<template>
  <div
    class="col-4 color-temp"
    data-cy="smsTypeCard"
    @click="selectedValue = !selectedValue"
  >
    <div :class="['type-sms-block', { highlighted: selectedValue }]">
      <h3>{{ title }}<i class="ico-title di-bubble-text"></i></h3>
      <span>{{ description }}</span>
      <img
        :class="{ 'type-img': animationSrc !== undefined }"
        class="img-sizing"
        alt="sms type logo"
        :src="illustrationSrc"
      />
      <img
        v-if="animationSrc !== undefined"
        class="type-animation img-sizing"
        alt="sms type animation"
        :src="animationSrc"
      />
      <p class="type-block-price">
        {{ $t('smsEditor.smsTypeSelector.cost', { cost: price }) }}
      </p>
      <button class="chooseButton">
        {{
          selectedValue
            ? $t('global.actions.choosen')
            : $t('global.actions.select')
        }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, ModelSync } from 'vue-property-decorator';

@Component
export default class SmsTypeCard extends Vue {
  @Prop({ type: String, required: true }) readonly title!: string;

  @Prop({ type: String, required: true }) readonly description!: string;

  @Prop({ type: String, required: true }) readonly illustrationSrc!: string;

  @Prop({ type: String }) readonly animationSrc!: string | undefined;

  @Prop({ type: String, required: true }) readonly price!: string;

  @ModelSync('selected', 'select', { type: Boolean, required: true })
  readonly selectedValue!: boolean;
}
</script>

<style scoped>
.color-temp {
  height: 430px;
  position: relative;
}

.type-sms-block {
  background-color: #fff;
  height: 395px;
  width: 324px;
  position: absolute;
  padding: 27.5px 30px 22px;
  line-height: 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.5s ease-in-out, font-size 0.5s;
  border-radius: 6.6px;
  box-shadow: 0 0 30px 0 rgba(73, 107, 162, 0.25);
  cursor: pointer;
}

.highlighted,
.type-sms-block:hover {
  z-index: 2;
  -webkit-transform: translate(-50%, -50%) scale(1.1);
  transform: translate(-50%, -50%) scale(1.1);
  box-shadow: 0 0 40px 0 rgba(73, 107, 162, 0.45);
}

.type-animation {
  display: none;
}

.type-sms-block:hover .type-img {
  display: none;
}

.type-sms-block:hover .type-animation {
  display: block;
}

.type-sms-block h3 {
  font-weight: 700;
  font-size: 18px;
  color: #30445f;
}

.type-sms-block h3 > span {
  color: var(--primary-color);
  height: 20px;
  width: 20px;
  margin-left: 3px;
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
}

.ico-title {
  color: var(--primary-color);
  font-size: 0.8em;
  margin-left: 5px;
}

.type-sms-block > span {
  min-height: 60px;
  display: block;
  font-weight: 300;
}

.img-sizing {
  height: 155.7px;
  margin: 19px auto 20px;
}

.chooseButton {
  background: none;
  border: 1px solid #3a4655;
  border-radius: 4px;
  width: 130px;
  height: 33px;
  color: #30445f;
  font-size: 14px;
  cursor: pointer;
}

.highlighted button,
.type-sms-block:hover button {
  background-color: var(--primary-color);
  color: white;
  border-color: var(--primary-color);
}

.type-block-price {
  color: #30445f;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 10px;
}
</style>
