<template>
  <p-card
    :title="$t('organisation.types.editor.title')"
    icon-src="@/core/assets/img/icons/ico-contacts.svg"
  >
    <b-spinner v-if="isLoading" />
    <b-container v-else>
      <b-table
        outlined
        striped
        hover
        fixed
        responsive
        :items="types"
        :fields="fields"
        class="entity-type-list"
      >
        <template #table-colgroup>
          <col style="width: 5rem" />
          <col />
          <col style="width: 10rem" />
          <col style="width: 15rem" />
        </template>

        <!-- Headers -->
        <template #head()="data">
          <span :id="data.column">
            {{ $t(`organisation.types.editor.headers.${data.column}`) }}
          </span>
          <b-tooltip :target="data.column" boundary="document">
            {{ $t(`organisation.types.editor.headers.tips.${data.column}`) }}
          </b-tooltip>
        </template>

        <!-- Name column -->
        <template #cell(name)="row">
          <EditableText
            v-if="$can(p.EDIT, p.ENTITYTYPE)"
            v-model="row.item.name"
            v-on:input="updateType(row.index)"
            :placeholder="$t('organisation.types.editor.namePlaceHolder')"
            :validators="nameValidators(row.item)"
            :feedback="feedback.name"
          />
          <div v-if="!$can(p.EDIT, p.ENTITYTYPE)">
            {{ row.item.name }}
          </div>
        </template>

        <!-- Is Group column -->
        <template #cell(isEntityGroup)="row">
          <div class="center" :id="`isgroup-${row.index}`">
            <b-form-checkbox disabled :checked="row.value" />
          </div>
          <b-tooltip
            :target="`isgroup-${row.index}`"
            :title="$t('organisation.types.editor.tips.isgroup')"
            boundary="document"
          />
        </template>

        <!-- Actions-->
        <template #cell(actions)="row">
          <b-button
            :id="`delete-${row.index}`"
            variant="danger"
            v-if="$can(p.DELETE, p.ENTITYTYPE)"
            @click="deleteType(row.index)"
          >
            <i class="fas fa-trash-alt" aria-hidden="true" />
          </b-button>
          <b-tooltip
            :target="`delete-${row.index}`"
            :title="$t('organisation.types.editor.tips.delete')"
            boundary="document"
          />
        </template>
      </b-table>
      <b-button
        v-if="$can(p.CREATE, p.ENTITYTYPE)"
        variant="primary"
        id="create-entity-type-button"
        href="#"
        tabindex="0"
      >
        <i class="fas fa-plus mr-1" aria-hidden="true" />
        {{ $t('organisation.types.editor.create') }}
      </b-button>
      <PopoverForm
        target="create-entity-type-button"
        :title="$t('organisation.types.editor.createTitle')"
        v-slot="scope"
      >
        <TypeCreate
          @canceled="scope.canceled"
          @saved="scope.saved"
          :validators="createFormValidators"
          :feedback="feedback"
        />
      </PopoverForm>
    </b-container>
  </p-card>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { maxLength, required } from 'vuelidate/lib/validators';
import PCard from '@/core/components/cards/PCard.vue';
import EditableText from '@/core/components/widgets/EditableText.vue';
import PopoverForm from '@/core/components/widgets/PopoverForm.vue';
import { EntityType } from '@/core/store/models';
import { CREATE, DELETE, EDIT, ENTITYTYPE } from '@/conf/permissions';
import FeedbacksMixin from '@/core/mixins/feedbacksMixin';
import PopupMixin from '@/core/mixins/popupMixin';
import TypeCreate from './TypeCreate.vue';

@Component({ components: { PCard, EditableText, PopoverForm, TypeCreate } })
export default class TypesEditor extends mixins(FeedbacksMixin, PopupMixin) {
  private p = { DELETE, CREATE, EDIT, ENTITYTYPE };

  private fields = ['id', 'name', 'isEntityGroup', 'actions'];

  get types(): EntityType[] | null {
    return this.$store.getters['entityTypes/all'];
  }

  get isLoading(): boolean {
    return this.$store.getters['entityTypes/isLoading'];
  }

  mounted(): void {
    this.$store.dispatch('entityTypes/fetch');
  }

  // Validations on type name
  private nameValidators(type: EntityType) {
    return {
      required,
      maxLength: maxLength(85),
      noDuplicate: (value: string) => {
        return !this.types?.find((t) => t.name === value && t.id !== type.id);
      }
    };
  }

  // Validators for create form
  private createFormValidators = {
    name: this.nameValidators(new EntityType({}))
  };

  private feedback = {
    name: (value: any) => {
      if (value.noDuplicate === false)
        return `${this.$t('organisation.types.editor.validators.noDuplicate')}`;
      return this.genericFeedback(value);
    }
  };

  // eslint-disable-next-line class-methods-use-this
  updateType(index: number): void {
    // TODO: loader ?
    this.$store.dispatch('entityTypes/saveAt', index);
  }

  // eslint-disable-next-line class-methods-use-this
  deleteType(index: number): void {
    this.popupConfirmDanger(
      `${this.$t('organisation.types.editor.deleteConfirm', {
        name: this.types?.[index].name
      })}`
    ).then((ok) => {
      if (ok) {
        this.$store.dispatch('entityTypes/deleteAt', index);
      }
    });
  }
}
</script>

<style lang="scss" scoped>
.entity-type-list {
  .btn {
    padding: 2px 8px;
    margin-top: -5px;
    margin-bottom: -5px;
  }
}
</style>
