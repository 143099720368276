<template>
  <footer>
    <div id="top">
      <b-container>
        <b-row>
          <img id="logo" src="@/core/assets/img/logo.svg" alt="Dolmen" />
        </b-row>
      </b-container>
    </div>
    <div id="bottom" class="py-2">
      <small>
        <b-container>
          <b-row>
            <b-col id="links">
              <span v-for="(href, key) in links" :key="key" class="link">
                <b-link v-bind:href="href">{{
                  $t(`footer.links.${key}`)
                }}</b-link>
              </span>
            </b-col>
            <b-col id="copyright">{{ $t('footer.copyright', { year }) }}</b-col>
          </b-row>
        </b-container>
      </small>
    </div>
  </footer>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import format from '@/core/utils/i18nDate';
import { links } from '@/conf/footer';

@Component({})
export default class Footer extends Vue {
  private links = links;

  private year = format(new Date(), 'yyyy');
}
</script>
<style lang="scss" scoped>
footer {
  border-top: 2px solid $separator-color;
  background-color: $footer-bg-color;
  color: $primary;

  #top {
    #logo {
      max-height: 3 * $font-size-base;
      margin: auto;
    }
  }

  #bottom {
    border-top: 1px solid $separator-color;
    text-align: center;

    #links {
      text-align: right;

      span.link {
        &:after {
          content: ' | ';
        }

        &:last-child:after {
          content: '';
        }
      }
    }

    #copyright {
      text-align: left;
    }
  }
}
</style>
