import { Component, Vue } from 'vue-property-decorator';
import { VueAuth } from '../auth';

@Component
export default class LogoutMixin extends Vue {
  logout(): void {
    this.$auth.then((auth: VueAuth) => {
      auth.logout({
        logoutParams: {
          returnTo: window.location.origin
        }
      });
    });
  }
}
