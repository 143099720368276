<template>
  <div>
    <b-row class="p-card-align">
      <b-col sm="6">
        <p-card
          :title="$t('communication.campaign.title')"
          icon-src="@/core/assets/img/icons/ico-com.svg"
        >
          {{
            $t('communication.redirection.text', {
              entityTypeName,
              entityName
            })
          }}
          <b-row class="mt-3">
            <b-col>
              <b-button
                class="w-100"
                variant="outline-primary"
                :href="redirectLinkToPilot"
                target="_blank"
                ><span class="span-align">
                  {{ $t('communication.redirection.accessLocalCampaigns') }}
                  <em class="pi pi-external-link" />
                </span>
              </b-button>
            </b-col>
          </b-row>
        </p-card>
      </b-col>
    </b-row>
  </div>
</template>

<script lang="ts">
import PCard from '@/core/components/cards/PCard.vue';
import getPilotLink from '@/core/menu/main/getPilotLink';
import { Entity, EntityType } from '@/core/store/models';
import { Component, Vue } from 'vue-property-decorator';

@Component({ components: { PCard } })
export default class RedirectToPilot extends Vue {
  redirectLinkToPilot = getPilotLink('connect/com/construct');

  get entityName(): string {
    return this.$store.getters['entities/current'].name;
  }

  get entityTypeName(): string {
    const currentEntity: Entity = this.$store.getters['entities/current'];
    const currentEntityType: EntityType = this.$store.getters[
      'entityTypes/getEntityType'
    ](currentEntity.type);

    return currentEntityType.name ? currentEntityType.name : '';
  }
}
</script>
<style lang="scss">
.span-align {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.p-card-align {
  justify-content: center;
}
</style>
