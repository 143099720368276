/* eslint-disable class-methods-use-this */
import { VuexModule, Module, Action } from 'vuex-module-decorators';

import store from '@/core/store';
import { OrganisationState } from './state';

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: 'organisation'
})
export default class Dashboard extends VuexModule implements OrganisationState {
  todo = '';

  @Action
  public initialize(): void {
    console.log('Initializing Organisation');
  }
}
