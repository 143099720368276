// Network service paths
enum Paths {
  ENTITY_WS = '/entity-manager/v1/organisation/:id/entity/:eId?',
  ENTITY_TYPE_WS = '/entity-manager/v1/organisation/:id/entity-type/:etId?',
  ORGANISATION_WS = '/entity-manager/v1/organisation',
  USERS_WS = '/user-manager/v1/organisation/:id/user/:userId?',
  USERS_ROLES_WS = '/user-manager/v1/role/:userId?',
  USERS_PREFERENCES_WS = '/user-manager/v1/preferences',
  ACTIVATION_CAMPAIGN_MANAGER_WS = '/activation-campaign-manager/v1',
  PACK_MANAGER_WS = '/packs-manager/v1',
  SMS_EDITOR_WS = '/pilot/ws/smseditor'
}

export default Paths;
