<template>
  <b-container>
    <b-form>
      <b-form-row>
        <b-form-group
          :label="$t('organisation.types.editor.nameLabel')"
          label-for="create-entity-type-name"
          :invalid-feedback="feedback.name($v.type.name)"
          :state="validateState('name')"
        >
          <b-form-input
            autofocus
            id="create-entity-type-name"
            :placeholder="$t('organisation.types.editor.namePlaceHolder')"
            v-model.trim="$v.type.name.$model"
            :state="validateState('name')"
            :disabled="isSaving"
          />
        </b-form-group>
      </b-form-row>
      <b-form-row>
        <b-form-group invalid-feedback="Invalid feedback" :state="null">
          <b-form-checkbox
            :disabled="isSaving"
            v-model.trim="$v.type.isEntityGroup.$model"
          >
            {{ $t('organisation.types.editor.isEntityGroupLabel') }}
          </b-form-checkbox>
        </b-form-group>
      </b-form-row>
      <b-form-row>
        <b-button-toolbar>
          <b-button
            class="mr-2"
            variant="primary"
            :disabled="isInvalid || isSaving"
            @click="confirm"
          >
            <b-spinner v-if="isSaving" small></b-spinner>
            <span v-else>{{ $t('global.actions.save') }}</span>
          </b-button>
          <b-button :disabled="isSaving" variant="secondary" @click="cancel">
            {{ $t('global.actions.cancel') }}
          </b-button>
        </b-button-toolbar>
      </b-form-row>
    </b-form>
  </b-container>
</template>

<script lang="ts">
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Emit, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { ValidationFunc } from 'vuelidate/lib/validators';
import { Validations } from 'vuelidate-property-decorators';
import FeedbacksMixin from '@/core/mixins/feedbacksMixin';
import { EntityType } from '@/core/store/models';

@Component
export default class TypeCreate extends mixins(FeedbacksMixin) {
  @Prop() readonly validators!: Record<string, Record<string, ValidationFunc>>;

  @Prop() readonly feedback: Record<string, (value: any) => string> | undefined;

  private type = new EntityType({
    name: '',
    isEntityGroup: false
  });

  private isSaving = false;

  @Validations()
  private validations() {
    return {
      type: {
        name: {},
        isEntityGroup: {},
        ...this.validators
      }
    };
  }

  private validateState(key: string) {
    const { $dirty, $error } = this.$v.type[key] || {
      $dirty: false,
      $error: false
    };
    return $dirty && !this.isSaving ? !$error : null;
  }

  get isInvalid(): boolean {
    return !this.$v.type.name?.$dirty || this.$v.type.$anyError;
  }

  private async confirm() {
    this.isSaving = true;
    const result = await this.$store.dispatch('entityTypes/create', this.type);
    if (result) {
      this.$emit('saved');
    } else {
      this.isSaving = false;
    }
  }

  @Emit('canceled')
  private cancel() {
    // This is intentionnal
  }
}
</script>

<style lang="scss" scoped>
#create-entity-type-name {
  width: 300px;
}
</style>
