import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { UsersClient } from '@/core/store/network';
import { User } from '@/core/store/models';
import store from '@/core/store';
import { UsersState } from './state';

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: 'users'
})
export default class Users extends VuexModule implements UsersState {
  private UsersClient = new UsersClient();

  users: User[] = [];

  loading = false;

  private userLoading = false;

  // Hash map of all users
  private _hashUsers: Record<string, User> = {};

  get all(): User[] {
    return this.users;
  }

  get isLoading(): boolean {
    return this.loading;
  }

  get isUserLoading(): boolean {
    return this.userLoading;
  }

  get getUser() {
    return (userId: string): User | null => {
      return this._hashUsers[userId] || null;
    };
  }

  @Mutation SET_LOADING(loading: boolean): void {
    this.loading = loading;
  }

  @Mutation SET_USER_LOADING(loading: boolean): void {
    this.userLoading = loading;
  }

  @Mutation
  public SET_USERS(users: User[]): void {
    this._hashUsers = {};
    users.forEach((et) => {
      if (et.userId !== undefined) {
        this._hashUsers[et.userId] = et;
      }
    });
    this.users = users;
  }

  @Mutation
  public UPDATE_USERS(user: User): void {
    if (user.userId !== undefined) {
      const index = this.users
        ? this.users.findIndex((e) => e.userId === user.userId)
        : -1;
      if (this.users && index >= 0) {
        this.users.splice(index, 1, user);
      } else {
        this.users.push(user);
      }
      this._hashUsers[user.userId] = user;
    }
  }

  /**
   * Fetch the entity Users corresponding to the organisation currently selected
   */
  @Action
  async fetch(): Promise<void> {
    this.context.commit('SET_LOADING', true);
    const response = await this.UsersClient.fetch(
      store.getters['organisations/current']
    );
    this.context.commit('SET_LOADING', false);
    if (!response || response.status !== 200) {
      console.error(response);
    } else {
      this.context.commit('SET_USERS', response.data);
    }
  }

  @Action
  async fetchUser(userId: string): Promise<User | null> {
    this.context.commit('SET_USER_LOADING', true);
    const response = await this.UsersClient.fetchUser(
      store.getters['organisations/current'],
      userId
    );
    this.context.commit('SET_USER_LOADING', false);
    if (!response || response.status !== 200) {
      console.error(response);
      return null;
    }
    if (response.data.userId) {
      this._hashUsers[response.data.userId] = new User(response.data);
    }
    return response.data;
  }

  /**
   * Save the given user
   */
  @Action
  async save(user: User): Promise<User | null> {
    const response = await this.UsersClient.save(
      store.getters['organisations/current'],
      user
    );
    if (!response || response.status !== 200) {
      // Refresh entity Users
      await this.context.dispatch('fetch');
      return null;
    }
    store.commit('users/UPDATE_USERS', response.data);
    if (user.userId === store.getters['auth/currentUser'].sub) {
      store.dispatch('auth/refreshUser', response.data);
    }
    return response.data;
  }
}
