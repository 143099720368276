<template>
  <div>
    <div v-if="edited">
      <b-form>
        <b-form-group
          :state="validateState"
          :invalid-feedback="feedback ? feedback($v.editedValue) : ''"
        >
          <b-form-input
            autofocus
            v-model.trim="$v.editedValue.$model"
            :placeholder="placeholder"
            :state="validateState"
          />
        </b-form-group>
        <b-button-toolbar>
          <b-button
            class="mr-2"
            variant="primary"
            :disabled="isInvalid"
            @click="confirm"
          >
            {{ $t('global.actions.save') }}
          </b-button>
          <b-button variant="secondary" @click="cancel">
            {{ $t('global.actions.cancel') }}
          </b-button>
        </b-button-toolbar>
      </b-form>
    </div>
    <div v-else class="read" @click="edit">
      <i
        aria-hidden="true"
        ref="edit-icon"
        class="edit fas fa-pencil-alt mr-2"
      />
      <span>{{ text }}</span>
      <b-tooltip
        :target="() => $refs['edit-icon']"
        :title="$t('editable.tips.edit')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue } from 'vue-property-decorator';
import { Validations } from 'vuelidate-property-decorators';
import { ValidationFunc } from 'vuelidate/lib/validators';

@Component({})
export default class EditableText extends Vue {
  @VModel({ type: String }) text!: string;

  @Prop() readonly placeholder: string | undefined;

  @Prop() readonly validators: Record<string, ValidationFunc> | undefined;

  @Prop() readonly feedback: ((editedValue: any) => string) | undefined;

  private edited = false;

  private editedValue = '';

  @Validations()
  validations(): any {
    return {
      editedValue: this.validators
    };
  }

  get validateState(): null | boolean {
    const { $dirty, $error } = this.$v.editedValue;
    return $dirty ? !$error : null;
  }

  get isInvalid(): boolean {
    return this.$v.editedValue.$anyError;
  }

  edit(): void {
    this.$v.editedValue.$reset();
    this.$v.editedValue.$model = this.text;
    this.edited = true;
  }

  confirm(): void {
    this.text = this.$v.editedValue.$model;
    this.edited = false;
  }

  cancel(): void {
    this.edited = false;
  }
}
</script>
<style lang="scss" scoped>
.read {
  cursor: pointer;

  .edit {
    opacity: 0;
  }

  &:hover {
    .edit {
      opacity: 0.5;
    }
  }

  span {
    &:hover {
      color: $primary;
    }
  }
}
</style>
