<template>
  <b-spinner v-if="isLoading" />
  <b-container v-else>
    <b-form>
      <b-form-row>
        <b-form-group
          :label="$t('organisation.users.editor.nameLabel')"
          label-for="create-user-name"
          :invalid-feedback="feedback.name($v.user.name)"
          :state="validateState('name')"
        >
          <b-form-input
            autofocus
            id="create-user-name"
            :placeholder="$t('organisation.users.editor.namePlaceHolder')"
            v-model.trim="$v.user.name.$model"
            :state="validateState('name')"
            :disabled="isSaving"
          />
        </b-form-group>
      </b-form-row>
      <b-form-row>
        <b-form-group
          :label="$t('organisation.users.editor.emailLabel')"
          label-for="create-user-email"
          :invalid-feedback="feedback.name($v.user.email)"
          :state="validateState('email')"
        >
          <b-form-input
            id="create-user-email"
            :placeholder="$t('organisation.users.editor.emailPlaceHolder')"
            v-model.trim="$v.user.email.$model"
            :state="validateState('email')"
            :disabled="isSaving"
            type="email"
          />
        </b-form-group>
      </b-form-row>
      <b-form-row>
        <b-form-group
          :label="$t('organisation.users.editor.entitiesLabel')"
          label-for="create-user-entities"
          :invalid-feedback="feedback.name($v.user.entities)"
          :state="validateState('entities')"
        >
          <treeselect
            id="create-user-entities"
            :value="value"
            @input="updateValue"
            :clearOnSelect="true"
            :multiple="true"
            :flat="true"
            :options="entities"
            :normalizer="normalizer"
            :clearable="true"
            :placeholder="$t('organisation.users.editor.tips.entities')"
          />
        </b-form-group>
      </b-form-row>
      <b-form-row v-if="!isUserEditingHimself && $can(p.VIEW, p.ROLES)">
        <b-form-group
          :label="$t('organisation.users.editor.rolesLabel')"
          label-for="create-user-roles"
          :invalid-feedback="feedback.name($v.user.role)"
          :state="validateState('role')"
        >
          <treeselect
            id="create-user-roles"
            v-model="$v.user.role.$model"
            value-format="object"
            :disabled="!$can(p.EDIT, p.ROLES)"
            :multiple="false"
            :options="roles"
            :normalizer="roleNormalizer"
            :clearable="true"
            :placeholder="$t('organisation.users.editor.rolesLabel')"
          />
        </b-form-group>
      </b-form-row>
      <b-form-row>
        <b-button-toolbar>
          <b-button
            class="mr-2"
            variant="primary"
            :disabled="isInvalid || isSaving"
            @click="confirm"
          >
            <b-spinner v-if="isSaving" small></b-spinner>
            <span v-else>{{ $t('global.actions.save') }}</span>
          </b-button>
          <b-button :disabled="isSaving" variant="secondary" @click="cancel">
            {{ $t('global.actions.cancel') }}
          </b-button>
        </b-button-toolbar>
      </b-form-row>
    </b-form>
  </b-container>
</template>

<script lang="ts">
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Emit, Prop } from 'vue-property-decorator';
import { ValidationFunc } from 'vuelidate/lib/validators';
import { mixins } from 'vue-class-component';
import { Validations } from 'vuelidate-property-decorators';
import FeedbacksMixin from '@/core/mixins/feedbacksMixin';
import { Entity, Role, User } from '@/core/store/models';
import { EDIT, ROLES, VIEW } from '@/conf/permissions';

@Component
export default class UserEdit extends mixins(FeedbacksMixin) {
  @Prop() readonly validators!: Record<string, Record<string, ValidationFunc>>;

  @Prop() readonly feedback: Record<string, (value: any) => string> | undefined;

  @Prop() readonly userId?: string;

  private p = { VIEW, EDIT, ROLES };

  private user: User | null = null;

  private _user: User | null = null;

  private isSaving = false;

  private value: number[] = [];

  private hiddenEntities: number[] = []; // Keep entities

  mounted(): void {
    if (this.isUserEditingHimself) {
      const currentUser = this.$store.getters['auth/currentUser'];
      this.user = new User({
        userId: currentUser.sub,
        name: currentUser.name,
        email: currentUser.email,
        entities: this.$store.getters['auth/userEntities']
      });
    } else if (this.userId && !this.isUserEditingHimself) {
      // edit already existing user
      this.fetchUser();
    } else {
      // create new user
      const defaultRoleId: string =
        this.roles.find((e) => {
          return e.roleName === "Client Consultation et suivi d'opérations";
        })?.roleId || '';
      this.user = new User({
        name: '',
        email: '',
        entities: [],
        role: this.$store.getters['roles/getRole'](defaultRoleId)
      });
    }
    this.initializeValue();
  }

  get entities(): Entity[] | null {
    return this.$store.getters['entities/all'];
  }

  get roles(): Role[] {
    return this.$store.getters['roles/all'];
  }

  private async fetchUser() {
    this._user = this.$store.getters['users/getUser'](this.userId);
    if (this._user?.role === undefined) {
      const result = await this.$store.dispatch('users/fetchUser', this.userId);
      this._user = new User({
        userId: result.userId,
        name: result.name,
        email: result.email,
        entities: result.entities,
        role: result.role
      });
    }
    this.user = new User({
      userId: this._user.userId,
      name: this._user.name,
      email: this._user.email,
      entities: this._user.entities,
      role: this._user.role
    });
    this.initializeValue();
  }

  private initializeValue() {
    const allEntities = Object.assign([], this.$v.user.entities?.$model); // Extracting all entities to filter
    const getEntity = this.$store.getters['entities/getEntity'];

    // Filter the entities to only keep the ones the user can access under its current organization
    this.value = allEntities.filter((id: number) => getEntity(id));
    // Keep the filtered entities to maintain them in user settings
    this.hiddenEntities = allEntities.filter(
      (id: number) => !this.value.includes(id)
    );
  }

  private updateValue(value: number[]) {
    if (this.user) {
      this.user.entities = [...value, ...this.hiddenEntities];
    }
    this.$v.user.$touch();
  }

  get isLoading(): boolean {
    return (
      this.$store.getters['roles/isLoading'] ||
      this.$store.getters['users/isUserLoading']
    );
  }

  get isUserEditingHimself(): boolean {
    return this.$store.getters['auth/currentUser'].sub === this.userId;
  }

  @Validations()
  private validations() {
    return {
      user: {
        name: {},
        email: {},
        entities: {},
        role: {},
        ...this.validators
      }
    };
  }

  // eslint-disable-next-line class-methods-use-this
  private normalizer(node: Entity) {
    return {
      id: node.id,
      label: node.name,
      children: node.children,
      isDisabled: this.isUserEditingHimself
    };
  }

  private roleNormalizer(node: Role) {
    return {
      id: node.roleId,
      label: node.roleName
    };
  }

  private validateState(key: string) {
    const { $dirty, $error } = this.$v.user[key] || {
      $dirty: false,
      $error: false
    };
    return $dirty && !this.isSaving ? !$error : null;
  }

  get isInvalid(): boolean {
    return this.userId
      ? this.$v.user.$anyError
      : this.$v.user.$anyError ||
          !this.$v.user.name?.$dirty ||
          !this.$v.user.email?.$dirty ||
          !this.$v.user.entities?.$dirty;
  }

  private async confirm() {
    this.$v.user.$touch();
    if (!this.$v.user.$invalid) {
      this.isSaving = true;
      const result = await this.$store.dispatch('users/save', this.user);
      if (result) {
        this.$emit('saved');
      }
      this.isSaving = false;
    }
  }

  @Emit('canceled')
  private cancel() {
    // This is intentionnal
  }
}
</script>

<style lang="scss" scoped>
#create-user-email,
#create-user-name {
  width: 300px;
}
</style>
