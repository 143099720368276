import { loadLocaleMessages } from '@/plugins/i18n';
import routes from './router';
import store from './store';
import menu from './menu';
import localeMessages from './locales';

export default {
  name: 'contacts',
  routes,
  store,
  locales: loadLocaleMessages(localeMessages, 'contacts'),
  menu
};
