<template>
  <p-card
    :title="$t('analyse.communication.stats.common.visualisation')"
    icon-src="@/core/assets/img/icons/ico-mapping.svg"
  >
    <b-row class="m-0 justify-content-between">
      <b-col class="bg-dark col-7"> TODO: MAP </b-col>
      <b-col class="col-5 pl-3">
        <b-list-group class="campaign-list">
          <b-list-group-item class="px-3 py-2 mb-4">
            <span class="campaign-name"><strong>Campaign Name</strong></span>
            <span class="campaign-date">10/05/2021</span>
          </b-list-group-item>
          <b-list-group-item class="px-3 py-2 mb-4">
            <span class="campaign-name"><strong>Campaign Name</strong></span>
            <span class="campaign-date">10/05/2021</span>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
  </p-card>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import PCard from '@/core/components/cards/PCard.vue';

@Component({ components: { PCard } })
export default class CommunicationMap extends Vue {
  @Prop(String) private title: string | undefined;

  @Prop(String) private iconSrc: string | undefined;
}
</script>
<style lang="scss" scoped>
.campaign-list {
  span {
    display: block;
  }
  .list-group-item {
    border: 0;
    background: #f9f9f9;
  }
  .campaign-name {
    color: $blue;
  }
  .campaign-date {
    color: $dark;
    font-size: 0.9rem;
    font-weight: lighter;
  }
}
</style>
