import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/core/store';
import { Preference } from '@/core/store/models';
import { PreferencesClient } from '@/core/store/network';
import { Operation, displayError } from '@/core/store/modules/apiError';
import { PreferencesState } from './state';

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: 'preferences'
})
export default class Preferences
  extends VuexModule
  implements PreferencesState
{
  private UsersPreferences = new PreferencesClient();

  preferences: Preference = {};

  loading = false;

  get currentPreferences(): Preference {
    return this.preferences;
  }

  get isLoading(): boolean {
    return this.loading;
  }

  @Mutation SET_LOADING(loading: boolean): void {
    this.loading = loading;
  }

  @Mutation
  public SET_PREFERENCES(preferences: Preference): void {
    this.preferences = preferences;
  }

  /**
   * Save the given user
   */
  @Action
  async save(preference: Preference): Promise<Preference | null> {
    const user = await store.getters['auth/currentUser'];
    const response = await this.UsersPreferences.save(
      store.getters['organisations/organisationByIdOrType'](
        preference?.lastSelectedOrganisation
      ),
      user,
      preference
    );

    if (response instanceof Error) {
      displayError(response, Operation.SAVE, user.name);
      return null;
    }
    store.commit('preferences/SET_PREFERENCES', response);

    return response;
  }
}
