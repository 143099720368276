<template>
  <b-container class="styleguide py-2">
    <b-row>
      <b-col><h1 class="center">Styleguide</h1></b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col><h2>Cards</h2></b-col>
    </b-row>
    <b-row>
      <b-col>
        <p-card
          title="Group card example"
          icon-src="@/core/assets/img/icons/ico-collect.svg"
          type="group"
        >
          <template #header>
            <div class="w-100 text-center">Content injected in card header</div>
          </template>
          <b-container>
            <b-row>
              <b-col>
                <p-card
                  title="Card example 1"
                  icon-src="@/core/assets/img/icons/ico-indicators.svg"
                >
                  <h3>Permissions:</h3>
                  <div class="zone-example" style="height: 300px">
                    <div v-if="$can(p.READ, p.DEMO1)">
                      Voir les statistiques de la base de contacts
                    </div>
                    <div v-if="$can(p.READ, p.Style)">
                      Voir toutes les stats de collecte
                    </div>
                    <div v-if="$can(p.CREATE, p.DEMO3)">
                      Créer une campagne marketing
                    </div>
                  </div>
                </p-card>
              </b-col>
              <b-col>
                <p-card
                  title="Card example 2"
                  icon-src="@/core/assets/img/icons/ico-network.svg"
                >
                  <b-row class="d-flex justify-content-center" align-v="center">
                    <div>
                      <circular-indicator
                        :percentage="36"
                        :value="299"
                        :fluctuation="8.1"
                      >
                      </circular-indicator>
                    </div>
                  </b-row>
                </p-card>
              </b-col>
              <b-col>
                <p-card
                  title="Card example 3"
                  icon-src="@/core/assets/img/icons/ico-plateform.svg"
                >
                  <b-row class="d-flex justify-content-center" align-v="center">
                    <div>
                      <semi-circular-indicator
                        :value="10"
                        :total="50"
                        title="Hyper"
                      >
                      </semi-circular-indicator>
                    </div>
                  </b-row>
                </p-card>
              </b-col>
            </b-row>
          </b-container>
        </p-card>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col><h2>Colors</h2></b-col>
    </b-row>
    <b-row>
      <b-col v-for="color in colors" :key="color">
        <div
          :style="`background-color:var(${color});width:50px;height:50px;`"
        ></div>
        <div>{{ color }}</div>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col><h2>Typography</h2></b-col>
    </b-row>
    <b-row>
      <b-container>
        <b-row>
          <b-col><h1>Headline H1</h1></b-col>
        </b-row>
        <b-row>
          <b-col><h2>Headline H2</h2></b-col>
        </b-row>
        <b-row>
          <b-col><h3>Headline H3</h3></b-col>
        </b-row>
        <b-row>
          <b-col>
            <p>
              This is a paragraph using Poppins Regular at 16px, with a 1.5em
              line-height in the colour #6E6E6E Phasellus pharetra urna sit amet
              tellus elementum aliquet in et quam. In condimentum dui.
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <p>
              This is a paragraph showing the use of <strong>bold text</strong>,
              using the weight medium. Nam ornare lectus ullamcorper nisl
              accumsan accumsan.Mauris et arcu sit amet enim lacinia
              pellentesque eu quis sapien.
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <span class="num1">28 888 178</span>
          </b-col>
          <b-col>
            <span class="num2">1 780</span>
          </b-col>
          <b-col>
            <span class="num3">200 000 000</span>
          </b-col>
        </b-row>
      </b-container>
    </b-row>
    <b-row class="mt-4">
      <b-col><h2>Icons</h2></b-col>
    </b-row>
    <b-row>
      <b-col v-for="name in icons" :key="name">
        <i :class="`di ${name}`" aria-hidden="true" />
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col><h2>Buttons</h2></b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col><h3>Plain</h3></b-col>
    </b-row>
    <b-row class="text-center">
      <b-col>
        <b-button variant="primary">Primary</b-button>
      </b-col>
      <b-col>
        <b-button variant="secondary">Secondary</b-button>
      </b-col>
      <b-col>
        <b-button variant="danger">Danger</b-button>
      </b-col>
      <b-col>
        <b-button variant="success">Success</b-button>
      </b-col>
      <b-col>
        <b-button variant="warning">Warning</b-button>
      </b-col>
      <b-col>
        <b-button variant="info">Info</b-button>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col><h3>Outlined</h3></b-col>
    </b-row>
    <b-row class="text-center">
      <b-col>
        <b-button variant="outline-primary">Primary</b-button>
      </b-col>
      <b-col>
        <b-button variant="outline-secondary">Secondary</b-button>
      </b-col>
      <b-col>
        <b-button variant="outline-danger">Danger</b-button>
      </b-col>
      <b-col>
        <b-button variant="outline-success">Success</b-button>
      </b-col>
      <b-col>
        <b-button variant="outline-warning">Warning</b-button>
      </b-col>
      <b-col>
        <b-button variant="outline-info">Info</b-button>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col
        ><h2>Fields</h2>
        <b-container>
          <b-row>
            <b-col>
              <b-form-group
                id="fieldset-1"
                description="Help text"
                label="Label"
                label-for="input-1"
                valid-feedback="Valid feedback"
                invalid-feedback="Invalid feedback"
                :state="null"
              >
                <b-form-input
                  id="input-1"
                  type="text"
                  placeholder="Placeholder"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="fieldset-2"
                description="Help text"
                label="Label"
                label-for="input-2"
                valid-feedback="Valid feedback"
                invalid-feedback="Invalid feedback"
                :state="null"
              >
                <b-form-input
                  disabled
                  id="input-2"
                  type="text"
                  placeholder="Placeholder"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col>
              <b-form-group
                id="fieldset-3"
                description="Help text"
                label="Label"
                label-for="input-3"
                valid-feedback="Valid feedback"
                invalid-feedback="Invalid feedback"
                :state="true"
              >
                <b-form-input
                  :state="true"
                  id="input-3"
                  type="text"
                  placeholder="Placeholder"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="fieldset-4"
                description="Help text"
                label="Label"
                label-for="input-4"
                valid-feedback="Valid feedback"
                invalid-feedback="Invalid feedback"
                :state="false"
              >
                <b-form-input
                  :state="false"
                  id="input-4"
                  type="text"
                  placeholder="Placeholder"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <b-form-group
                id="fieldset-6"
                description="Help text"
                label="Label"
                label-for="input-6"
              >
                <treeselect
                  id="input-6"
                  v-model="selected1"
                  :multiple="false"
                  :options="selectOptions"
                  :clearable="true"
                  placeholder="Dropdown"
                />
              </b-form-group>
            </b-col>
            <b-col></b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <b-form-group
                id="fieldset-7"
                description="Help text"
                label="Label"
                label-for="input-7"
              >
                <b-form-textarea
                  id="textarea"
                  placeholder="Placeholder"
                  rows="3"
                />
              </b-form-group>
            </b-col>
            <b-col></b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <b-form-group
                id="fieldset-8"
                description="Help text"
                label="Label"
              >
                <b-form-radio name="some-radios" value="A" v-model="radio"
                  >Option A
                </b-form-radio>
                <b-form-radio name="some-radios" value="B" v-model="radio"
                  >Option B
                </b-form-radio>
                <b-form-radio
                  name="some-radios"
                  value="C"
                  disabled
                  v-model="radio"
                  >Option C
                </b-form-radio>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="fieldset-8"
                description="Help text"
                label="Label"
              >
                <b-form-checkbox
                  id="checkbox-1"
                  name="checkbox-1"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  Option 1
                </b-form-checkbox>
                <b-form-checkbox
                  id="checkbox-2"
                  name="checkbox-2"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  Option 2
                </b-form-checkbox>
                <b-form-checkbox
                  id="checkbox-2"
                  name="checkbox-2"
                  value="accepted"
                  unchecked-value="not_accepted"
                  v-model="checkbox"
                  disabled
                >
                  Option 3
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col
        ><h2>Tooltips</h2>
        <b-container>
          <b-row>
            <b-col
              ><p id="tooltip-text-example">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
              <b-tooltip show target="tooltip-text-example"
                >Tooltip on a text
              </b-tooltip>
            </b-col>
            <b-col>
              <b-button id="tooltip-button-example">Tooltip button</b-button>
              <b-tooltip show target="tooltip-button-example" placement="right">
                Tooltip on a button
              </b-tooltip>
            </b-col>
            <b-col>
              <i
                id="tooltip-icon-example"
                class="fas fa-info-circle"
                aria-hidden="true"
              ></i>
              <b-tooltip
                target="tooltip-icon-example"
                placement="bottom"
                variant="danger"
              >
                Tooltip on an icon
              </b-tooltip>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import PCard from '@/core/components/cards/PCard.vue';
import CircularIndicator from '@/core/components/widgets/CircularIndicator.vue';
import SemiCircularIndicator from '@/core/components/widgets/SemiCircularIndicator.vue';
import { CREATE, DEMO1, DEMO2, DEMO3, READ } from '@/conf/permissions';

@Component({
  components: {
    PCard,
    CircularIndicator,
    SemiCircularIndicator
  }
})
export default class Styleguide extends Vue {
  private p = { READ, CREATE, DEMO1, DEMO2, DEMO3 };

  private colors = [
    '--blue',
    '--indigo',
    '--cyan',
    '--orange',
    '--green',
    '--red',
    '--gray',
    '--gray-dark'
  ];

  private icons = [
    'collect',
    'com',
    'contacts',
    'indicators',
    'network',
    'plateform'
  ];

  private selectOptions = [
    { id: 'a', label: 'Action' },
    { id: 'b', label: 'Another action' },
    { id: 'c', label: 'This one is disabled', isDisabled: true }
  ];

  private selected1 = null;

  private radio = 'C';

  private checkbox = 'accepted';
}
</script>
