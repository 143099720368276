/* eslint-disable class-methods-use-this */
import { VuexModule, Module, Action } from 'vuex-module-decorators';
import store from '@/core/store';
import { AnalyseState } from './state';

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: 'analyse'
})
export default class AnalyseStore extends VuexModule implements AnalyseState {
  dummy = '';

  get statsDates(): { from: Date; to: Date } {
    return {
      from: store.getters['app/subheader/getValue'](
        'sh-date-picker',
        'fromDate'
      ),
      to: store.getters['app/subheader/getValue']('sh-date-picker', 'toDate')
    };
  }

  @Action
  public initialize(): void {
    console.log('Initializing Analyse');
  }
}
