// actions
export const READ = 'read';
export const VIEW = 'view';
export const EDIT = 'edit';
export const CREATE = 'create';
export const DELETE = 'delete';

// names
export const STYLEGUIDE = 'styleguide';
export const ORGANISATION = 'organisation';
export const STATISTICS = 'statistics';
export const ENTITY = 'entity';
export const ENTITYTYPE = 'entity-type';
export const ENTITYID = 'entity-id';
export const USERS = 'users';
export const ROLES = 'roles';
export const DEPLOYMENT = 'deployment';
export const COLLECT = 'collect';
export const COMMUNICATE = 'communicate';
export const COMMUNICATE_BILLING = 'communicate_billing';
export const COMMUNICATE_MODELS = 'communicate_models';

// For demo
export const DEMO1 = 'network';
export const DEMO2 = 'collect_kiosk-operation';
export const DEMO3 = 'communication_campaigns';
