<template>
  <div>
    <div class="datepicker-trigger">
      <b-form-input
        :value="formatDatesForDisplay(dates.fromDate, dates.toDate)"
        placeholder="Dates"
        id="datepicker-trigger"
      ></b-form-input>
    </div>
    <DolmenDatepicker
      :trigger-element-id="'datepicker-trigger'"
      :mode="'range'"
      :date-one="formatDateForPicker(dates.fromDate)"
      :date-two="formatDateForPicker(dates.toDate)"
      :showShortcutsMenuTrigger="false"
      @apply="apply()"
      @date-one-selected="
        (val) => {
          dateOne = val;
        }
      "
      @date-two-selected="
        (val) => {
          dateTwo = val;
        }
      "
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { DolmenDatepicker } from '@dolmen/vue-toolkit';
import { subMonths } from 'date-fns';
import format, { isDateValid } from '@/core/utils/i18nDate';
import SHComponent from './SHComponent';

export interface SHDatePickerValues {
  fromDate: Date;
  toDate: Date;
}

@Component({
  components: { DolmenDatepicker }
})
export default class SHDatePicker extends SHComponent {
  get dates(): SHDatePickerValues {
    return {
      fromDate: this.getValue('fromDate') || subMonths(new Date(), 1),
      toDate: this.getValue('toDate') || new Date()
    };
  }

  private dateOne?: string;

  private dateTwo?: string;

  mounted(): void {
    Vue.nextTick(() => this.apply());
  }

  /**
   * Set date and ensure that it wont bug out on local time changes PLA-3087.
   * If setMinutes is not used then the date will not be properly set,
   * example : if GTM+2 date will be 2020-01-01 00:00:00 but if you switch it to GTM+1 date becomes 2020-01-01 01:00:00
   * The extra set minutes prevents it from happening
   */
  setDateStr(key: string, dateStr: string, offset?: number): void {
    const date = new Date(dateStr);
    if (offset) this.setValue(key, new Date(date.getTime() + offset));
    else this.setValue(key, date);
  }

  apply(): void {
    if (this.dateOne) this.setDateStr('fromDate', this.dateOne);
    if (this.dateTwo)
      // add 23h 59m and 59sec
      this.setDateStr('toDate', this.dateTwo, 1000 * 60 * 60 * 24 - 1);
  }

  private dateDisplayFormat = 'd MMM';

  private datePickerFormat = 'yyyy-MM-dd';

  private formatDateForPicker(date: Date) {
    const formatted = new Date(date);
    formatted.setMinutes(date.getMinutes() + date.getTimezoneOffset());
    return isDateValid(formatted)
      ? format(formatted, this.datePickerFormat)
      : '';
  }

  private formatDatesForDisplay(fromDate: Date, toDate: Date) {
    let formattedDates = '';
    if (fromDate && isDateValid(fromDate)) {
      const formattedFrom = new Date(fromDate);
      formattedFrom.setMinutes(
        formattedFrom.getMinutes() + formattedFrom.getTimezoneOffset()
      );
      formattedDates = format(formattedFrom, this.dateDisplayFormat);
    }
    if (toDate && isDateValid(toDate)) {
      const formattedTo = new Date(toDate);
      formattedTo.setMinutes(
        formattedTo.getMinutes() + formattedTo.getTimezoneOffset()
      );
      formattedDates += ` - ${format(formattedTo, this.dateDisplayFormat)}`;
    }
    return formattedDates;
  }
}
</script>
<style lang="scss" scoped></style>
