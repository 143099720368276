import Role from './role';

export default class User {
  email?: string;

  entities?: number[];

  firstName?: string;

  lastName?: string;

  name?: string;

  userId?: string;

  role?: Role;

  constructor(data: Partial<User>) {
    Object.assign(this, data);
  }
}
