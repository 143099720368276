import { i18n } from '@/plugins/index';
import { Toast } from '@/core/store/models';
import store from '@/core/store';

export enum Operation {
  UNKNOWN = 'unknown',
  READ = 'read',
  SAVE = 'save',
  EDIT = 'edit',
  DELETE = 'delete',
  CREATE_CAMPAIGN = 'createCampaign',
  UPDATE_CAMPAIGN = 'updateCampaign',
  TARGET_CONTACT = 'targetContacts',
  GET_PACKS_BY_ENTITIES_ID = 'packsByEntities',
  GET_CONF = 'getEntityConfiguration',
  PRICES_PROCESS = 'pricesProcess',
  ENTITY_NOT_EXIST = 'entityNotExist',
  FETCH = 'fetch'
}

export function displayError(
  error: any,
  operation?: Operation | null,
  itemName?: string,
  httpCode?: number | null
): void {
  console.error(error);
  // Display error

  const title = i18n.t(
    `global.errors.${
      operation === null || operation === undefined
        ? Operation.UNKNOWN
        : operation
    }.title`
  );
  const text = i18n.t(
    `global.errors.${
      operation === null || operation === undefined
        ? Operation.UNKNOWN
        : operation
    }.text`
  );
  const detail =
    httpCode !== null &&
    httpCode !== undefined &&
    [401, 403, 404, 462, 463, 465, 466, 468, 469, 470, 471, 500].includes(
      httpCode
    )
      ? `: ${i18n.t(`global.errors.common.${httpCode}`, { n: itemName })}`
      : '';
  store.commit(
    'app/DISPLAY_TOAST',
    Toast.error(`${title}`, `${text}${detail}`)
  );
}
