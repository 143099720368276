import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SHComponent extends Vue {
  @Prop(String) cid!: string;

  getValue(key: string): any {
    const getValueFn = this.$store.getters['app/subheader/getValue'];
    return getValueFn(this.cid, key);
  }

  setValue(key: string, value: unknown): void {
    this.$store.commit('app/subheader/SET_COMPONENT_VALUES', {
      id: this.cid,
      key,
      value
    });
  }
}
