<template>
  <StatItem
    :title="$t('analyse.dashboard.communication.title')"
    icon-src="@/core/assets/img/icons/ico-com.svg"
    :button-title="$t('analyse.dashboard.communication.buttonTitle')"
    button-link="/dashboard/communication"
  >
    TODO: communication stats
  </StatItem>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import StatItem from './StatItem.vue';

@Component({ components: { StatItem } })
export default class StatItemCommunication extends Vue {}
</script>

<style lang="scss" scoped></style>
