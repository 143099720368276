/* eslint-disable @typescript-eslint/no-explicit-any */
import { Organisation } from '@/core/store/models';
import { AxiosResponse } from 'axios';
import EntityType from '../models/entityType';
import ApiClient from './ApiClient';
import paths from './paths';
import { Operation, displayError } from '../modules/apiError';

export default class EntitiesTypesClient extends ApiClient {
  constructor() {
    super(paths.ENTITY_TYPE_WS);
  }

  async fetch(
    organisation: Organisation
  ): Promise<AxiosResponse<Array<EntityType>>> {
    try {
      const response = await this.get({
        id: `${organisation.id}`
      });
      return response;
    } catch (error) {
      console.error(error);
      displayError(
        error,
        Operation.FETCH,
        `Fetch EntitiesTypes for:${organisation.name}`
      );
      return Promise.reject(new Error(error as string));
    }
  }

  async save(
    organisation: Organisation,
    entityType: EntityType
  ): Promise<AxiosResponse<EntityType>> {
    try {
      const exist = !!entityType.id;
      const method = (exist ? this.patch : this.post).bind(this);
      const response = await method(
        {
          id: `${organisation.id}`,
          ...(exist && { etId: `${entityType.id}` })
        },
        entityType
      );
      return response;
    } catch (error) {
      console.error(error);
      displayError(
        error,
        Operation.SAVE,
        `Save EntitiesTypes for:${entityType.name}`
      );
      return Promise.reject(new Error(error as string));
    }
  }

  async del(
    organisation: Organisation,
    entityType: EntityType
  ): Promise<AxiosResponse<boolean>> {
    try {
      const response = await this.delete({
        id: `${organisation.id}`,
        etId: `${entityType.id}`
      });

      return response;
    } catch (error) {
      console.error(error);
      displayError(
        error,
        Operation.DELETE,
        `Delete EntitiesType for: ${entityType.name}`
      );
      return Promise.reject(new Error(error as string));
    }
  }
}
