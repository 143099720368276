import ESmsType from '@/core/enum/ESmsType';
import EUnopenedHours from '@/core/enum/EUnopenedHours';
import { Entity } from '@/core/store/models';
import store from '@/core/store';
import {
  SendTargetedContacts,
  TargetedContact
} from '@/core/store/models/activationCampaign';
import { Pack } from '@/core/store/models/pack';
import { SmsEditorPrices } from '@/core/store/models/smsEditor';
import moment from 'moment';
import { ActivationCampaignClient } from '@/core/store/network';
import { EstimationsData } from '../store/model/packEstimations';
import { TargetContactsFilters } from '../store/model/targetedContacts';

/* eslint-disable no-restricted-syntax */
/* eslint-disable class-methods-use-this */
export default class CommunicationService {
  /**
   *
   * @param contactsByEntities list of entities and its target id
   * @param smsCostNumber prices of media
   * @param packsByEntitiesId Map of entityId to its pack
   * @returns data of included/excluded of all packs and there total
   */
  public calculateEstimations(
    contactsByEntities: SendTargetedContacts[],
    smsCostNumber: number,
    packsByEntitiesId: Record<number, Pack[]>
  ) {
    let estimation: EstimationsData = {
      included: [],
      excluded: [],
      totalIncluded: 0,
      totalExcluded: 0,
      totalSendMessage: 0,
      totalCost: 0,
      hasPack: false
    };

    const sumMessageNumberByEntities: Map<Entity, number> =
      this.sumMessageNumberByEntities(contactsByEntities, smsCostNumber);

    sumMessageNumberByEntities.forEach((messageNumberToSend, key) => {
      const entity: Entity = key;

      estimation.totalSendMessage += messageNumberToSend;
      if (entity.id) {
        // Check there are no undefined values
        if (packsByEntitiesId[entity.id] && packsByEntitiesId[entity.id][0]) {
          const pack = packsByEntitiesId[entity.id][0];

          estimation = this.packEstimationForEntity(
            estimation,
            pack,
            entity,
            messageNumberToSend
          );
          // If there's no pack
        } else if (entity.name) {
          estimation.totalExcluded += messageNumberToSend;

          estimation.excluded.push({
            entityId: entity.id,
            entityName: entity.name,
            number: messageNumberToSend
          });
        }
      }
    });

    // Descending sort
    estimation.included.sort((p1, p2) => p2.number - p1.number);
    estimation.excluded.sort((p1, p2) => p2.number - p1.number);

    estimation.hasPack =
      estimation.included.length > 0 || estimation.excluded.length > 0;

    return estimation;
  }

  public createPricesFromRequest(prices: any): SmsEditorPrices[] {
    // Simple
    const simple: SmsEditorPrices = this.priceMapper(ESmsType.SIMPLE, prices);

    // Premium
    const premium: SmsEditorPrices = this.priceMapper(ESmsType.PREMIUM, prices);

    return [simple, premium];
  }

  private priceMapper(smsType: ESmsType, data: any): SmsEditorPrices {
    let pilotTypeValue: string;
    switch (smsType) {
      case ESmsType.SIMPLE:
        pilotTypeValue = 'classic';
        break;
      case ESmsType.PREMIUM:
        pilotTypeValue = 'premium';
        break;
      default:
        pilotTypeValue = 'classic';
        break;
    }
    const price: SmsEditorPrices = {
      type: smsType,
      national: data[pilotTypeValue].default[0],
      international: data[pilotTypeValue].default[1]
    };
    return price;
  }

  public findPriceBySMSType(smsType: ESmsType, prices: SmsEditorPrices[]) {
    return prices.find((price) => price.type === smsType);
  }

  /**
   *
   * @param estimate
   * @param pack
   * @param entity
   * @param messageNumberToSend
   * @returns estimate with data incremented by processing on pack, entity and messageNumberToSend
   */
  private packEstimationForEntity(
    estimate: EstimationsData,
    pack: Pack,
    entity: Entity,
    messageNumberToSend: number
  ): EstimationsData {
    const futurEstimate = estimate;

    const packContainAlreadyExcluded = pack.excluded > 0;

    const packPoolIsNotExceed =
      pack.included + messageNumberToSend <= pack.pool;

    if (entity.id && entity.name) {
      if (packContainAlreadyExcluded) {
        // Excluded only

        estimate.excluded.push({
          entityId: entity.id,
          entityName: entity.name,
          number: messageNumberToSend
        });
        futurEstimate.totalExcluded += messageNumberToSend;
      } else if (packPoolIsNotExceed) {
        // Included Only, send total
        estimate.included.push({
          entityId: entity.id,
          entityName: entity.name,
          number: messageNumberToSend
        });
        futurEstimate.totalIncluded += messageNumberToSend;
      } else {
        // Included & Excluded
        const underPool = pack.pool - pack.included;
        const overPool = messageNumberToSend - underPool;

        futurEstimate.totalIncluded += underPool;
        futurEstimate.totalExcluded += overPool;

        futurEstimate.excluded.push({
          entityId: entity.id,
          entityName: entity.name,
          number: overPool
        });

        futurEstimate.included.push({
          entityId: entity.id,
          entityName: entity.name,
          number: underPool
        });
      }
    }

    return futurEstimate;
  }

  /**
   * To calculate the total number of message by entity
   */
  private sumMessageNumberByEntities(
    contactsByEntities: SendTargetedContacts[],
    smsCostNumber: number
  ): Map<Entity, number> {
    const entityToMsgNbr: Map<Entity, number> = new Map();

    contactsByEntities.forEach((targetsByEntityId) => {
      const entity = store.getters['entities/getEntity'](
        targetsByEntityId.networkNode
      );
      entityToMsgNbr.set(
        entity,
        targetsByEntityId.contacts.length * smsCostNumber
      );
    });
    return entityToMsgNbr;
  }

  /**
   *
   * @param contactList
   * @param entitiesList
   * @returns List of grouped contacts by entity
   */
  public splitTargetedContactsByEntities(
    contactList: TargetedContact[],
    entitiesList: number[]
  ): SendTargetedContacts[] {
    const targetedContacts: SendTargetedContacts[] = [];
    const ClonedContactList = contactList.slice();

    // Loop over sub-entites
    for (const entityId of entitiesList) {
      const contactsByEntity: SendTargetedContacts = {
        networkNode: entityId.toString(),
        contacts: []
      };

      // Loop over all contacts
      ClonedContactList.forEach((contact) => {
        // If a contact have the same networkId/entityId
        // And is not already inside
        if (
          contact.networkNode === entityId.toString() &&
          !contactsByEntity.contacts.find(
            (alreadyIn) => alreadyIn === contact.id
          )
        ) {
          // We insert him into a list that stock all contact for this entity
          contactsByEntity.contacts.push(contact.id);
        }
      });
      targetedContacts.push(contactsByEntity);
    }
    return targetedContacts;
  }

  /**
   *
   * @param contactLists
   * @param filters
   * @returns List of contacts that pass all filters
   */
  public filterContacts(
    contactList: TargetedContact[],
    filters: TargetContactsFilters
  ): TargetedContact[] {
    const filteredTargetedContacts: TargetedContact[] = [];
    if (contactList.length !== 0) {
      for (const contact of contactList) {
        let genderCond = false;
        let ageCond = false;
        let birthMonthCond = false;

        if (this.filterByGender(contact.gender, filters.gender)) {
          genderCond = true;
        }

        if (this.filterByAge(contact.birthDate, filters.ageRange)) {
          ageCond = true;
        }

        if (this.filterByBirthMonth(contact.birthMonth, filters.birthMonth)) {
          birthMonthCond = true;
        }

        if (genderCond && ageCond && birthMonthCond) {
          filteredTargetedContacts.push(contact);
        }
      }
    }
    return filteredTargetedContacts;
  }

  private filterByGender(contactGender: number, genderFilter: number) {
    const noFilter = genderFilter === -1;

    const genderMatch = contactGender === genderFilter;

    return noFilter || genderMatch;
  }

  private filterByAge(contactAge: number, ageRangeFilter: number[]) {
    const minInRange = ageRangeFilter[0];
    const maxInRange = ageRangeFilter[1];

    const minPossible = 0;
    const maxPossible = 100;

    const noFilter = minInRange === minPossible && maxInRange === maxPossible;

    const ageIsBetween = contactAge >= minInRange && contactAge <= maxInRange;

    return noFilter || ageIsBetween;
  }

  private filterByBirthMonth(
    contactBirthMonth: number,
    birthMonthFilter: number
  ) {
    const noFilter = birthMonthFilter === -1;

    const birthMonthMatch = contactBirthMonth === birthMonthFilter;

    return noFilter || birthMonthMatch;
  }

  /**
   *  Find all leaves id of entity in the entity tree
   */
  public findLeavesId(entitiesId: number[], entitiesInTree: Entity[]) {
    const entities: Entity[] = [];
    let entitiesLeaves: Entity[] = [];
    const leavesId: number[] = [];

    // Find all entity selected in the tree of entities
    for (const entityId of entitiesId) {
      const entityOrNull = this.findEntity(entityId, entitiesInTree);
      if (entityOrNull != null) {
        entities.push(entityOrNull);
      }
    }

    // Find all leaves of previous entity if they have children (replace parent by childs)
    for (const entity of entities) {
      entitiesLeaves = entitiesLeaves.concat(this.findLeaves(entity));
    }

    // Return only ids
    for (const entity of entitiesLeaves) {
      if (entity.id) {
        leavesId.push(entity.id);
      }
    }

    return leavesId;
  }

  /**
   * Recursively search leaves in the entity tree
   */
  public findLeaves(entity: Entity): Entity[] {
    let leaves: Array<Entity> = [];
    if (entity.children === undefined) {
      leaves.push(entity);
    } else {
      entity.children.forEach((element) => {
        leaves = leaves.concat(this.findLeaves(element));
      });
    }
    return leaves;
  }

  /*
   * Recursively search entity in the entity tree from the ID
   */
  public findEntity(id: number, entities: Entity[]): Entity | null {
    // eslint-disable-next-line no-restricted-syntax
    for (const entity of entities) {
      if (entity.id === id) {
        return entity;
      }

      if (entity.children !== undefined) {
        const childEntity = this.findEntity(id, entity.children);
        if (childEntity !== null) {
          return childEntity;
        }
      }
    }

    return null;
  }

  /*
   * Check if the hour is between 8h and 20h
   * Check if the day is not sunday
   * Check if the date is not a holiday
   */
  public async isHolidayOrUnopenedHours() {
    const hour = moment().format('H');
    const date = moment().format('YYYY-MM-DD');

    const activationCampaignClient = new ActivationCampaignClient();

    let holidays: string[] = [];

    await activationCampaignClient
      .getBlackoutDate(date, date)
      .then((response) => {
        holidays = response.data;
      });

    const isDateHoliday = holidays.includes(date);

    return (
      isDateHoliday ||
      Number(hour) < EUnopenedHours.MINHOUR ||
      Number(hour) >= EUnopenedHours.MAXHOUR
    );
  }
}
