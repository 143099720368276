import getEnv from '@/core/utils/getEnv';
import { Organisation, Preference } from '@/core/store/models';
import NetworkError from '@/core/store/network/NetworkError';
import ApiClient from './ApiClient';
import paths from './paths';

export default class PreferencesClient extends ApiClient {
  constructor() {
    super(`${paths.USERS_PREFERENCES_WS}/`);
  }

  private baseURL = getEnv('USER_MANAGER_URL');

  async fetch(): Promise<Preference> {
    try {
      const response = await this.get({}, { baseURL: `${this.baseURL}` });

      if (response.status !== 200) {
        throw new Error(
          `fetch roles invalid response status :${response.status}`
        );
      }
      return response.data.map((et: Partial<Preference>) => new Preference(et));
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(error as string));
    }
  }

  async save(
    organisation: Organisation,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    user: any,
    preference: Preference
  ): Promise<Preference> {
    try {
      const exist = !!user.sub;
      const method = (exist ? this.patch : this.post).bind(this);
      const response = await method({}, preference, {
        baseURL: `${this.baseURL}`
      });

      if (response.status !== 200) {
        throw new NetworkError(
          `save user invalid response status :${response.status}`,
          response.status
        );
      }
      return new Preference({
        lastSelectedOrganisation: response.data.lastSelectedOrganisation
      });
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(error as string));
    }
  }
}
