<template>
  <div class="v-sidebar-content">
    <router-view />
    <Footer />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Footer from '@/core/components/layout/Footer.vue';

@Component({
  components: {
    Footer
  }
})
export default class Content extends Vue {}
</script>
<style lang="scss" scoped></style>
