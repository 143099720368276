import Organisation from '../views/Organisation.vue';

export default [
  {
    path: '/organisation',
    name: 'Organisation',
    component: Organisation,
    meta: {
      permission: {
        view: 'deployment'
      }
    }
  }
];
