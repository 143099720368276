<template>
  <div class="organisation">
    <b-container class="mt-4 mb-4">
      <b-row>
        <b-col>
          <h1>{{ $t('organisation.title') }}</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <TypesEditor />
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <EntitiesEditor />
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <UsersEditor />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import FullscreenMixins from '@/core/mixins/fullscreenMixin';
import { ENTITYTYPE, READ, USERS } from '@/conf/permissions';
import { getInstance } from '@/core/auth/wrapper';
import TypesEditor from './types/TypesEditor.vue';
import EntitiesEditor from './entities/EntitiesEditor.vue';
import UsersEditor from './users/UsersEditor.vue';

@Component({
  components: { TypesEditor, EntitiesEditor, UsersEditor },
  mixins: [FullscreenMixins]
})
export default class Organisation extends Vue {
  private p = { READ, USERS, ENTITYTYPE };

  get currentOrganisation(): Organisation {
    return this.$store.getters['organisations/current'];
  }

  @Watch('currentOrganisation')
  onCurrentOrganisationChanged(): void {
    const authService = getInstance();
    if (authService.$can(READ, ENTITYTYPE) && authService.$can(READ, USERS)) {
      this.$store.dispatch('entityTypes/fetch');
      this.$store.dispatch('users/fetch');
    }
  }
}
</script>
