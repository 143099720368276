<template>
  <treeselect
    :value="value.id"
    v-on:input="onSelected"
    :multiple="false"
    :options="entities"
    :normalizer="normalizer"
    :clearable="false"
    placeholder="Rechercher"
    class="entitySelector"
    :disabled="disabled"
    data-cy="entity-selector"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Entity } from '@/core/store/models';

@Component({})
export default class EntitySelector extends Vue {
  @Prop(Object) value?: Entity;

  @Prop({ default: false }) private disabled!: boolean;

  onSelected(entityId: string): void {
    this.$emit(
      'input',
      this.$store.getters['entities/getEntity'](parseInt(entityId, 10))
    );
  }

  get entities(): Entity[] {
    return this.$store.getters['entities/all'];
  }

  // eslint-disable-next-line class-methods-use-this
  private normalizer(node: Entity) {
    return {
      id: `${node.id}`,
      label: node.name,
      children: node.children
    };
  }
}
</script>
<style lang="scss">
.entitySelector {
  .vue-treeselect__single-value {
    &:before {
      @extend %fa-icon;
      @extend .fas;
      content: fa-content($fa-var-search);
      opacity: 0.6;
      margin-right: 10px;
    }
  }
}
</style>
