<template>
  <div>
    <button @click="toggleShow" class="anchor">{{ title }}</button>
    <div v-if="showMenu" class="menu">
      <div
        class="menu-item"
        v-for="item in this.items"
        v-bind:key="item.value"
        @click="itemClicked(item)"
      >
        {{ item.label }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { dropdownItem } from '@/core/utils/editorUtils';

@Component
export default class Dropdown extends Vue {
  showMenu = false;

  @Prop({
    type: String,
    required: true
  })
  readonly title!: string;

  @Prop({
    type: Array,
    required: true
  })
  readonly items!: Array<dropdownItem>;

  toggleShow() {
    this.showMenu = !this.showMenu;
  }

  itemClicked(item: dropdownItem) {
    this.toggleShow();
    this.$emit('click', item);
  }
}
</script>

<style scoped>
.anchor {
  padding-top: 7px;
  height: 38px;
  padding-bottom: 7px;

  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #fff;
  background-color: #498efb;
  border-color: #498efb;
}

.anchor::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.5em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.28em solid transparent;
  border-bottom: 0;
  border-left: 0.28em solid transparent;
}

.anchor:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  cursor: pointer;
}

.menu {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  color: #212529;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  list-style: none;
  margin: 0.125rem 0 0;
  padding: 0.5rem 0;
  position: absolute;
  text-align: left;
}

.menu-item {
  color: #212529;
  padding: 0.25rem 1.5rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.menu-item:hover {
  background-color: #f4f6f6;
  cursor: pointer;
}
</style>
