import i18n from '@/plugins/i18n';
import getPilotLink from '@/core/menu/main/getPilotLink';
import { getInstance } from '@/core/auth/wrapper';
import { COLLECT, VIEW } from '@/conf/permissions';

export default {
  name: 'collect',
  createMenu: (): any => {
    const authService = getInstance();
    return {
      hidden: !authService || !authService.$ability.can(VIEW, COLLECT),
      href: getPilotLink('campaigns/'),
      icon: 'fas fa-user-friends',
      title: i18n.t('collect.menu.title'),
      external: true,
      attributes: {
        target: '_blank'
      }
    };
  }
};
