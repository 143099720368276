/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { compile } from 'path-to-regexp';
import HttpClient from './HttpClient';

export default class ApiClient {
  private basePath = '';

  constructor(basePath: string) {
    this.basePath = basePath;
  }

  // eslint-disable-next-line class-methods-use-this
  private get httpClient() {
    return HttpClient.getInstance();
  }

  private fullPath(params: Record<string, string>, endPath?: string): string {
    let filler;
    if (endPath && endPath.length > 0) {
      filler = compile(this.basePath + endPath);
    } else {
      filler = compile(this.basePath);
    }

    return filler(params);
  }

  protected get(
    params: Record<string, string> = {},
    conf = {},
    endPath?: string
  ): Promise<AxiosResponse<any>> {
    return this.httpClient.get(this.fullPath(params, endPath), conf);
  }

  protected delete(
    params: Record<string, string> = {},
    conf = {},
    endPath?: string
  ): Promise<AxiosResponse<any>> {
    return this.httpClient.delete(this.fullPath(params, endPath), conf);
  }

  protected head(
    params: Record<string, string> = {},
    conf = {},
    endPath?: string
  ): Promise<AxiosResponse<any>> {
    return this.httpClient.head(this.fullPath(params, endPath), conf);
  }

  protected options(
    params: Record<string, string> = {},
    conf = {},
    endPath?: string
  ): Promise<AxiosResponse<any>> {
    return this.httpClient.options(this.fullPath(params, endPath), conf);
  }

  protected post(
    params: Record<string, string> = {},
    data = {},
    conf = {},
    endPath?: string
  ): Promise<AxiosResponse<any>> {
    return this.httpClient.post(this.fullPath(params, endPath), data, conf);
  }

  protected put(
    params: Record<string, string> = {},
    data = {},
    conf = {},
    endPath?: string
  ): Promise<AxiosResponse<any>> {
    return this.httpClient.put(this.fullPath(params, endPath), data, conf);
  }

  protected patch(
    params: Record<string, string> = {},
    data = {},
    conf = {},
    endPath?: string
  ): Promise<AxiosResponse<any>> {
    return this.httpClient.patch(this.fullPath(params, endPath), data, conf);
  }
}
