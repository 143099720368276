import { AxiosResponse } from 'axios';
import { i18n } from '@/plugins/index';
import getEnv from '@/core/utils/getEnv';
import {
  SmsEditorCampaignConfiguration,
  SmsEditorImage,
  SmsEditorMedia
} from '@/core/store/models/smsEditor';
import ESmsSaveStatus from '@/core/enum/ESmsSaveStatus';
import { Toast } from '@/core/store/models';
import store from '@/core/store';
import ApiClient from './ApiClient';
import { Operation, displayError } from '../modules/apiError';
import paths from './paths';

enum EndPointFinalPath {
  GET_CONF = '/conf/:campaignId',
  SMS = '/:campaignId',
  IMAGE = '/image/sms/:campaignId/0',
  TEST = '/sendtest/:campaignId'
}
const CAMPAIGN_ID_SUFFIX = '_0_und';

export default class extends ApiClient {
  private debounce: any;

  constructor() {
    super(paths.SMS_EDITOR_WS);
  }

  private baseURL = getEnv('SMS_EDITOR_URL');

  async getConfByCampaignId(
    campaignId: string
  ): Promise<AxiosResponse<SmsEditorCampaignConfiguration>> {
    try {
      const response = await this.get(
        { campaignId: `${campaignId}${CAMPAIGN_ID_SUFFIX}` },
        {
          baseURL: `${this.baseURL}`,
          params: {
            ctx: 'campaign-edition'
          }
        },
        `${EndPointFinalPath.GET_CONF}`
      );
      return response;
    } catch (error) {
      displayError(error, Operation.GET_CONF, 'SmsEditorClient');
      return Promise.reject(error);
    }
  }

  async getSms(
    campaignId: string
  ): Promise<AxiosResponse<SmsEditorMedia | Array<void>>> {
    try {
      const response = await this.get(
        { campaignId: `${campaignId}${CAMPAIGN_ID_SUFFIX}` },
        { baseURL: `${this.baseURL}` },
        `${EndPointFinalPath.SMS}`
      );
      return response;
    } catch (error) {
      displayError(error, Operation.READ, 'SmsEditorClient');
      return Promise.reject(error);
    }
  }

  async updateSms(
    campaignId: string,
    media: SmsEditorMedia,
    callback: (status: ESmsSaveStatus) => void
  ) {
    clearTimeout(this.debounce);
    callback(ESmsSaveStatus.SAVING);
    this.debounce = setTimeout(async () => {
      try {
        await this.post(
          { campaignId: `${campaignId}${CAMPAIGN_ID_SUFFIX}` },
          media,
          { baseURL: `${this.baseURL}` },
          `${EndPointFinalPath.SMS}`
        );
        callback(ESmsSaveStatus.SAVED);
      } catch (error) {
        displayError(error, Operation.SAVE, 'SmsEditorClient');
        callback(ESmsSaveStatus.ERROR);
      }
    }, 1500);
  }

  async uploadImage(
    campaignId: string,
    image: File
  ): Promise<AxiosResponse<SmsEditorImage>> {
    try {
      const formData = new FormData();
      formData.append('image', image);
      const headers = {
        Accept: 'multipart/form-data',
        'Content-Type': 'multipart/form-data'
      };
      const response = await this.post(
        { campaignId: `${campaignId}${CAMPAIGN_ID_SUFFIX}` },
        formData,
        {
          baseURL: `${this.baseURL}`,
          headers
        },
        `${EndPointFinalPath.IMAGE}`
      );
      return response;
    } catch (error) {
      displayError(error, Operation.SAVE, 'SmsEditorClient');
      return Promise.reject(error);
    }
  }

  async sendSmsTest(campaignId: string, phonNumber: string) {
    try {
      await this.post(
        {
          campaignId: `${campaignId}${CAMPAIGN_ID_SUFFIX}`
        },
        {
          recipients: [phonNumber]
        },
        { baseURL: `${this.baseURL}` },
        `${EndPointFinalPath.TEST}`
      );
      store.commit(
        'app/DISPLAY_TOAST',
        Toast.success(
          `${i18n.t('smsEditor.smsSendTest.success.title')}`,
          `${i18n.t('smsEditor.smsSendTest.success.message')}`
        )
      );
    } catch (error) {
      store.commit(
        'app/DISPLAY_TOAST',
        Toast.error(
          `${i18n.t('smsEditor.smsSendTest.error.title')}`,
          `${i18n.t('smsEditor.smsSendTest.error.message')}`
        )
      );
    }
  }
}
