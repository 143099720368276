import { RouteConfig } from 'vue-router';
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable class-methods-use-this */
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from 'vuex-module-decorators';

import store from '@/core/store';
import router from '@/core/router';
import i18n from '@/plugins/i18n';
import {
  AnalyseAppModule,
  CollectAppModule,
  CommunicationAppModule,
  ContactsAppModule,
  OrganisationAppModule
} from '@/modules';
import { AppModule } from '@/core/services/modularity/appModule';
import MenuItemCreator from '@/core/menu/menuItemCreator';
import MenuItem from '@/core/menu/menuItem';
import menuOrder from '@/conf/menu';
import loadMainMenu from '@/core/menu/main/loadMainMenu';
import Toast from '@/core/store/models/toast';
import { ApplicationState } from './state';

interface BuiltMenuItem {
  name: string;
  item: MenuItem;
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: 'app'
})
export default class Application
  extends VuexModule
  implements ApplicationState
{
  private collapsed = false;

  private isMobile = false;

  private rtl = false;

  private menu: BuiltMenuItem[] = [];

  private appModules: Array<AppModule> = [];

  private mainToast = new Toast({});

  get currentMenu(): any[] {
    const sortedMenu = this.menu.sort(
      (a, b) => menuOrder.indexOf(a.name) - menuOrder.indexOf(b.name)
    );
    return sortedMenu.map((bmi) => bmi.item);
  }

  @Mutation
  private ADD_MENU_ITEM(itemCreator: MenuItemCreator) {
    const item = itemCreator.createMenu();
    const { name } = itemCreator;
    if (item) {
      this.menu.push({ name, item });
    }
  }

  @Mutation
  private CLEAR_MENU() {
    this.menu = [];
  }

  get isOnMobile(): boolean {
    return this.isMobile;
  }

  get isCollapsed(): boolean {
    return this.collapsed;
  }

  get isRTL(): boolean {
    return this.rtl;
  }

  get toast(): Toast | null {
    return this.mainToast;
  }

  @Mutation
  public SET_COLLAPSED(isCollapsed: boolean): void {
    this.collapsed = isCollapsed;
  }

  @Mutation
  public SET_IS_MOBILE(isMobile: boolean): void {
    this.isMobile = isMobile;
  }

  @Mutation
  public SET_RTL(isRTL: boolean): void {
    this.rtl = isRTL;
  }

  @Mutation
  public DISPLAY_TOAST(toast: Toast): void {
    this.mainToast = toast;
    this.mainToast.visible = true;
  }

  @Action
  public registerAllModules(): void {
    store.dispatch('app/registerAppModule', OrganisationAppModule);
    store.dispatch('app/registerAppModule', AnalyseAppModule);
    store.dispatch('app/registerAppModule', CommunicationAppModule);
    store.dispatch('app/registerAppModule', CollectAppModule);
    store.dispatch('app/registerAppModule', ContactsAppModule);
  }

  @Action
  public rebuildMenu(): void {
    store.commit('app/CLEAR_MENU');
    loadMainMenu();
    this.appModules
      .filter((module) => module.menu && module.menu.createMenu !== undefined)
      .forEach((module) => store.commit('app/ADD_MENU_ITEM', module.menu));
  }

  /**
   * Register dynamicaly a new app module
   * @param module
   */
  @Action
  public registerAppModule(module: AppModule): void {
    if (this.appModules.find((m) => m.name === module.name)) {
      console.error(`Module ${module.name} already registered`);
      return;
    }
    this.appModules.push(module);

    // Register locales
    Object.keys(module.locales).forEach((locale) => {
      i18n.mergeLocaleMessage(locale, module.locales[locale]);
    });

    getModule(module.store, store);
    module.routes.forEach((route: RouteConfig) => router.addRoute(route));

    store.dispatch(`${module.name}/initialize`, null, { root: true });
  }
}
