import { LINE_BREAK_LENGTH, SMS_MAX } from '.';
import SmsCharType from './SmsCharType';
import SmsMode from './SmsMode';

export default class SmsModeWithLink implements SmsMode {
  private smsCharType: SmsCharType;

  constructor(smsCharType: SmsCharType) {
    this.smsCharType = smsCharType;
  }

  getSmsMaxUnitLength(): number {
    return this.smsCharType.getSmsMaxUnitLength();
  }

  setSmsCharType(smsCharType: SmsCharType): void {
    this.smsCharType = smsCharType;
  }

  getCharset(): string {
    return this.smsCharType.getCharset();
  }

  countCharacters(text: string): number {
    return this.smsCharType.countCharacters(text);
  }

  countSms(contentLength: number): number {
    return this.smsCharType.countSms(contentLength);
  }

  countNextSmsLimit(smsCount: number): number {
    return this.smsCharType.countNextSmsLimit(smsCount);
  }

  computeMessageLimit(): number {
    return SMS_MAX * this.smsCharType.getSmsMaxUnitLength();
  }

  computeBodyLimit(
    footer: string,
    linkTitle: string | undefined,
    linkHref: string | undefined
  ): number {
    /*
     * If there is a title with a link
     * \ntitle\nlink => 2 "\n"
     * If no title
     * \nlink => 1 "\n"
     */
    const linkLength = linkTitle
      ? this.smsCharType.countCharacters(linkTitle) +
        (linkHref?.length || 0) +
        2 * LINE_BREAK_LENGTH
      : (linkHref?.length || 0) + LINE_BREAK_LENGTH;

    // There is a line break before the footer
    return (
      this.computeMessageLimit() -
      linkLength -
      (footer.length + LINE_BREAK_LENGTH)
    );
  }

  computeLinkTitleLimit(
    fulltextValue: string,
    footer: string,
    linkHref: string | undefined
  ): number {
    // There is 2 lines breaks for a link
    const linkLength = (linkHref?.length || 0) + 2 * LINE_BREAK_LENGTH;

    // Min (255, compute) to get the min value of the initial limit 50 and the result
    // Max (0, compute) to avoid negative value => when no remaining length for title
    return Math.max(
      0,
      Math.min(
        255,
        this.computeMessageLimit() -
          this.smsCharType.countCharacters(fulltextValue) -
          linkLength -
          (footer.length + LINE_BREAK_LENGTH)
      )
    );
  }
}
