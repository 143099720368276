import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Store } from 'vuex';
import RootState from '../modules/rootState';

export default class HttpClient {
  private static instance: HttpClient;

  private axios: AxiosInstance;

  private store: Store<RootState>;

  public static createInstance(baseUrl: string, store: Store<RootState>): void {
    if (HttpClient.instance) {
      throw new Error(
        '(error): Instantiation failed: HttpClient already instanciated'
      );
    }
    HttpClient.instance = new HttpClient(baseUrl, store);
  }

  public static getInstance(): HttpClient {
    if (HttpClient.instance === undefined) {
      throw new Error(
        '(error): Get instance failed: HttpClient not yet instanciated'
      );
    }
    return HttpClient.instance;
  }

  private constructor(baseUrl: string, store: Store<RootState>) {
    this.store = store;
    this.axios = axios.create({
      baseURL: baseUrl,
      timeout: 30000,
      withCredentials: false
    });

    // Add access token to each requests
    const authInterceptor = (config: AxiosRequestConfig) => {
      const newConfig = config;
      if (this.store.getters['auth/isAuthenticated'] && newConfig.headers) {
        newConfig.headers.Authorization = `Bearer ${this.store.getters['auth/token']}`;
      }
      return newConfig;
    };
    this.axios.interceptors.request.use(authInterceptor);
  }

  async get(url: string, conf = {}): Promise<AxiosResponse<any>> {
    try {
      const response = await this.axios.get(url, conf);
      return await Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async delete(url: string, conf = {}): Promise<AxiosResponse<any>> {
    try {
      const response = await this.axios.delete(url, conf);
      return await Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async head(url: string, conf = {}): Promise<AxiosResponse<any>> {
    try {
      const response = await this.axios.head(url, conf);
      return await Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async options(url: string, conf = {}): Promise<AxiosResponse<any>> {
    try {
      const response = await this.axios.options(url, conf);
      return await Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async post(url: string, data = {}, conf = {}): Promise<AxiosResponse<any>> {
    try {
      const response = await this.axios.post(url, data, conf);
      return await Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async put(url: string, data = {}, conf = {}): Promise<AxiosResponse<any>> {
    try {
      const response = await this.axios.put(url, data, conf);
      return await Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async patch(url: string, data = {}, conf = {}): Promise<AxiosResponse<any>> {
    try {
      const response = await this.axios.patch(url, data, conf);
      return await Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
