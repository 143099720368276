// eslint-disable-next-line
import jwt_decode from "jwt-decode";
import Vue from 'vue';
import {
  createAuth0Client,
  GetTokenSilentlyOptions,
  LogoutOptions,
  Auth0Client,
  RedirectLoginOptions,
  PopupLoginOptions,
  GetTokenWithPopupOptions,
  IdToken
} from '@auth0/auth0-spa-js';

import { Store } from 'vuex';
import RootState from '@/core/store/modules/rootState';
import store from '@/core/store';

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = () => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

export interface AuthAppState {
  targetUrl: string;
}

export interface AuthOptions {
  domain: string;
  clientId: string;
  onRedirectCallback?: (appState: AuthAppState) => void;
  store?: Store<RootState>;
  redirectUri?: string;
  // eslint-disable-next-line
  options?: any;
}

export class VueAuth extends Vue {
  private client: Promise<Auth0Client>;

  private options: AuthOptions;

  private loading!: boolean;

  // eslint-disable-next-line
  private user: any;

  private popupOpen: boolean;

  constructor(client: Promise<Auth0Client>, options: AuthOptions) {
    super({
      data() {
        return {
          loading: true,
          authenticated: false
        };
      }
    });
    if (options.store) {
      this.$store = options.store;
    }
    this.client = client;
    this.options = options;
    this.user = {};
    this.popupOpen = false;
  }

  public async init() {
    try {
      // If the user is returning to the app after authentication..
      if (
        window.location.search.includes('code=') &&
        window.location.search.includes('state=')
      ) {
        // handle the redirect and retrieve tokens
        const { appState } = await (await this.client).handleRedirectCallback();

        // Notify subscribers that the redirect callback has happened, passing the appState
        // (useful for retrieving any pre-authentication state)
        if (!this.options.onRedirectCallback) {
          this.options.onRedirectCallback = DEFAULT_REDIRECT_CALLBACK;
        }
        this.options.onRedirectCallback(appState);
      }
    } finally {
      // Initialize our internal authentication state
      this.user = await (await this.client).getUser();
      this.loading = false;
    }
  }

  async loginWithPopupOpen(opt: PopupLoginOptions): Promise<void> {
    try {
      await (await this.client).loginWithPopup(opt);
    } catch (e) {
      console.error(e);
    } finally {
      this.popupOpen = false;
    }
    this.user = await (await this.client).getUser();
  }

  /** Authenticates the user using the redirect method */
  async loginWithRedirect(opt?: RedirectLoginOptions): Promise<void> {
    return (await this.client).loginWithRedirect(opt);
  }

  /** Returns all the claims present in the ID token */
  async getIdTokenClaims(): Promise<IdToken | undefined> {
    return (await this.client).getIdTokenClaims();
  }

  /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
  async getTokenSilently(
    opt?: GetTokenSilentlyOptions
  ): Promise<string | void> {
    return (await this.client).getTokenSilently(opt).then(async (token) => {
      // TODO use auth0 client decoder ?
      const accessToken = jwt_decode(token);
      // merge accessToken in user
      this.user = await (await this.client).getUser();
      this.user.accessToken = accessToken;

      return token;
    });
  }

  /** Gets the access token using a popupOpen window */
  async getTokenWithPopupOpen(
    opt?: GetTokenWithPopupOptions
  ): Promise<string | undefined> {
    return (await this.client).getTokenWithPopup(opt);
  }

  /** Logs the user out and removes their session on the authorization server */
  async logout(opt?: LogoutOptions): Promise<void> {
    return (await this.client).logout(opt);
  }

  async isAuthenticated(): Promise<boolean> {
    return (await this.client).isAuthenticated();
  }

  popupIsOpen(): boolean {
    return this.popupOpen;
  }

  isLoading(): boolean {
    return this.loading;
  }

  getUser(): any {
    return this.user;
  }
}

let instance: VueAuth;

export const getInstance = (): VueAuth => instance;

async function useAuth0(options: AuthOptions) {
  if (!instance) {
    try {
      const client = createAuth0Client({
        domain: options.domain,
        clientId: options.clientId,
        // For Cypress tests => stores tokens in localStorage
        // cacheLocation: ((<any>window).Cypress) ? "localstorage" : "memory",
        cacheLocation: 'localstorage',
        authorizationParams: {
          redirect_uri: options.redirectUri,
          audience: options.options ? options.options.audience : undefined
        }
      });
      /* eslint-enable */
      options.onRedirectCallback =
        options.onRedirectCallback || DEFAULT_REDIRECT_CALLBACK;
      instance = new VueAuth(client, options);
      await instance.init();
    } catch (e) {
      console.log(e);
    }
  }

  return instance;
}

/* eslint no-param-reassign: ["error", {"ignorePropertyModificationsFor": ["$auth "] }] */
export default {
  install(vue: typeof Vue, options?: AuthOptions): void {
    if (!options) {
      throw new Error('Options for Authentication must be defined.');
    }

    options.store = store;
    vue.prototype.$auth = useAuth0(options);
  }
};
