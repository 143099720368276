/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class FeedbacksMixin extends Vue {
  genericFeedback(v: any): string {
    if (v.$params.required && !v.required)
      return `${this.$t('validators.required')}`;
    if (v.$params.maxLength && !v.maxLength)
      return `${this.$t('validators.maxLength', {
        n: v.$params.maxLength.max
      })}`;
    if (v.$params.minLength && !v.minLength)
      return `${this.$t('validators.minLength', {
        n: v.$params.minLength.min
      })}`;
    if (v.$params.email && !v.email) return `${this.$t('validators.email')}`;
    return '';
  }
}
