import store from '@/core/store';
import home from './home';
import styleguide from './styleguide';
import training from './training';

export default function loadMainMenu(): void {
  store.commit('app/ADD_MENU_ITEM', home);
  store.commit('app/ADD_MENU_ITEM', training);
  store.commit('app/ADD_MENU_ITEM', styleguide);
}
