var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"editor-component"},[_c('div',{staticClass:"d-flex flex-row-reverse mb-3"},[_c('span',{staticClass:"pl-2 btn-close align-self-center",on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.$t('global.actions.close'))),_c('i',{staticClass:"di-close"})])]),(_vm.component)?_c('div',[(_vm.isTextComponent)?_c('web-text-editor',{attrs:{"items":_vm.items},model:{value:(_vm.editedComponent),callback:function ($$v) {_vm.editedComponent=$$v},expression:"editedComponent"}}):(_vm.isImageComponent)?_c('web-image-editor',{on:{"change":_vm.onImageChange},model:{value:(_vm.editedComponent),callback:function ($$v) {_vm.editedComponent=$$v},expression:"editedComponent"}}):(_vm.isCouponComponent)?_c('web-coupon-editor',{on:{"validity-change":_vm.onValidityChange},model:{value:(_vm.editedComponent),callback:function ($$v) {_vm.editedComponent=$$v},expression:"editedComponent"}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"mt-3 text-center"},[_c('button',{class:[
        'btn',
        'btn-outline-dark',
        {
          'mr-3': !_vm.isImageComponent
        }
      ],attrs:{"type":"button"},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t('global.actions.cancel'))+" ")]),(!_vm.isImageComponent)?_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":_vm.isNotSubmittable,"type":"button"},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('global.actions.confirm'))+" ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }