import { Component, Vue } from 'vue-property-decorator';

@Component
export default class FullscreenMixin extends Vue {
  mounted(): void {
    this.$store.commit('app/subheader/DISPLAY', false);
  }

  destroyed(): void {
    this.$store.commit('app/subheader/DISPLAY', true);
  }
}
