import { AxiosResponse } from 'axios';
import getEnv from '@/core/utils/getEnv';
import { Operation, displayError } from '../modules/apiError';
import ApiClient from './ApiClient';
import paths from './paths';
import {
  CampaignDTO,
  CreateCampaignRequest,
  UpdateCampaignRequest,
  CampaignGetTargetRequest,
  TargetedContactsResponse,
  CampaignCreateTargetRequest
} from '../models/activationCampaign';

enum EndPointFinalPath {
  // NB : xxx/:YYY/zzz -> YYY is a variable to need to be defined
  CAMPAIGNS_BY_ENTITY_ID = '/entities/:etId/campaigns',
  SEND_CAMPAIGN_BY_ID = '/campaigns/:cpId?/send',
  CAMPAIGN_BY_ID = '/campaigns/:cpId?',
  SUB_CAMPAIGNS_BY_CP_ID = '/campaigns/:cpId/sub-campaigns',
  TARGET_BY_CP_ID = '/target/:cpId',
  GET_BLACKOUT_DATE = '/date-management/blackoutdate'
}
export default class extends ApiClient {
  constructor() {
    super(paths.ACTIVATION_CAMPAIGN_MANAGER_WS);
  }

  private baseURL = getEnv('ACTIVATION_CAMPAIGN_MANAGER_URL');

  private customTimeOut = getEnv('CLIENT_TIMEOUT');

  async getCampaignInfoByid(
    campaignId: string
  ): Promise<AxiosResponse<CampaignDTO>> {
    try {
      const response = await this.get(
        {
          cpId: campaignId
        },
        {
          baseURL: `${this.baseURL}`
        },
        EndPointFinalPath.CAMPAIGN_BY_ID
      );
      return response;
    } catch (error) {
      displayError(error, Operation.READ, 'ActivationCampaignClient');
      return Promise.reject(error);
    }
  }

  async saveCampaign(
    campaignId: string,
    campaign: UpdateCampaignRequest
  ): Promise<AxiosResponse<CampaignDTO>> {
    try {
      const response = await this.patch(
        {
          cpId: campaignId
        },
        campaign,
        {
          baseURL: `${this.baseURL}`,
          timeout: this.customTimeOut
        },
        EndPointFinalPath.CAMPAIGN_BY_ID
      );
      return response;
    } catch (error) {
      displayError(error, Operation.READ, 'ActivationCampaignClient');
      return Promise.reject(error);
    }
  }

  async getSubCampaignsByCpId(
    campaignId: string
  ): Promise<AxiosResponse<Array<number>>> {
    try {
      const response = await this.get(
        {
          cpId: campaignId
        },
        { baseURL: `${this.baseURL}` },
        EndPointFinalPath.SUB_CAMPAIGNS_BY_CP_ID
      );
      return response;
    } catch (error) {
      displayError(error, Operation.READ, 'ActivationSubCampaign');
      return Promise.reject(error);
    }
  }

  async getCampaignsByEntityId(
    entityId: number,
    page: number,
    size: number,
    sort: string | null,
    query: string | null
  ): Promise<AxiosResponse<Array<CampaignDTO>>> {
    try {
      const response = await this.get(
        {
          etId: entityId.toString()
        },
        {
          baseURL: `${this.baseURL}`,
          params: { page, size, sort, q: query }
        },
        EndPointFinalPath.CAMPAIGNS_BY_ENTITY_ID
      );
      return response;
    } catch (error) {
      displayError(error, Operation.READ, 'ActivationCampaignClient');
      return Promise.reject(error);
    }
  }

  async createCampaignByEntityId(
    entityId: number,
    campaign: CreateCampaignRequest
  ): Promise<AxiosResponse<CampaignDTO>> {
    try {
      const response = await this.put(
        {
          etId: entityId.toString()
        },
        campaign,
        {
          baseURL: `${this.baseURL}`
        },
        EndPointFinalPath.CAMPAIGNS_BY_ENTITY_ID
      );
      return response;
    } catch (error) {
      displayError(error, Operation.READ, 'ActivationCampaignClient');
      return Promise.reject(error);
    }
  }

  async sendCampaignById(cpId: string): Promise<AxiosResponse<void>> {
    try {
      const response = await this.post(
        {
          cpId
        },
        {},
        {
          baseURL: `${this.baseURL}`
        },
        EndPointFinalPath.SEND_CAMPAIGN_BY_ID
      );
      return response;
    } catch (error) {
      displayError(error, Operation.READ, 'ActivationCampaignClient');
      return Promise.reject(error);
    }
  }

  async fetchPossibleContactsByCampaignId(
    cpId: string,
    body: CampaignGetTargetRequest
  ): Promise<AxiosResponse<TargetedContactsResponse>> {
    try {
      const response = await this.post(
        {
          cpId
        },
        body,
        {
          baseURL: `${this.baseURL}`,
          timeout: this.customTimeOut
        },
        EndPointFinalPath.TARGET_BY_CP_ID
      );
      return response;
    } catch (error) {
      displayError(error, Operation.TARGET_CONTACT, 'ActivationCampaignClient');
      return Promise.reject(error);
    }
  }

  async sendTargetedContactsByCampaignId(
    cpId: string,
    contactsByEntity: CampaignCreateTargetRequest
  ): Promise<AxiosResponse<any>> {
    try {
      const response = await this.patch(
        {
          cpId
        },
        contactsByEntity,
        {
          baseURL: `${this.baseURL}`,
          timeout: this.customTimeOut
        },
        EndPointFinalPath.TARGET_BY_CP_ID
      );
      return response;
    } catch (error) {
      displayError(error, Operation.TARGET_CONTACT, 'ActivationCampaignClient');
      return Promise.reject(error);
    }
  }

  async getBlackoutDate(
    startDate: string,
    endDate: string
  ): Promise<AxiosResponse<Array<string>>> {
    try {
      const response = await this.post(
        {},
        {
          startDate: startDate.toString(),
          endDate: endDate.toString()
        },
        {
          baseURL: `${this.baseURL}`,
          timeout: this.customTimeOut
        },
        EndPointFinalPath.GET_BLACKOUT_DATE
      );
      return response;
    } catch (error) {
      displayError(error, Operation.READ, 'ActivationCampaignClient');
      return Promise.reject(error);
    }
  }
}
