import ESmsType from '@/core/enum/ESmsType';
import { PageType } from '@/core/utils/pageUtils';
import { SmsType } from '@/core/utils/smsUtils';

export interface SmsMediaType {
  type: ESmsType;
  sms: SmsType;
  page: PageType;
  count: number;
}

export const EMPTY_SMS_PAGE: PageType = {
  style: {
    colors: {
      background: '#D9E1EB',
      foreground: '#FFFFFF',
      font: '#30445F'
    }
  },
  components: []
};
