export default class EntityType {
  id?: number;

  isEntityGroup?: boolean;

  organisation?: number;

  quotaEntity?: number;

  quotaUser?: number;

  name?: string;

  constructor(data: Partial<EntityType>) {
    Object.assign(this, data);
  }
}
