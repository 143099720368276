/* eslint-disable class-methods-use-this */
import { VuexModule, Module, Action } from 'vuex-module-decorators';

import store from '@/core/store';
import { ContactsState } from './state';

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: 'contacts'
})
export default class Communication extends VuexModule implements ContactsState {
  todo = '';

  @Action
  public initialize(): void {
    console.log('Initializing Contacts');
  }
}
