export const SMS_SENDER = '[MULTICAMPAIGNS]';

export const LINK_PREVIEW = 'https://dol.mn/+123abc';

export const SMS_FOOTER = 'STOP au 36180';

export const SMS_MAX = 7;

export const LINE_BREAK_LENGTH = 1;

// We use 2 SMS prices : this one for the estimation at the end
export function getSmsRoundedPrice(
  unitPrice: number,
  quantity: number
): number {
  return Math.round(quantity * (unitPrice / 100000) * 100) / 100;
}

// And this one to display the exact price of a SMS
export function getSmsPrice(unitPrice: number, quantity: number): number {
  return quantity * (unitPrice / 100000);
}

export type SmsType = {
  sender: string;
  message: string;
  linkTitle: string;
};
