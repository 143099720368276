<template>
  <b-form @submit.stop.prevent="send" novalidate>
    <div v-if="isHolidayOrUnopenedHours" class="error">
      <HolidayPopup
        :title="$t('global.errors.holidayError.title')"
        :on-close="() => $router.push({ name: 'communication-campaign-list' })"
      >
        {{ $t('global.errors.holidayError.text') }}
      </HolidayPopup>
    </div>
    <b-row>
      <b-col class="estimateCol">
        <p-card
          :title="$t('communication.campaign.form.confirmation.title')"
          class="estimateCard"
        >
          <b-col>
            <b-row class="estimateRow1">
              <div class="h5__iconed">
                <i class="pi pi-clock" />
                <h5 class="timeZoneH5">
                  {{ $t('communication.campaign.form.confirmation.timeZone') }}
                </h5>
              </div>

              <b-select
                v-if="!loading"
                id="input-timezone"
                v-model="defaultTimeZone"
                :options="timeZonesOptions"
              ></b-select>
              <b-skeleton type="input" width="11rem" v-else />
            </b-row>
            <hr class="hr--blue" />
            <b-row class="rowTitle">
              <div class="h5__iconed">
                <i class="pi pi-mobile" />
                <h5>
                  {{
                    $t('communication.campaign.form.confirmation.campaignType')
                  }}
                </h5>
              </div>

              <span
                v-if="!loading"
                class="align-with-orderlist"
                data-cy="campaign-type"
                >{{ campaignType }}</span
              >
              <b-skeleton v-else width="15%" class="align-with-orderlist" />
            </b-row>
            <b-row class="rowTitle">
              <h5>
                {{
                  $t(
                    'communication.campaign.form.confirmation.selectedContacts'
                  )
                }}
              </h5>
              <span
                v-if="!loading"
                class="align-with-orderlist"
                data-cy="selected-contacts-total"
                >{{ totalContactsSelected }}</span
              >
              <b-skeleton class="align-with-orderlist" v-else width="10%"
            /></b-row>
            <hr class="hr--blue" />
            <div>
              <b-row class="rowTitle">
                <div class="h5__iconed">
                  <i class="pi pi-comment"></i>
                  <h5>
                    {{
                      $t(
                        'communication.campaign.form.confirmation.sendMessageNumber'
                      )
                    }}
                  </h5>
                </div>
                <span
                  v-if="!loading"
                  class="align-with-orderlist"
                  data-cy="send-messages-total"
                  >{{ totalSendMessage }}</span
                >
                <b-skeleton v-else width="10%" class="align-with-orderlist" />
              </b-row>
              <div v-if="hasPack && !loading">
                <b-row class="rowTitle"
                  ><h5>
                    {{
                      $t('communication.campaign.form.confirmation.included')
                    }}
                  </h5>
                  <span
                    class="align-with-orderlist"
                    data-cy="included-message-total"
                    >{{ totalIncluded }}</span
                  >
                </b-row>
                <b-row>
                  <order-list
                    v-if="hasIncluded"
                    v-model="included"
                    dataKey="entityId"
                  >
                    <template #item="slotProps">
                      <div
                        class="p-orderlist-items"
                        data-cy="included-message-item"
                      >
                        <span>{{ slotProps.item.entityName }}</span>
                        <span class="span--blue">{{
                          slotProps.item.number
                        }}</span>
                      </div>
                    </template>
                  </order-list>
                </b-row>
                <b-row class="rowTitle"
                  ><h5>
                    {{ $t('communication.campaign.form.confirmation.over') }}
                  </h5>
                  <span
                    class="align-with-orderlist"
                    data-cy="excluded-message-total"
                    >{{ totalExcluded }}</span
                  ></b-row
                >
                <b-row>
                  <order-list
                    v-if="hasExcluded"
                    v-model="excluded"
                    dataKey="entityId"
                  >
                    <template #item="slotProps">
                      <div
                        class="p-orderlist-items"
                        data-cy="excluded-message-item"
                      >
                        <span>{{ slotProps.item.entityName }}</span>
                        <span class="span--blue">{{
                          slotProps.item.number
                        }}</span>
                      </div>
                    </template>
                  </order-list>
                </b-row>
              </div>
              <hr class="hr--blue" />
            </div>
            <div></div>
            <b-row class="rowTitle"
              ><h5>
                {{ $t('communication.campaign.form.confirmation.totalCost') }}
              </h5>
              <span
                v-if="!loading"
                class="align-with-orderlist"
                data-cy="cost-total"
                >{{ totalCost }} €</span
              ><b-skeleton v-else width="10%" class="align-with-orderlist" />
            </b-row>
            <b-row style="justify-content: flex-end">
              <span class="align-with-orderlist">{{
                $t('communication.campaign.form.confirmation.estimeBeforeSend')
              }}</span>
            </b-row>
            <b-row class="rowCgu">
              <b-form-checkbox
                id="cgu"
                class="inputCgu"
                v-model="checkBoxCguValue"
              >
                <label
                  >{{
                    $t('communication.campaign.form.confirmation.cgu.agree')
                  }}&nbsp;
                </label>
                <label class="linkCgu" @click="$bvModal.show('cguModal')">
                  {{ $t('communication.campaign.form.confirmation.cgu.link') }}
                </label>
                <b-form-invalid-feedback :state="checkBoxCguValue">{{
                  $t(
                    'communication.campaign.form.confirmation.cgu.invalid-feedback'
                  )
                }}</b-form-invalid-feedback>
              </b-form-checkbox>

              <b-modal id="cguModal" size="lg" hide-footer>
                <cgu></cgu>
                <b-button
                  class="mt-4"
                  block
                  @click="$bvModal.hide('cguModal')"
                  >{{
                    $t('communication.campaign.form.confirmation.cgu.close')
                  }}</b-button
                >
              </b-modal>
            </b-row>
          </b-col>
        </p-card>
      </b-col>
    </b-row>
    <b-row class="mt-4" align-h="between">
      <b-col cols="auto" class="mr-auto">
        <b-button
          variant="primary"
          :to="{
            name: 'communication-campaign-step-message',
            query: { cid: $route.query.cid }
          }"
          ><i class="fas fa-chevron-left" />
          {{ $t('global.actions.previous') }}</b-button
        >
      </b-col>
      <b-col cols="auto" class="mr-auto">
        <b-button :to="{ name: 'communication-campaign-list' }">{{
          $t('communication.campaign.actions.back')
        }}</b-button>
      </b-col>
      <b-col cols="auto">
        <b-button
          variant="primary"
          type="submit"
          :disabled="disabledSendButton"
          data-cy="next4"
        >
          <span v-if="!submitting">
            {{ $t('global.actions.send') }} <i class="fas fa-paper-plane" />
          </span>
          <span v-else> {{ $t('global.actions.loading') }} </span>
          <b-spinner v-if="submitting" small></b-spinner>
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import OrderList from 'primevue/orderlist';
import PCard from '@/core/components/cards/PCard.vue';
import ECampaignType from '@/core/enum/ECampaignType';
import HolidayPopup from '@/core/components/popups/HolidayPopup.vue';
import CommunicationService from '@/modules/communication/service/CommunicationService';
import Cgu from './Cgu.vue';
import { PackEstimation } from '../../store/model/packEstimations';

@Component({
  components: { PCard, Cgu, OrderList, HolidayPopup }
})
export default class StepConfirm extends Vue {
  checkBoxCguValue = null;

  submitting = false;

  loading = true;

  communicationService = new CommunicationService();

  isHolidayOrUnopenedHours = false;

  // Temporary variable, waiting to be getable (English?) from store

  async created() {
    try {
      this.loading = true;
      await this.$store.dispatch('communication/fetchCampaign', {
        entityId: this.$store.getters['entities/current'].id,
        campaignId: this.$route.query.cid
      });
      await this.$store.dispatch('communication/getEntityConfiguration');
      await this.$store.dispatch('communication/fetchSms');
      await this.$store.dispatch('communication/estimatePacksByEntities');
      this.isHolidayOrUnopenedHours =
        await this.communicationService.isHolidayOrUnopenedHours();
    } finally {
      this.loading = false;
    }
  }

  // Disable send button if:
  // Cgu is not check by user OR
  // The campaign targets no one
  get disabledSendButton(): boolean {
    return !this.checkBoxCguValue || this.totalContactsSelected === 0;
  }

  get defaultTimeZone(): number {
    const defaultTZ: string =
      this.$store.state.communication.entityOptions.defaultTimeZone;

    const allTZ: string[] =
      this.$store.state.communication.entityOptions.timeZones;

    if (defaultTZ && allTZ) {
      const defaultTZValue = allTZ.findIndex((tz) => tz === defaultTZ);

      return defaultTZValue;
    }
    return 0;
  }

  // Create TZ options for selected tag
  get timeZonesOptions(): { value: number; text: string }[] {
    const possibleTimeZones: string[] =
      this.$store.state.communication.entityOptions.timeZones;

    const options: { value: number; text: string }[] = [];

    possibleTimeZones.forEach((tz, index) =>
      options.push({ value: index, text: tz })
    );

    return options;
  }

  get totalContactsSelected(): number {
    return this.$store.getters['communication/targetedContactFilteredLength'];
  }

  get totalSendMessage(): number {
    return this.$store.state.communication.estimationsData.totalSendMessage;
  }

  get totalIncluded(): number {
    return this.$store.state.communication.estimationsData.totalIncluded;
  }

  get totalExcluded(): number {
    return this.$store.state.communication.estimationsData.totalExcluded;
  }

  get included(): PackEstimation[] {
    return this.$store.state.communication.estimationsData.included;
  }

  get excluded(): PackEstimation[] {
    return this.$store.state.communication.estimationsData.excluded;
  }

  get hasIncluded(): boolean {
    return this.$store.state.communication.estimationsData.included.length > 0;
  }

  get hasExcluded(): boolean {
    return this.$store.state.communication.estimationsData.excluded.length > 0;
  }

  get totalCost(): boolean {
    return this.$store.state.communication.estimationsData.totalCost;
  }

  get hasPack(): boolean {
    return this.$store.state.communication.estimationsData.hasPack;
  }

  get campaignType(): ECampaignType {
    return this.$store.state.communication.currentCampaign.media.mediaType;
  }

  public send() {
    this.submitting = true;
    this.$store
      .dispatch('communication/sendCampaign')
      .then(() => {
        this.$router.push({ name: 'communication-campaign-list' });
      })
      .finally(() => {
        this.submitting = false;
      });
  }
}
</script>

<style lang="scss">
.estimateCol {
  display: flex;
  justify-content: center;
}

.estimateCard {
  width: min-content;
  display: flex;
  align-items: center;
  h3 {
    color: var(--blue) !important;
  }
  hr {
    background: var(--blue) !important;
  }
}

.custom-select {
  width: auto;
}

.estimateRow1 {
  flex-wrap: unset;
  align-items: center;
  gap: 5px;
}

.timeZoneH5 {
  width: max-content;
}

.rowTitle {
  justify-content: space-between;
}
.rowCgu {
  margin-top: 10px;
  display: inline-block;
}
.inputCgu {
  margin-right: 1rem;
}
.linkCgu {
  color: var(--blue);
  text-decoration: underline;
}
.p-orderlist-controls {
  display: none !important;
}
.p-orderlist-list {
  min-height: fit-content;
  max-height: 12rem;
}
.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
  background: var(--blue) !important;
  color: white !important;
}
.p-orderlist .p-orderlist-list .p-orderlist-item {
  color: #6e6e6e !important;
}
.p-orderlist {
  font-family: 'Poppins', sans-serif !important;
  display: contents;
}
.p-orderlist-list-container {
  padding-bottom: 1rem;
}
.p-orderlist-items {
  font-size: 1rem !important;
  display: flex;
  justify-content: space-between;
}
.align-with-orderlist {
  margin-right: 1.55rem;
  color: var(--indigo);
}
.hr--blue {
  background: var(--blue);
}
.h5__iconed {
  display: flex;
  gap: 5px;
}
.span--blue {
  color: var(--indigo);
}
</style>
