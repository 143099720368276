/* eslint-disable @typescript-eslint/no-explicit-any */
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import getEnv from '@/core/utils/getEnv';
import store from '@/core/store';
import { Preference } from '@/core/store/models';
import { getInstance } from '@/core/auth/wrapper';
import { AuthState } from './state';

const userEntitiesKey = `${getEnv('AUTH0_NAMESPACE')}/entityIds`;

enum AuthError {
  NoError = 0,
  UserNoEntity = 1
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: 'auth'
})
export default class Auth extends VuexModule implements AuthState {
  user: Record<string, any> | null = null;

  authenticated = false;

  error = AuthError.NoError;

  accessToken = '';

  get currentUser(): Record<string, any> | null {
    return this.user;
  }

  get userEntities(): Array<any> {
    return this.currentUser ? this.currentUser[userEntitiesKey] : [];
  }

  get isAuthenticated(): boolean {
    return this.authenticated;
  }

  get isError(): boolean {
    return this.error !== AuthError.NoError;
  }

  get token(): string {
    return this.accessToken;
  }

  @Mutation
  public SET_USER(user: Record<string, any>[]): void {
    this.user = user;
  }

  @Mutation
  public SET_AUTHENTICATED(): void {
    this.authenticated = true;
  }

  @Mutation
  public SET_ERROR(error: AuthError): void {
    this.error = error;
  }

  @Mutation
  public SET_ACCESS_TOKEN(accessToken: string): void {
    this.accessToken = accessToken;
  }

  @Action
  authenticate(credentials: {
    user: Record<string, any>;
    accessToken: string;
  }): void {
    if (Object.keys(credentials.user).length > 1) {
      const previousUserEntities = this.userEntities;
      this.context.commit('SET_USER', credentials.user);
      this.context.commit('SET_AUTHENTICATED');
      this.context.commit('SET_ACCESS_TOKEN', credentials.accessToken);
      const newUserEntities = credentials.user[userEntitiesKey];

      // Keep user entities list control to fetch the organisations, only
      // if entity ids changed in token
      if (newUserEntities.length === 0) {
        this.context.commit('SET_ERROR', AuthError.UserNoEntity);
      } else if (
        newUserEntities.filter(
          (id: string) => !previousUserEntities.includes(id)
        ).length > 0
      ) {
        setTimeout(async () => {
          const preferences = new Preference(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.currentUser[`${getEnv('AUTH0_NAMESPACE')}/preferences`]
          );
          store.commit('preferences/SET_PREFERENCES', preferences);
          await store.dispatch('organisations/fetch');
          // Rule for now: select the first organisation (PLA-1651)
          const organisations = store.getters['organisations/all'];
          if (organisations.length > 0) {
            if (!preferences.lastSelectedOrganisation) {
              await store.dispatch(
                'preferences/save',
                new Preference({
                  lastSelectedOrganisation: organisations[0].id
                })
              );
            }
            store.dispatch(
              'organisations/SELECT_CURRENT_ORGANISATION',
              preferences.lastSelectedOrganisation || organisations[0]
            );
          } else {
            this.context.commit('SET_ERROR', AuthError.UserNoEntity);
          }
        });
      }
    }
  }

  @Action
  refreshUser(user: Record<string, any>): void {
    // Retrieve the stored user
    const authService = getInstance();
    const updated = authService.getUser();

    // Update its information for displaying purposes, the token will be invalidated when the page is refreshed
    updated.email = user.email;
    updated.name = user.name;
    this.context.commit('SET_USER', updated);
  }
}
