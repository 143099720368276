<template>
  <p-card
    v-if="!isLeaf"
    :title="$t('analyse.communication.stats.common.details')"
    icon-src="@/core/assets/img/icons/ico-analyse.svg"
  >
    <b-row class="m-0 justify-content-between">
      <DetailedStatsTable :indicators="indicators" module="communication" />
    </b-row>
  </p-card>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import PCard from '@/core/components/cards/PCard.vue';
import DetailedStatsTable from './DetailedStatsTable.vue';

@Component({ components: { PCard, DetailedStatsTable } })
export default class CommunicationDetails extends Vue {
  @Prop(String) private title: string | undefined;

  @Prop(String) private iconSrc: string | undefined;

  get isLeaf(): boolean {
    return this.$store.getters['entities/current'].children === undefined;
  }

  private indicators = [
    {
      id: 'smsCampaign',
      path: 'communication.campaignStats.sms.total',
      label: this.$t(
        'analyse.communication.stats.indicators.sms.campaign.title'
      ),
      tooltip: this.$t(
        'analyse.communication.stats.indicators.sms.campaign.tooltip'
      )
    },
    {
      id: 'smsVolume',
      path: 'communication.transmittedMessage.sms.count',
      label: this.$t('analyse.communication.stats.indicators.sms.volume.title'),
      tooltip: this.$t(
        'analyse.communication.stats.indicators.sms.volume.tooltip'
      )
    },
    {
      id: 'emailCampaign',
      path: 'communication.campaignStats.email.total',
      label: this.$t(
        'analyse.communication.stats.indicators.email.campaign.title'
      ),
      tooltip: this.$t(
        'analyse.communication.stats.indicators.email.campaign.tooltip'
      )
    },
    {
      id: 'emailVolume',
      path: 'communication.transmittedMessage.email.count',
      label: this.$t(
        'analyse.communication.stats.indicators.email.volume.title'
      ),
      tooltip: this.$t(
        'analyse.communication.stats.indicators.email.volume.tooltip'
      )
    }
  ];
}
</script>
<style lang="scss" scoped>
.campaign-list {
  span {
    display: block;
  }
  .list-group-item {
    border: 0;
    background: #f9f9f9;
  }
  .campaign-name {
    color: $blue;
    font-weight: bold;
  }
  .campaign-date {
    color: $dark;
    font-size: 0.9rem;
    font-weight: lighter;
  }
}
</style>
