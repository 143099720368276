<template>
  <p-card :title="title" :icon-src="iconSrc">
    <b-spinner v-if="loading"></b-spinner>
    <b-container v-else-if="!isError && !loading" class="p-0">
      <b-row>
        <b-col><slot /></b-col>
      </b-row>
      <h5 class="text-center">{{ subTitle }}</h5>
      <p class="text-center">
        <span
          ><u>{{ statTitle }} </u>
        </span>
        <span v-b-tooltip :title="tooltipString" class="key-stat" :id="statId">
          {{ ' ' }} {{ stat }}
        </span>
      </p>
      <b-row class="mt-3">
        <b-col>
          <b-button
            class="w-100"
            variant="outline-primary"
            :to="{ path: buttonLink }"
          >
            {{ buttonTitle }}
          </b-button>
        </b-col>
      </b-row>
    </b-container>
    <b-container v-else-if="isError && !loading">
      <h5
        class="text-center key-stat-error"
        v-b-tooltip
        :title="$t('analyse.dashboard.keyFacts.error-tooltip')"
      >
        {{ $t('analyse.dashboard.keyFacts.error') }}
      </h5>
    </b-container>
  </p-card>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import PCard from '@/core/components/cards/PCard.vue';

@Component({ components: { PCard } })
export default class StatItem extends Vue {
  @Prop(String) private title: string | undefined;

  @Prop(String) private iconSrc: string | undefined;

  @Prop(String) private buttonTitle: string | undefined;

  @Prop(String) private subTitle: string | undefined;

  @Prop(String) private statTitle: string | undefined;

  @Prop() private stat: number | undefined | string;

  @Prop(String) private buttonLink: string | undefined;

  @Prop(String) private tooltipString: string | undefined;

  @Prop(String) private statId: string | undefined;

  @Prop(Boolean) private loading: boolean | undefined;

  @Prop(Boolean) private activityStatError: boolean | undefined;

  get isError(): boolean {
    return this.stat === -1 || !!this.activityStatError;
  }
}
</script>

<style lang="scss" scoped>
.key-stat {
  color: $orange;
  font-size: $h1-font-size;
}
</style>
