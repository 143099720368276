<template>
  <div class="circular-indicator">
    <div class="svg-container">
      <svg viewBox="0 0 40 40" class="svg-circular-indicator">
        <path
          class="circle-bg"
          d="M20 4
          a 16 16 0 0 1 0 32
          a 16 16 0 0 1 0 -32"
        />
        <path
          class="circle"
          ref="circle"
          :style="{
            strokeDasharray: `${incrementPercentage}, 100`
          }"
          d="M20 4
          a 16 16 0 0 1 0 32
          a 16 16 0 0 1 0 -32"
        />
        <path
          class="marker"
          ref="marker"
          d="M20 2
          a 2 2 0 0 1 0 4
          a 2 2 0 0 1 0 -4"
          :style="{
            transform: `rotate(${rotation}deg)`
          }"
        ></path>
      </svg>
    </div>
    <div class="circular-indicator-legend">
      <h2
        v-b-tooltip
        :title="tooltipPercentage"
        class="circular-indicator-title"
        v-if="percentage > 0"
      >
        {{ Math.floor(incrementPercentage) }}%
      </h2>
      <h2
        v-b-tooltip
        :title="tooltipPercentage"
        class="circular-indicator-title"
        v-else
      >
        -
      </h2>
      <p
        v-b-tooltip
        :title="tooltipValue"
        class="circular-indicator-value"
        v-if="value != undefined && value != -1"
      >
        {{ value }}
      </p>
      <div
        class="circular-indicator-fluctuation"
        v-if="fluctuation != undefined"
      >
        <i
          v-if="fluctuation > 0"
          class="fas fa-arrow-up"
          aria-hidden="true"
        ></i>
        <i
          v-if="fluctuation < 0"
          class="fas fa-arrow-down"
          aria-hidden="true"
        ></i>
        <p>{{ fluctuation }}%</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import gsap from 'gsap';

@Component({
  components: {}
})
export default class CircularIndicator extends Vue {
  @Prop(Number) readonly percentage!: number;

  @Prop(Number) readonly value?: number;

  @Prop(Number) readonly fluctuation?: number;

  @Prop(String) readonly tooltipPercentage?: string;

  @Prop(String) readonly tooltipValue?: string;

  @Prop({ type: Number, default: 2 }) readonly duration!: number;

  private incrementPercentage = 0;

  mounted(): void {
    this.incrementPercentageAnimation();
  }

  @Watch('percentage')
  onPercentageChanged(): void {
    this.incrementPercentage = 0;
    this.incrementPercentageAnimation();
  }

  incrementPercentageAnimation(): void {
    gsap.to(this, {
      duration: this.duration,
      incrementPercentage: this.percentage
    });
  }

  get rotation(): number {
    return (this.incrementPercentage * 360) / 100;
  }
}
</script>

<style lang="scss" scoped>
$circular-indicator-thickness: 1.5;
$circular-indicator-marker-thickness: 1;

.circular-indicator {
  min-width: 150px;
  min-height: 150px;
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;

  .svg-container {
    width: 100%;
    position: absolute;

    .svg-circular-indicator {
      display: block;
      margin: auto;
    }

    .circle-bg {
      fill: none;
      stroke: $circular-indicator-bg-color;
      stroke-width: $circular-indicator-thickness;
    }

    .circle {
      fill: none;
      stroke: $circular-indicator-circle-color;
      stroke-width: $circular-indicator-thickness;
    }

    .marker {
      fill: white;
      stroke: $circular-indicator-marker-color;
      stroke-width: $circular-indicator-marker-thickness;
      transform-origin: 50% center;
    }

    @keyframes progress {
      0% {
        stroke-dasharray: 0 100;
      }
    }

    @keyframes rotateProgress {
      0% {
        transform: rotate(0deg);
      }
    }
  }

  .circular-indicator-legend {
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-40%);

    .circular-indicator-title {
      color: $circular-indicator-title-color;
      font-weight: $title-font-weight;
      font-size: $font-size-base * 2;
      margin-bottom: 0;
    }

    .circular-indicator-value {
      font-size: $font-size-lg;
      font-family: $component-font-family;
      color: $circular-indicator-value-color;
      margin-bottom: 0;
    }

    .circular-indicator-fluctuation {
      display: inline-flex;
      font-size: $font-size-sm;
      font-family: $component-font-family;
      color: $circular-indicator-fluctuation-color;

      p {
        padding-left: 5%;
      }
    }
  }
}
</style>
