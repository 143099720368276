<template>
  <div>
    <h5 class="panel-title">
      {{ $t('smsEditor.tools.webImageEditor.title') }}
    </h5>
    <div class="bloc-import-img">
      <input-file
        @fileChanged="onFileChange"
        title="Upload Image"
        :colors="{
          primary: primaryColor,
          secondary: secondaryColor,
          third: thirdColor
        }"
        v-bind:fileDetails="$t('smsEditor.tools.webImageEditor.details')"
        v-bind:inputLabel="$t('smsEditor.tools.webImageEditor.label')"
        v-bind:inputPlaceholder="
          $t('smsEditor.tools.webImageEditor.placeholder')
        "
      ></input-file>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { InputFile } from '@dolmen/vue-toolkit';

/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-empty-function */
@Component({ components: { InputFile } })
export default class WebImageEditor extends Vue {
  primaryColor = '#498EFB';

  secondaryColor = '#0057E2';

  thirdColor = '#0043AE';

  onFileChange(files: FileList) {
    if (!files.length) return;
    this.$emit('change', files[0]);
  }
}
</script>
<style>
.bloc-import-img {
  margin-top: 30px;
}
</style>
