import { Organisation } from '@/core/store/models';
import { AxiosResponse } from 'axios';
import ApiClient from './ApiClient';
import paths from './paths';
import { Operation, displayError } from '../modules/apiError';

export default class OrganisationsClient extends ApiClient {
  constructor() {
    super(paths.ORGANISATION_WS);
  }

  async fetch(): Promise<AxiosResponse<Array<Organisation>>> {
    try {
      return await this.get();
    } catch (error) {
      displayError(error, Operation.FETCH, 'Fetch Organisations');
      console.error(error);
      return Promise.reject(new Error(error as string));
    }
  }
}
