import { SMS_SENDER } from '@/core/utils/smsUtils';
import ECampaignType from '@/core/enum/ECampaignType';
import ESmsType from '@/core/enum/ESmsType';
import { TargetContactsFilters } from './targetedContacts';
import { MediaType } from './MediaType';
import { EMPTY_SMS_PAGE } from './SmsMediaType';

export default class ActivationCampaignType {
  id = '';

  externalId = '';

  name = '';

  subEntities: number[] = [];

  filter: TargetContactsFilters = {
    gender: -1,
    ageRange: [0, 100],
    localisation: true,
    birthMonth: -1
  };

  media: MediaType = {
    mediaType: ECampaignType.SMS,
    smsMedia: {
      type: ESmsType.SIMPLE,
      sms: {
        sender: SMS_SENDER,
        message: '',
        linkTitle: ''
      },
      page: structuredClone(EMPTY_SMS_PAGE),
      count: 1
    }
  };
}
