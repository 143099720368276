<template>
  <div>
    <b-container class="py-2 mb-4">
      <b-row>
        <b-col v-if="!entityTypeLoading">
          <MenuCommunication
            v-if="isEntityGroup"
            data-cy="communication-content"
          />
          <redirect-to-pilot v-else data-cy="pilot-redirection" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Entity, EntityType } from '@/core/store/models';
import { displayError, Operation } from '@/core/store/modules/apiError';
import MenuCommunication from './MenuCommunication.vue';
import RedirectToPilot from './components/RedirectToPilot.vue';

@Component({ components: { MenuCommunication, RedirectToPilot } })
export default class Campaign extends Vue {
  @Prop(String) loadEntityId?: string;

  get entityTypeLoading() {
    return this.$store.getters['entityTypes/isLoading'];
  }

  get isEntityGroup(): boolean {
    const currentEntity: Entity = this.$store.getters['entities/current'];
    const currentEntityType: EntityType = this.$store.getters[
      'entityTypes/getEntityType'
    ](currentEntity.type);

    return !!currentEntityType.isEntityGroup;
  }

  created() {
    if (this.loadEntityId) {
      // Remove entityId from url
      this.$router.replace('/communication');
      this.setCurrentEntityOrDisplayError();
    }
  }

  setCurrentEntityOrDisplayError() {
    if (this.loadEntityId) {
      const entityExist: Entity = this.$store.getters['entities/getEntity'](
        this.loadEntityId
      );
      if (entityExist) {
        this.$store.commit('entities/SELECT_ENTITY', this.loadEntityId);
      } else {
        displayError('', Operation.ENTITY_NOT_EXIST, 'Campaign');
      }
    }
  }
}
</script>
