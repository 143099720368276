<template>
  <b-popover :show.sync="show" :target="target" :title="title" triggers="click">
    <slot :canceled="canceled" :saved="saved" />
  </b-popover>
</template>

<script lang="ts">
/* eslint-disable class-methods-use-this */
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class PopoverForm extends Vue {
  @Prop() readonly target!: string;

  @Prop() readonly title!: string;

  show = false;

  @Emit('canceled')
  canceled(): void {
    this.show = false;
  }

  @Emit('saved')
  saved(): void {
    this.show = false;
  }
}
</script>
<style lang="scss" scoped></style>
