/* eslint-disable class-methods-use-this */
import SmsCharType from './SmsCharType';

export default class SmsCharUnicode implements SmsCharType {
  static CHARSET = 'unicode';

  static SMS_MAX_LENGTH = 70;

  static SMS_CONCATENATION = 3;

  getCharset(): string {
    return SmsCharUnicode.CHARSET;
  }

  countCharacters(text: string): number {
    return text.length;
  }

  countSms(contentLength: number): number {
    if (contentLength <= SmsCharUnicode.SMS_MAX_LENGTH) {
      return 1;
    }
    return Math.ceil(
      contentLength /
        (SmsCharUnicode.SMS_MAX_LENGTH - SmsCharUnicode.SMS_CONCATENATION)
    );
  }

  countNextSmsLimit(smsCount: number): number {
    if (smsCount <= 1) {
      return SmsCharUnicode.SMS_MAX_LENGTH;
    }
    return (
      Math.max(smsCount, 1) *
      (SmsCharUnicode.SMS_MAX_LENGTH - SmsCharUnicode.SMS_CONCATENATION)
    );
  }

  getSmsMaxUnitLength(): number {
    return SmsCharUnicode.SMS_MAX_LENGTH - SmsCharUnicode.SMS_CONCATENATION;
  }
}
