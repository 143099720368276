<template>
  <StatItem
    :tooltip-string="dateThreashold"
    :title="$t('analyse.dashboard.keyCommunication.title')"
    icon-src="@/core/assets/img/icons/ico-com.svg"
    :sub-title="$t('analyse.dashboard.keyCommunication.subTitle')"
    :stat="keyStat"
    :statId="'keycomstat_number'"
    :button-title="$t('analyse.dashboard.keyCommunication.buttonTitle')"
    :loading="statLoading"
    button-link="/analyse/communication"
  >
  </StatItem>
</template>

<script lang="ts">
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import format, { isDateValid, dateDisplayFormat } from '@/core/utils/i18nDate';
import { READ, STATISTICS } from '@/conf/permissions';
import { getInstance } from '@/core/auth/wrapper';
import StatsQueryMixin from '../mixins/statsQueryMixin';
import StatItem from './StatItem.vue';
import { getCommunicationKeyStats } from '../store/network/graphql/queries';
import { EntityStats } from '../store/models/statistics';

@Component({
  components: { StatItem },
  apollo: {
    getCommunicationStat: {
      query: getCommunicationKeyStats,
      variables() {
        return {
          input: this.entityInput,
          minimum: 0,
          startDate: this.dates.from,
          endDate: this.dates.to
        };
      },
      skip() {
        return (
          !this.dates ||
          !this.dates.from ||
          !this.dates.to ||
          !this.$can(READ, STATISTICS)
        );
      },
      error(error) {
        this.error = error;
      }
    }
  }
})
export default class StatItemKeyCommunication extends mixins(StatsQueryMixin) {
  private getCommunicationStat: EntityStats = new EntityStats();

  private p = { READ, STATISTICS };

  mounted(): void {
    this.queryName = 'getCommunicationStat';
  }

  get keyStat(): number | string {
    const authService = getInstance();
    if (authService.$ability.can(READ, STATISTICS)) {
      return (
        this.getCommunicationStat.communication?.campaignStats?.total?.total ||
        0
      );
    }
    return '-';
  }

  get dateThreashold(): string {
    const fromDate = this.dates.from;
    const toDate = this.dates.to;
    let finalString = this.$t('analyse.dashboard.date.from');
    if (fromDate && isDateValid(fromDate)) {
      finalString += ` ${format(fromDate, dateDisplayFormat)}`;
    }
    if (toDate && isDateValid(toDate)) {
      finalString += ` ${this.$t('analyse.dashboard.date.to')} ${format(
        toDate,
        dateDisplayFormat
      )}`;
    }

    return finalString as string;
  }
}
</script>

<style lang="scss" scoped></style>
