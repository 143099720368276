import EPageComponentType from '@/core/enum/EPageComponentType';
import ESmsType from '@/core/enum/ESmsType';
import {
  SmsEditorMedia,
  SmsEditorPage,
  SmsEditorPageComponent
} from '@/core/store/models/smsEditor';
import { PageComponent, PageType } from '@/core/utils/pageUtils';
import { SMS_SENDER } from '@/core/utils/smsUtils';
import { EMPTY_SMS_PAGE, SmsMediaType } from '../store/model/SmsMediaType';

/* eslint-disable class-methods-use-this */
export default class SmsMapperService {
  toApiSms(sms: SmsMediaType): SmsEditorMedia {
    let apiObject: SmsEditorMedia = {
      version: '2.1',
      nb_sms: sms.count,
      type: sms.type,
      lang: 'fr',
      preview: '',
      sms: {
        sender: {
          content: SMS_SENDER,
          locked: false
        },
        message: {
          content: sms.sms.message,
          locked: false
        },
        link_title: {
          content: sms.type === ESmsType.PREMIUM ? sms.sms.linkTitle : '',
          locked: false
        }
      }
    };

    if (sms.type === ESmsType.PREMIUM) {
      apiObject = { ...apiObject, page: this.toApiPage(sms.page) };
    }

    return apiObject;
  }

  toStoreSms(sms: SmsEditorMedia): SmsMediaType {
    const storeObject: SmsMediaType = {
      type: sms.type,
      count: sms.nb_sms,
      sms: {
        sender: sms.sms.sender.content,
        message: sms.sms.message.content,
        linkTitle: sms.sms.link_title.content
      },
      page: sms.page?.components
        ? this.toStorePage(sms.page)
        : structuredClone(EMPTY_SMS_PAGE)
    };

    return storeObject;
  }

  toApiPage(page: PageType): SmsEditorPage {
    return {
      style: {
        colors: {
          background: page.style.colors.background,
          foreground: page.style.colors.foreground,
          font: page.style.colors.font,
          locked: false
        }
      },
      components: page.components.map(this.toApiPageComponent),
      structureLocked: false
    };
  }

  toStorePage(page: SmsEditorPage): PageType {
    return {
      style: {
        colors: {
          background: page.style.colors.background,
          foreground: page.style.colors.foreground,
          font: page.style.colors.font
        }
      },
      components: page.components.map(this.toStorePageComponent)
    };
  }

  toApiPageComponent(component: PageComponent): SmsEditorPageComponent {
    return {
      ...component,
      locked: false
    };
  }

  toStorePageComponent(component: SmsEditorPageComponent): PageComponent {
    switch (component.type) {
      case EPageComponentType.IMAGE:
        return {
          type: EPageComponentType.IMAGE,
          url: `${component.url}`,
          fid: `${component.fid}`
        };
      case EPageComponentType.COUPON:
        if (component.validity?.after && component.validity.duration) {
          return {
            type: EPageComponentType.COUPON,
            codeIsInPath: component.codeIsInPath
              ? component.codeIsInPath
              : true,
            format: `${component.format}`,
            code: `${component.code}`,
            duration: component.duration ? component.duration : 0,
            validity: {
              after: component.validity.after,
              duration: component.validity.duration
            }
          };
        }

        return {
          type: EPageComponentType.COUPON,
          codeIsInPath: component.codeIsInPath ? component.codeIsInPath : true,
          format: `${component.format}`,
          code: `${component.code}`,
          duration: component.duration ? component.duration : 0,
          validity: {
            start: `${component?.validity?.start}`,
            end: `${component?.validity?.end}`
          }
        };

      case EPageComponentType.TEXT:
      default:
        return {
          type: EPageComponentType.TEXT,
          content: `${component.content}`
        };
    }
  }
}
