<template>
  <p-card type="group" :title="title" :icon-src="iconSrc">
    <b-container>
      <b-row>
        <b-col v-for="(item, index) in items" :key="index"
          ><component :is="item"
        /></b-col>
      </b-row>
    </b-container>
  </p-card>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import PCard from '@/core/components/cards/PCard.vue';

@Component({
  components: {
    PCard
  }
})
export default class StatGroup extends Vue {
  @Prop(String) title: string | undefined;

  @Prop(String) iconSrc: string | undefined;

  @Prop(Array) items: Record<string, unknown>[] | undefined;
}
</script>

<style lang="scss" scoped></style>
