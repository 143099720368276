import Communication from '../views/Communication.vue';
import Dashboard from '../views/Dashboard.vue';

export default [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/analyse/activity',
    name: 'Activity',
    // component: Activity,
    meta: {
      permission: {
        view: 'statistics'
      }
    }
  },
  {
    path: '/analyse/collect',
    name: 'Collect',
    // component: Collect,
    meta: {
      permission: {
        view: 'statistics'
      }
    }
  },
  {
    path: '/analyse/communication',
    name: 'Communication',
    component: Communication,
    meta: {
      permission: {
        view: 'statistics'
      }
    }
  },
  {
    path: '/analyse/contacts',
    name: 'Contacts',
    // component: Contacts,
    meta: {
      permission: {
        view: 'statistics'
      }
    }
  }
];
