<template>
  <div class="mb-5">
    <h5 class="panel-title">{{ $t('smsEditor.tools.webTextEditor.title') }}</h5>
    <h6 class="mb-4 panel-subTitle">
      {{ $t('smsEditor.tools.webTextEditor.subTitle') }}
    </h6>
    <div>
      <label>{{ $t('smsEditor.tools.webTextEditor.writeYourMessage') }}</label>
      <vue-editor
        :editorOptions="editorSettings"
        @blur="onEditorBlur"
        @focus="onEditorFocus"
        ref="htmlEditor"
        v-model="content"
      ></vue-editor>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Ref, Model, Prop } from 'vue-property-decorator';
import { VueEditor, VueEditorData } from 'vue2-editor';
import { dropdownItem } from '@/core/utils/editorUtils';
import { TextPageComponent } from '@/core/utils/pageUtils';

interface Toolbar {
  container: any;
  handlers: any;
}

interface VueEditorOptions {
  modules: { toolbar: Toolbar };
  placeholder: string;
  theme: string;
}

@Component({ components: { VueEditor } })
export default class WebTextEditor extends Vue {
  @Ref('htmlEditor') readonly editor!: VueEditorData;

  @Model('input', { type: Object, required: true })
  component!: TextPageComponent;

  @Prop({ type: Array, required: true })
  readonly items!: Array<dropdownItem>;

  editorSettings: VueEditorOptions = this.initEditorSettings();

  dropDownItemsByLabel: Map<string, string> = new Map();

  get content() {
    return this.component.content;
  }

  set content(value: string) {
    this.component.content = value;
    this.$emit('input', this.component);
  }

  mounted() {
    this.items.forEach((item) => {
      this.dropDownItemsByLabel.set(item.label, `[${item.value}] `);
    });

    this.$nextTick(() => {
      this.editor.quill.focus();
    });

    this.editor.quill.webTextEditor = this;
  }

  onEditorFocus() {
    this.editor.quill.root.classList.add('focus');
  }

  onEditorBlur() {
    this.editor.quill.root.classList.remove('focus');
  }

  initEditorSettings(): VueEditorOptions {
    return {
      modules: {
        toolbar: {
          container: [
            ['bold', 'italic', 'underline'],
            [{ color: [] }, { background: [] }],
            [{ placeholder: this.items.map((obj) => obj.label) }]
          ],
          handlers: {
            placeholder(value: string) {
              if (value) {
                const cursorPosition = this.quill.getSelection().index;
                const token = `${this.quill.webTextEditor.dropDownItemsByLabel.get(
                  value
                )}`;
                this.quill.insertText(cursorPosition, token);
                this.quill.setSelection(cursorPosition + token.length);
              }
            }
          }
        }
      },
      placeholder: `${this.$t('smsEditor.tools.webTextEditor.placeholder')}`,
      theme: 'snow'
    };
  }
}
</script>
<style>
.panel-title {
  text-align: left;
  font-size: 22px;
  color: #30445f;
  line-height: 24px;
  font-weight: 700;
  margin: 0;
}

.text-center h5 {
  text-align: center;
}

.panel-subTitle {
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  color: #30445f;
}

.ql-placeholder .ql-picker-item:before {
  content: attr(data-value);
}

.ql-picker.ql-placeholder {
  width: 110%;
}

.ql-picker.ql-placeholder .ql-picker-label::before {
  content: 'Champs Personnalisés';
}

.ql-editor.ql-blank,
.ql-editor.ql-blank:before {
  color: #9aa5b3 !important;
  font-size: 13px !important;
  font-weight: 300 !important;
  font-style: normal !important;
}

.ql-editor,
.ql-editor.ql-blank {
  border: solid 1px #dde1e8 !important;
  border-radius: 3px;
}

.ql-editor.focus {
  border: 1px solid #498efb !important;
  box-shadow: 0 0 5px 0 rgba(73, 142, 251, 0.2);
}

.ql-toolbar.ql-snow {
  border: none !important;
  font-family: 'Museo Sans', sans-serif !important;
  font-weight: 500 !important;
  color: #000000 !important;
  padding-top: 0 !important;
  padding-left: 0 !important;
}

.ql-toolbar.ql-snow .ql-formats {
  border: 1px solid #dde1e8;
  border-radius: 3px;
  padding: 0 15px 0px 8px;
  height: 36px;
  margin-right: 10px !important;
}

.ql-toolbar.ql-snow button,
.ql-snow .ql-toolbar button {
  padding: 5px !important;
}

.ql-container.ql-snow {
  border: none !important;
  font-family: 'Museo Sans', sans-serif !important;
}

.ql-editor p {
  font-family: 'Museo Sans', sans-serif !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #30445f !important;
}

.ql-editor p strong {
  font-weight: 700 !important;
}

.ql-snow .ql-picker-label {
  padding: 0;
}
</style>
