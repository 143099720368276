import i18n from '@/plugins/i18n';
import { getInstance } from '@/core/auth/wrapper';
import { VIEW, DEPLOYMENT } from '@/conf/permissions';

export default {
  name: 'organisation',
  createMenu: (): any => {
    return getInstance().$ability.can(VIEW, DEPLOYMENT)
      ? {
          href: '/organisation/',
          title: `${i18n.t('organisation.menu.title')}`,
          icon: 'fas fa-layer-group'
        }
      : null;
  }
};
