<template>
  <b-navbar :class="`mb-3 ${isScrolled ? 'scrolled' : ''}`" type="light" sticky>
    <b-nav-text><div :style="`width: ${menuWidth}px;`"></div></b-nav-text>
    <b-nav-text class="w-25 ml-4" href="#">
      <entity-selector
        v-model="selectedEntity"
        :disabled="editCampaignMode"
        data-cy="sub-header-entity-selector"
      />
    </b-nav-text>
    <b-collapse id="nav-collapse" is-nav>
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto mr-3 text-primary">
        <b-nav-text v-for="component in components" :key="component.id">
          <component
            :cid="component.id"
            :id="component.id"
            :is="component.name"
          />
        </b-nav-text>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import EntitySelector from '@/core/components/widgets/EntitySelector.vue';
import { Entity } from '@/core/store/models';
import { menuWidth } from '@/conf/menu';
import { ComponentState } from '@/core/store/modules/application/subheader/state';
import SHDatePicker from './SHDatePicker.vue';

Vue.component('SHDatePicker', SHDatePicker);

@Component({
  components: { EntitySelector }
})
export default class SubHeader extends Vue {
  private menuWidth = menuWidth;

  private isScrolled = false;

  get components(): ComponentState[] {
    return this.$store.getters['app/subheader/components'];
  }

  get selectedEntity(): Entity {
    return this.$store.getters['entities/current'];
  }

  set selectedEntity(entity: Entity) {
    this.$store.commit('entities/SELECT_ENTITY', entity.id);
  }

  get editCampaignMode(): boolean {
    return this.$store.getters['communication/editMode'];
  }

  get createCampaignMode(): boolean {
    return this.$store.getters['communication/createMode'];
  }

  created(): void {
    window.addEventListener('scroll', this.handleScroll);
  }

  destroyed(): void {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(): void {
    this.isScrolled = window.scrollY > 0;
  }
}
</script>
<style lang="scss" scoped>
.navbar {
  background-color: rgb(249, 250, 251, 0.96);
  z-index: 5;
  &.scrolled {
    -webkit-box-shadow: 0 0.5rem 0.5rem -0.5rem rgba(0, 0, 0, 0.2) !important;
    box-shadow: 0 0.5rem 0.5rem -0.5rem rgba(0, 0, 0, 0.2) !important;
  }
}
</style>
