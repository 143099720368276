var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-navbar',{attrs:{"type":"light","sticky":""}},[_c('div',{style:(`width: ${_vm.menuWidth}px;`)},[_c('b-navbar-brand',{attrs:{"href":"/"}},[_c('img',{attrs:{"src":require("@/core/assets/img/logo.svg"),"alt":"Dolmen","width":"100%"}})])],1),(_vm.organisations.length > 1)?_c('b-navbar-nav',{staticClass:"w-25 ml-4",attrs:{"href":"#"}},[_c('treeselect',{attrs:{"value-format":"object","multiple":false,"options":_vm.organisations,"normalizer":_vm.normalizer,"clearable":false},model:{value:(_vm.currentOrganisation),callback:function ($$v) {_vm.currentOrganisation=$$v},expression:"currentOrganisation"}})],1):_vm._e(),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"ml-auto mr-3 text-primary"},[_c('b-nav-item-dropdown',{ref:"dropdown",attrs:{"toggle-class":"nav-link dropdown-toggle","right":""},on:{"hide":_vm.onDropdownHide,"toggle":_vm.toggle},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('span',{staticClass:"user-name"},[_vm._v(_vm._s(_vm.user.name))])]},proxy:true}])},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"#","id":"edit-user-profile"}},[_c('i',{staticClass:"fa fa-user",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.$t('global.authentication.profile'))+" ")]),_c('PopoverForm',{attrs:{"target":"edit-user-profile","title":_vm.$t('organisation.users.editor.popupTitle')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('UserEdit',{attrs:{"validators":_vm.validators,"feedback":_vm.feedback,"userId":_vm.user.sub},on:{"canceled":() => {
                scope.canceled();
                _vm.closeDropDown();
              },"saved":() => {
                scope.saved();
                _vm.closeDropDown();
              }}})]}}])}),_c('b-dropdown-item',{attrs:{"variant":"danger"},on:{"click":_vm.logout}},[_c('i',{staticClass:"fa fa-sign-out-alt",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.$t('global.authentication.logout'))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }