<template>
  <b-form @submit.stop.prevent="onSubmit" novalidate>
    <b-row class="mt-4">
      <b-col offset-lg="2" lg="8">
        <b-skeleton-wrapper v-if="loading">
          <b-skeleton height="20px" width="30%" class="mb-2"></b-skeleton>
          <b-skeleton height="43px"></b-skeleton>
        </b-skeleton-wrapper>
        <b-form-group
          id="input-group-name"
          :label="$t('communication.campaign.form.name.label')"
          label-for="input-name"
          v-else
        >
          <b-form-input
            id="input-name"
            v-model="name"
            type="text"
            :placeholder="$t('communication.campaign.form.name.placeholder')"
            :state="validateState('name')"
            aria-describedby="input-name-feedback"
            data-cy="name"
          ></b-form-input>
          <b-form-invalid-feedback
            id="input-name-feedback"
            data-cy="name-feedback"
            >{{
              $t('communication.campaign.form.name.invalid-feedback')
            }}</b-form-invalid-feedback
          >
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col offset-lg="2" lg="8">
        <b-skeleton-wrapper v-if="loading">
          <b-skeleton height="20px" width="30%" class="mb-2"></b-skeleton>
          <b-skeleton height="43px"></b-skeleton>
          <b-skeleton height="20px" width="38%"></b-skeleton>
        </b-skeleton-wrapper>
        <b-form-group
          id="input-group-subentities"
          :label="$t('communication.campaign.form.sub-entities.label')"
          v-else
        >
          <treeselect
            :class="{
              entitySelector: true,
              'is-invalid': validateState('subEntities') === false,
              'is-valid': validateState('subEntities') === true
            }"
            v-model="subEntities"
            :multiple="true"
            :options="entities"
            :placeholder="
              $t('communication.campaign.form.sub-entities.placeholder')
            "
            :normalizer="normalizer"
            data-cy="entities"
          />
          <b-form-invalid-feedback>{{
            $t('communication.campaign.form.sub-entities.invalid-feedback')
          }}</b-form-invalid-feedback>
          <div>
            <small class="form-text text-muted">
              <i
                class="pi pi-info-circle"
                style="font-size: 0.8rem"
                v-b-tooltip
                :title="$t('communication.campaign.form.sub-entities.tooltip')"
              />
              {{
                $t('communication.campaign.form.sub-entities.description', {
                  entityName: currentEntityName
                })
              }}
            </small>
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mt-4" align-h="between">
      <b-col cols="auto" class="mr-auto"></b-col>
      <b-col cols="auto" class="mr-auto">
        <b-button :to="{ name: 'communication-campaign-list' }">{{
          $t('communication.campaign.actions.back')
        }}</b-button>
      </b-col>
      <b-col cols="auto">
        <b-button
          variant="primary"
          type="submit"
          :disabled="isNotSubmittable()"
          data-cy="next"
        >
          <span v-if="!submitting">
            <span v-if="$route.query.cid">
              {{ $t('global.actions.next') }} <i class="fas fa-chevron-right" />
            </span>
            <span v-else
              >{{ $t('global.actions.start') }}
              <i class="fas fa-chevron-right" /></span
          ></span>
          <span v-else> {{ $t('global.actions.loading') }} </span>
          <b-spinner v-if="submitting" small></b-spinner>
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { Validations } from 'vuelidate-property-decorators';
import {
  required,
  minLength,
  maxLength,
  helpers
} from 'vuelidate/lib/validators';
import { Entity } from '@/core/store/models';
import emojiDetector from '@/core/utils/emojiTools';

@Component
export default class StepGeneral extends Vue {
  submitting = false;

  loading = false;

  regexPrintableCharacter = helpers.regex('', /^[ -~À-ÿ]+$/);

  @Validations()
  validations = {
    name: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(50),
      emojiDetector,
      validation: this.regexPrintableCharacter
    },
    subEntities: {
      required
    }
  };

  get currentEntityName(): string {
    return this.$store.getters['entities/current'].name;
  }

  get name(): string {
    return this.$store.state.communication.currentCampaign.name;
  }

  set name(value) {
    this.$store.commit('communication/updateCampaignName', value);
    this.$v.name.$touch();
  }

  get subEntities(): string {
    return this.$store.state.communication.currentCampaign.subEntities;
  }

  set subEntities(value) {
    this.$store.commit('communication/updateCampaignSubEntities', value);
    this.$v.subEntities.$touch();
  }

  get entities(): Entity[] {
    // Between [] because tree select need an array and entities/current is an object
    return [this.$store.getters['entities/current']];
  }

  public async created() {
    if (this.$route.query.cid) {
      try {
        this.loading = true;
        await this.$store.dispatch('communication/fetchCampaign', {
          entityId: this.$store.getters['entities/current'].id,
          campaignId: this.$route.query.cid
        });
      } finally {
        this.loading = false;
      }
    } else {
      this.$store.dispatch('communication/clearCampaign');
    }
  }

  public validateState(name: string) {
    const $dirty = this.$v[name]?.$dirty;
    const $error = this.$v[name]?.$error;
    return $dirty ? !$error : null;
  }

  /**
   * To submit the form :
   * - The form must be modifyed for new entity (you can pass the step for existing entity)
   * - The form must be valid
   */
  public isNotSubmittable(): boolean {
    return (
      this.submitting ||
      !(this.$v.$dirty || this.$route.query.cid) ||
      this.$v.$invalid
    );
  }

  public onSubmit() {
    this.$v.$touch();
    if (this.$v.$anyError) {
      return;
    }

    if (this.$route.query.cid) {
      this.updateCampaign();
    } else {
      this.createCampaign();
    }
  }

  private async createCampaign() {
    try {
      this.submitting = true;
      await this.$store.dispatch('communication/createCampaign', {
        entityId: this.$store.getters['entities/current'].id
      });
      await this.$router.push({
        name: 'communication-campaign-step-contacts',
        query: { cid: this.$store.state.communication.currentCampaign.id }
      });
    } finally {
      this.submitting = false;
    }
  }

  private async updateCampaign() {
    try {
      this.submitting = true;
      await this.$store.dispatch('communication/updateCampaign');
      await this.$router.push({
        name: 'communication-campaign-step-contacts',
        query: { cid: this.$route.query.cid }
      });
    } finally {
      this.submitting = false;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private normalizer(node: Entity) {
    return {
      id: node.id,
      label: node.name,
      children: node.children
    };
  }
}
</script>

<style lang="scss">
.entitySelector {
  .vue-treeselect__single-value {
    &:before {
      @extend %fa-icon;
      @extend .fas;
      content: fa-content($fa-var-search);
      opacity: 0.6;
      margin-right: 10px;
    }
  }
}
.vue-treeselect.is-invalid:not(.vue-treeselect--open) .vue-treeselect__control {
  border-color: #e70003;
}

.vue-treeselect.is-invalid .vue-treeselect__single-value {
  color: #e70003;
}
.vue-treeselect.is-valid:not(.vue-treeselect--open) .vue-treeselect__control {
  border-color: #33a02c;
}

.vue-treeselect.is-valid .vue-treeselect__single-value {
  color: #33a02c;
}
label,
legend {
  color: #666666;
}
.message-type__detail {
  color: #999999;
}
</style>
