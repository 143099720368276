import emojiList from 'emoji-regex';

/**
 * @param text to verify
 * @returns true if there is no emoji, false if it contains at least 1 emoji
 */
const emojiDetector = (text: string) => {
  const emojis = emojiList();

  // Transform matchAll returned object/iterator into an array
  const matches = [...text.matchAll(emojis)];

  // Empty means no match found, so no emoji
  return matches.length === 0;
};

export default emojiDetector;
