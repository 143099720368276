import { getInstance } from '@/core/auth/wrapper';
import { STATISTICS, VIEW } from '@/conf/permissions';
import i18n from '@/plugins/i18n';

export default {
  name: 'home',
  createMenu: (): any => {
    const authService = getInstance();
    return {
      icon: 'fas fa-home',
      href: '/dashboard',
      title: i18n.t('menu.home'),
      hidden: !authService || !authService.$ability.can(VIEW, STATISTICS)
    };
  }
};
