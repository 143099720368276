<template>
  <div class="row" data-cy="smsEditorMessage">
    <div class="col-12 d-inline mt-3 mb-3">
      <div
        class="mb-1 sms-editor-info-message is-unicode-message"
        :class="{ 'd-none': !(unicodeCharacters.length > 0) }"
      >
        <b-button
          block
          v-b-toggle.accordion-unicode
          class="c-accordion-heading d-flex align-items-center"
          variant="info"
        >
          <span class="text-wrap text-left mr-1">
            {{ $t('smsEditor.tools.smsCounter.smsAlertUni') }} ({{
              unicodeCharacters.join(', ')
            }})
          </span>
          <em
            class="fa-solid fas fa-angle-down pr-1 ml-auto"
            aria-hidden="true"
          ></em>
        </b-button>
        <b-collapse
          id="accordion-unicode"
          accordion="my-accordion"
          role="tabpanel"
        >
          <b-card-body class="rounded">
            <b-card-text
              >{{ $t('smsEditor.tools.smsCounter.smsAlertUniExplanations')
              }}<a
                href="https://intercom.help/dolmenfr/fr/articles/8820305-decompte-des-caracteres-sms"
                rel="noopener"
                target="_blank"
                >{{ $t('smsEditor.tools.smsCounter.documentation') }}</a
              >.</b-card-text
            >
          </b-card-body>
        </b-collapse>
      </div>

      <div
        class="mb-1 sms-editor-info-message is-multiple-message"
        :class="{
          'd-none': !(smsCount > 1),
          'mt-2': unicodeCharacters.length > 0
        }"
      >
        <b-button
          block
          v-b-toggle.accordion-multiple-message
          class="c-accordion-heading d-flex align-items-center"
          variant="info"
        >
          <span class="text-wrap text-left mr-1">
            {{
              $t('smsEditor.tools.smsCounter.smsMultiple', {
                count:
                  unicodeCharacters.length > 0 ? maxLengthUni : maxLengthGSM
              })
            }}
            {{
              $t('smsEditor.tools.smsCounter.smsMultipleCount', {
                count: smsCount
              })
            }}
          </span>
          <em
            class="fa-solid fas fa-angle-down pr-1 ml-auto"
            aria-hidden="true"
          ></em>
        </b-button>
        <b-collapse
          id="accordion-multiple-message"
          accordion="my-accordion"
          role="tabpanel"
        >
          <b-card-body class="rounded">
            <b-card-text>{{
              $t('smsEditor.tools.smsCounter.smsMultipleExplanations')
            }}</b-card-text>
          </b-card-body>
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import SmsCharGSM from '@/core/utils/smsUtils/SmsCharGSM';
import SmsCharUnicode from '@/core/utils/smsUtils/SmsCharUnicode';

@Component
export default class SmsCounter extends Vue {
  @Prop({ type: Array, required: true })
  readonly unicodeCharacters!: Array<string>;

  @Prop({ type: Number, required: true }) readonly smsCount!: number;

  @Prop({ type: Number, required: true }) readonly nextSmsLimit!: number;

  maxLengthGSM = SmsCharGSM.SMS_MAX_LENGTH;

  maxLengthUni = SmsCharUnicode.SMS_MAX_LENGTH;
}
</script>
<style>
.sms-editor-info-message button.c-accordion-heading em {
  transition: all 0.5s;
}

.sms-editor-info-message button.c-accordion-heading.not-collapsed em {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.sms-editor-info-message button.c-accordion-heading {
  background-color: #498efb;
  color: #fff;
  padding: 0.7rem;
}

.sms-editor-info-message [role='tabpanel'] .card-body {
  padding: 1.1rem;
  font-size: 0.9rem;
  line-height: 1.5rem;
}

.sms-editor-row
  .c-accordion-heading.btn-info:not(:disabled):not(.disabled):active,
.sms-editor-row
  .c-accordion-heading.btn-info:not(:disabled):not(.disabled).active,
.show > .sms-editor-row .c-accordion-heading.btn-info.dropdown-toggle {
  color: #fff;
  background-color: #498efb;
  border-color: #498efb;
}

.sms-editor-row .c-accordion-heading.btn-info,
.sms-editor-row .c-accordion-heading.btn-info:focus,
.sms-editor-row .c-accordion-heading.btn-info:hover,
.sms-editor-row .c-accordion-heading.btn-info:active,
.sms-editor-row .c-accordion-heading.btn-info:focus-within,
.sms-editor-row .c-accordion-heading.btn-info:target,
.sms-editor-row .c-accordion-heading.btn-info:visited,
.sms-editor-row .c-accordion-heading.btn-info:focus-visible {
  color: #fff;
  background-color: #498efb;
  border-color: #498efb;
}

.text-wrap {
  white-space: normal;
  overflow-wrap: break-word;
}
</style>
