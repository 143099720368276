<template>
  <b-container fluid v-if="selectedEntity !== null">
    <b-row class="py-sm-2">
      <DataTable
        class="p-datatable-sm"
        ref="dt"
        :value="statsData"
        :paginator="true"
        :rows="rows"
        :lazy="false"
        :scrollable="true"
        :rowHover="true"
        :stripedRows="true"
        scrollDirection="horizontal"
        paginatorTemplate="CurrentPageReport RowsPerPageDropdown
          FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
        :currentPageReportTemplate="
          $t('analyse.datatable.paginator.pageReport')
        "
        :rowsPerPageOptions="[10, 25, 50, 100]"
        :loading="statLoading"
        sortMode="single"
        :totalRecords="totalRecords"
        style="width: 100%"
      >
        <Column
          field="name"
          columnKey="name"
          header="Entité"
          :sortable="true"
          headerStyle="background-color: #D9EEF9;"
        ></Column>
        <Column
          v-for="(indicator, index) in selectedIndicatorsColumns"
          :field="indicator.id"
          :columnKey="indicator.id"
          :sortable="true"
          :key="index"
          bodyStyle="text-align:right"
          headerStyle="background-color: #D9EEF9;text-align:right;"
        >
          <template #header>
            <span class="p-column-title" :id="`dt-header-${indicator.id}`">
              {{ indicator.label }}
            </span>
            <b-tooltip :target="`dt-header-${indicator.id}`">
              {{ indicator.tooltip }}
            </b-tooltip>
          </template>
          <template #body="{ data }">
            {{ $n(data[indicator.id]) }}
          </template>
        </Column>
      </DataTable>
    </b-row>
  </b-container>
</template>

<script lang="ts">
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { JSONPath } from 'jsonpath-plus';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DocumentNode } from 'graphql/language/ast';
import { mixins } from 'vue-class-component';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { getInstance } from '@/core/auth/wrapper';
import { READ, STATISTICS } from '@/conf/permissions';
import { Entity } from '@/core/store/models';
import StatsQueryMixin from '../../mixins/statsQueryMixin';
import {
  DetailedQueryModule,
  getDetailedQuery
} from '../../store/network/graphql/queries/detailed';
import { EntityStats } from '../../store/models/statistics';

Vue.component('DataTable', DataTable);
Vue.component('Column', Column);

export interface DetailedStatsIndicator {
  id: string;

  title: string;

  path: string;
}

export interface DetailedStatsQuery {
  name: string;
  scheme: DocumentNode;
}

@Component({
  apollo: {
    stats: {
      query() {
        return getDetailedQuery(this.module);
      },
      variables() {
        return {
          input: this.entityInput,
          startDate: this.dates.from,
          endDate: this.dates.to,
          children: this.entityInput?.children?.map((c: Entity) => c.id) || []
        };
      },
      skip() {
        return (
          !this.dates ||
          !this.dates.from ||
          !this.dates.to ||
          !this.selectedEntity
        );
      },
      error(error) {
        this.error = error;
      }
    }
  }
})
export default class DetailedStatsTable extends mixins(StatsQueryMixin) {
  @Prop(Array) private indicators: DetailedStatsIndicator[] | undefined;

  @Prop() private module!: DetailedQueryModule;

  private selectedEntity: Entity | null = null;

  private rows = 10;

  private stats = new EntityStats();

  get selectedIndicatorsColumns(): DetailedStatsIndicator[] {
    return this.indicators || [];
  }

  get totalRecords(): number {
    return this.children.length;
  }

  get children(): EntityStats[] {
    return this.stats?.children || [];
  }

  get statsData(): any[] {
    const authService = getInstance();
    if (authService.$ability.can(READ, STATISTICS)) {
      return this.children
        ?.filter((entity: EntityStats) => {
          return this.$store.getters['entities/getEntity'](entity.id) !== null;
        })
        .map((entity: EntityStats) => ({
          name: this.$store.getters['entities/getEntity'](entity.id).name,
          ...this.selectedIndicatorsColumns.reduce(
            (fields: any, indicator: DetailedStatsIndicator) => ({
              ...fields,
              [indicator.id]:
                JSONPath({
                  path: indicator.path,
                  wrap: false,
                  json: entity || {}
                }) === undefined
                  ? 0
                  : JSONPath({
                      path: indicator.path,
                      wrap: false,
                      json: entity || {}
                    })
            }),
            {}
          )
        }));
    }

    return [];
  }

  mounted(): void {
    this.queryName = 'stats';
    this.selectedEntity = this.currentEntity;
  }
}
</script>
<style lang="scss">
.p-datatable-sm {
  .even {
    background-color: $gray-100;
  }

  tr:hover {
    .even {
      background-color: transparent;
    }
  }
}
</style>
