import Campaign from '@/modules/communication/views/Campaign.vue';
import CampaignList from '@/modules/communication/views/components/CampaignList.vue';
import CampaignEdit from '@/modules/communication/views/components/CampaignEdit.vue';
import StepGeneral from '@/modules/communication/views/components/StepGeneral.vue';
import StepContacts from '@/modules/communication/views/components/StepContacts.vue';
import StepMessage from '@/modules/communication/views/components/StepMessage.vue';
import StepConfirm from '@/modules/communication/views/components/StepConfirm.vue';

export default [
  {
    path: '/communication/:loadEntityId?',
    props: true,
    component: Campaign,
    children: [
      {
        path: '',
        name: 'communication-campaign-list',
        component: CampaignList,
        meta: {
          permission: {
            view: 'communicate_campaign'
          }
        }
      },
      {
        path: 'edit',
        component: CampaignEdit,
        children: [
          {
            path: '',
            redirect: { name: 'communication-campaign-step-general' }
          },
          {
            path: 'general',
            name: 'communication-campaign-step-general',
            component: StepGeneral
          },
          {
            path: 'contacts',
            name: 'communication-campaign-step-contacts',
            component: StepContacts
          },
          {
            path: 'message',
            name: 'communication-campaign-step-message',
            component: StepMessage
          },
          {
            path: 'confirmation',
            name: 'communication-campaign-step-confirmation',
            component: StepConfirm
          }
        ],
        meta: {
          permission: {
            update: 'communicate_campaign'
          }
        }
      }
    ]
  }
];
