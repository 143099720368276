<template>
  <div class="dashboard">
    <b-container class="mb-4">
      <b-row>
        <b-col>
          <h5>{{ $t('welcome') }}</h5>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="mb-4">
      <b-row v-if="$can(p.VIEW, p.STATISTICS)">
        <b-col>
          <StatGroup
            :title="$t('analyse.dashboard.activityFacts.title')"
            icon-src="@/core/assets/img/icons/ico-histo.svg"
            :items="['StatItemActivityCommunication']"
          />
        </b-col>
      </b-row>
      <b-row class="mt-4" v-if="$can(p.VIEW, p.STATISTICS)">
        <b-col>
          <StatGroup
            :title="$t('analyse.dashboard.keyFacts.title')"
            icon-src="@/core/assets/img/icons/ico-indicators.svg"
            :items="['StatItemKeyCommunication']"
          />
        </b-col>
      </b-row>
      <b-row class="mt-4" v-if="false">
        <b-col>
          <LicenseDeployment />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import PCard from '@/core/components/cards/PCard.vue';
import { STATISTICS, VIEW } from '@/conf/permissions';
import StatGroup from './StatGroup.vue';
import StatItemPlatform from './StatItemPlatform.vue';
import StatItemCollect from './StatItemCollect.vue';
import StatItemCommunication from './StatItemCommunication.vue';
import StatItemKeyCommunication from './StatItemKeyCommunication.vue';
import LicenseDeployment from './LicenseDeployment.vue';
import StatItemActivityCommunication from './StatItemActivityCommunication.vue';

Vue.component('StatItemPlatform', StatItemPlatform);
Vue.component('StatItemCollect', StatItemCollect);
Vue.component('StatItemCommunication', StatItemCommunication);
Vue.component('StatItemKeyCommunication', StatItemKeyCommunication);
Vue.component('StatItemActivityCommunication', StatItemActivityCommunication);

@Component({
  components: {
    PCard,
    StatGroup,
    LicenseDeployment
  }
})
export default class Dashboard extends Vue {
  private p = { VIEW, STATISTICS };

  mounted(): void {
    this.$store.commit('app/subheader/ADD_COMPONENT', {
      name: 'SHDatePicker',
      id: 'sh-date-picker'
    });
  }
}
</script>
