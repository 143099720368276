<template>
  <div class="col-12">
    <div class="row text-center type-sms-row">
      <div class="col-12">
        <div class="sms-type-container">
          <div class="row justify-content-around">
            <sms-type-card
              :title="$t('smsEditor.smsTypeSelector.standard.title')"
              :description="
                $t('smsEditor.smsTypeSelector.standard.description')
              "
              :illustrationSrc="
                require('@/core/assets/img/sms-editor/IlluSmsStandard@2x.png')
              "
              :price="simplePrice"
              v-model="standard"
            ></sms-type-card>

            <sms-type-card
              :title="$t('smsEditor.smsTypeSelector.premium.title')"
              :description="$t('smsEditor.smsTypeSelector.premium.description')"
              :illustrationSrc="
                require('@/core/assets/img/sms-editor/IlluSmsPremium@2x.png')
              "
              :price="premiumPrice"
              v-model="premium"
            ></sms-type-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, ModelSync, Prop, Vue } from 'vue-property-decorator';
import ESmsType from '@/core/enum/ESmsType';
import SmsTypeCard from './SmsTypeCard.vue';

@Component({ components: { SmsTypeCard } })
export default class SmsTypeSelector extends Vue {
  @Prop({ type: String, required: true }) readonly simplePrice!: string;

  @Prop({ type: String, required: true }) readonly premiumPrice!: string;

  @ModelSync('type', 'change', { type: String })
  typeValue!: string | undefined;

  get standard() {
    return this.typeValue === ESmsType.SIMPLE;
  }

  set standard(value) {
    if (value === true) {
      this.typeValue = ESmsType.SIMPLE;
    } else {
      this.typeValue = undefined;
    }
  }

  get premium() {
    return this.typeValue === ESmsType.PREMIUM;
  }

  set premium(value) {
    if (value === true) {
      this.typeValue = ESmsType.PREMIUM;
    } else {
      this.typeValue = undefined;
    }
  }
}
</script>

<style scoped>
.type-sms-row {
  display: flex;
  justify-content: center;
  margin-top: 0;
  margin-bottom: 15px;
}

.sms-type-container {
  margin: 0 auto;
  max-width: 1072px;
}
</style>
