import { BvMsgBoxOptions } from 'bootstrap-vue';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class PopupMixin extends Vue {
  popupInfo(message: string, options?: BvMsgBoxOptions): Promise<any> {
    return this.$bvModal.msgBoxOk(message, {
      ...(options || {}),
      okTitle: `${this.$t('global.actions.confirm')}`
    });
  }

  popupConfirm(message: string, options?: BvMsgBoxOptions): Promise<any> {
    return this.$bvModal.msgBoxConfirm(message, {
      ...(options || {}),
      okTitle: `${this.$t('global.actions.confirm')}`,
      cancelTitle: `${this.$t('global.actions.cancel')}`
    });
  }

  popupConfirmDanger(message: string, options?: BvMsgBoxOptions): Promise<any> {
    return this.popupConfirm(message, {
      ...(options || {}),
      okVariant: 'danger'
    });
  }
}
