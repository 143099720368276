<template>
  <StatItem
    :tooltip-string="tooltipString"
    :title="title"
    :icon-src="iconSrc"
    :button-title="buttonTitle"
    :button-link="buttonLink"
    :loading="loading"
    :sub-title="subTitle"
    :stat-title="statTitle"
    :stat="stat"
    :statId="statId"
    :activityStatError="activityStatError"
  >
    <b-container v-if="!loading && !activityStatError">
      <b-row class="d-flex justify-content-center" align-v="center">
        <div>
          <circular-indicator
            :percentage="percentage"
            :value="value"
            :fluctuation="fluctuation"
            :duration="duration"
            :tooltipPercentage="tooltipPercentage"
            :tooltipValue="tooltipValue"
          >
          </circular-indicator>
        </div>
        <div>{{ $t('analyse.dashboard.utils.active') }}</div>
      </b-row>
    </b-container>
  </StatItem>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { READ, STATISTICS } from '@/conf/permissions';
import CircularIndicator from '@/core/components/widgets/CircularIndicator.vue';
import StatItem from './StatItem.vue';

@Component({ components: { StatItem, CircularIndicator } })
export default class StatItemActivity extends Vue {
  // Circular Indicator
  @Prop(Number) private percentage!: number;

  @Prop(Number) private value: number | undefined;

  @Prop(Number) private fluctuation: number | undefined;

  @Prop(Number) private duration: number | undefined;

  @Prop(String) private tooltipPercentage: string | undefined;

  @Prop(String) private tooltipValue: string | undefined;

  // StatItem

  @Prop(String) private title: string | undefined;

  @Prop(String) private iconSrc: string | undefined;

  @Prop(String) private buttonTitle: string | undefined;

  @Prop(String) private subTitle: string | undefined;

  @Prop(String) private statTitle: string | undefined;

  @Prop(Number) private stat: number | undefined;

  @Prop(String) private buttonLink: string | undefined;

  @Prop(String) private tooltipString: string | undefined;

  @Prop(String) private statId: string | undefined;

  @Prop(Boolean) private loading: boolean | undefined;

  @Prop(Boolean) private activityStatError: boolean | undefined;

  private p = { READ, STATISTICS };
}
</script>

<style lang="scss" scoped></style>
