<template>
  <div>
    <b-container>
      <b-row>
        <DataTable
          :value="campaigns"
          responsiveLayout="scroll"
          :scrollable="true"
          :rowHover="true"
          :stripedRows="true"
          scrollDirection="horizontal"
          :lazy="true"
          :loading="campaignLoading"
          :totalRecords="campaignsCount"
          :paginator="true"
          :rows="rows"
          :paginatorTemplate="paginatorTemplate"
          :currentPageReportTemplate="
            $t('communication.campaign.table.pageReport')
          "
          :rowsPerPageOptions="[10, 25, 50, 100]"
          sortField="creationDate"
          @sort="fetchCampaignsByEvent($event)"
          :sortOrder="-1"
          removableSort
          @page="fetchCampaignsByEvent($event)"
        >
          <template #header>
            <div class="d-flex flex-row">
              <b-form inline @reset="onReset()" class="mr-auto">
                <b-input-group>
                  <b-input-group-prepend>
                    <b-input-group-text>
                      <em class="pi pi-search" />
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input
                    :placeholder="
                      $t('communication.campaign.table.filter.placeholder')
                    "
                    v-model="form.filter"
                    @input="onFilter()"
                    type="search"
                    class="search-imput"
                    data-cy="search"
                  />
                </b-input-group>
              </b-form>
              <b-button
                class="py-2"
                variant="primary"
                :to="{ name: 'communication-campaign-step-general' }"
                data-cy="create"
              >
                <i class="fas fa-plus" />
                {{ $t('communication.campaign.actions.create') }}
              </b-button>
            </div>
          </template>
          <Column
            className="type__column"
            :header="$t('communication.campaign.table.headers.type')"
          >
            <template #body>
              <img
                class="communication-type__img mx-auto"
                src="@/core/assets/img/communication/sms.png"
                alt="Smartphone icon"
                :title="$t('communication.campaign.table.types.sms-standard')"
              />
            </template>
          </Column>
          <Column
            field="name"
            :header="$t('communication.campaign.table.headers.name')"
            :sortable="true"
          ></Column>
          <Column
            field="creationDate"
            :header="$t('communication.campaign.table.headers.creation-date')"
            :sortable="true"
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.creationDate) }}
            </template>
          </Column>
          <Column
            field="sendDate"
            :header="$t('communication.campaign.table.headers.send-date')"
            :sortable="true"
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.sendDate) }}
            </template>
          </Column>
          <Column
            className="subcampaigns__column"
            field="id"
            :header="$t('communication.campaign.table.headers.sub-campaigns')"
          >
            <template #body="slotProps">
              <div class="ml-4">
                <b-link
                  @click="onViewSubCampaigns(slotProps.data.id)"
                  :title="$t('communication.campaign.table.sub-campaigns')"
                >
                  <em class="far fa-eye" />
                </b-link>
              </div>
            </template>
          </Column>
          <Column
            className="actions__column"
            :header="$t('communication.campaign.table.headers.actions')"
          >
            <template #body="slotProps">
              <b-button
                variant="link"
                class="py-0"
                :title="$t('communication.campaign.actions.edit')"
                :to="{
                  name: 'communication-campaign-step-general',
                  query: { cid: slotProps.data.id }
                }"
                data-cy="edit"
              >
                <i class="fas fa-pen" />
              </b-button>
            </template>
          </Column>
          <template #empty>{{
            $t('communication.campaign.table.empty')
          }}</template>
        </DataTable>
      </b-row>
    </b-container>
    <b-modal
      v-model="modalSubCampaignsShow"
      :title="
        $t('communication.campaign.sub-campaigns-modal.title', {
          nb: modalSubCampaigns.length
        })
      "
      scrollable
      centered
      ok-only
    >
      <div class="d-flex justify-content-center mb-3" v-if="subCampaignLoading">
        <b-spinner
          style="width: 3rem; height: 3rem"
          variant="primary"
          label="Loading..."
          type="grow"
        ></b-spinner>
      </div>
      <b-table
        striped
        :items="modalSubCampaigns"
        :fields="modalSubCampaignsFields"
        thead-class="hidden_header"
        v-if="!subCampaignLoading && modalSubCampaigns.length > 0"
      ></b-table>
      <div v-if="!subCampaignLoading && modalSubCampaigns.length == 0">
        {{ $t('communication.campaign.sub-campaigns-modal.empty') }}
      </div>
    </b-modal>
  </div>
</template>

<script lang="ts">
/* eslint-disable class-methods-use-this */
import { Component, Vue } from 'vue-property-decorator';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import moment from 'moment';
import { DatatableParamsType } from '../../store/state';

/* based on https://www.primefaces.org/primevue-v2/#/datatable/lazy */
@Component({ components: { DataTable, Column } })
export default class CampaignList extends Vue {
  form = {
    filter: ''
  };

  timer: any;

  modalSubCampaignsShow = false;

  modalSubCampaignsFields = ['name'];

  unsubscribe: any;

  get campaignLoading() {
    return this.$store.state.communication.campaignLoading;
  }

  get campaigns() {
    return this.$store.state.communication.campaigns;
  }

  get rows() {
    return this.$store.state.communication.paginationParams.rows;
  }

  get campaignsCount() {
    return this.$store.state.communication.campaignsCount;
  }

  get subCampaignLoading() {
    return this.$store.state.communication.subCampaignLoading;
  }

  get modalSubCampaigns() {
    return this.$store.state.communication.subCampaigns;
  }

  get paginatorTemplate() {
    return 'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown';
  }

  created() {
    this.form.filter = this.$store.state.communication.searchQuery;

    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === 'entities/SELECT_ENTITY') {
        this.$store.dispatch('communication/fetchCampaigns', {
          entityId: this.$store.getters['entities/current'].id,
          event: {}
        });
      }
    });
  }

  beforeDestroy() {
    this.unsubscribe();
  }

  async mounted() {
    await this.$store.dispatch('communication/fetchCampaigns', {
      entityId: this.$store.getters['entities/current'].id,
      event: this.$store.state.communication.paginationParams
    });
  }

  fetchCampaignsByEvent(event: DatatableParamsType) {
    this.$store.dispatch('communication/fetchCampaigns', {
      entityId: this.$store.getters['entities/current'].id,
      event
    });
  }

  onReset() {
    this.form = {
      filter: ''
    };
    this.$store.dispatch('communication/searchCampaigns', {
      entityId: this.$store.getters['entities/current'].id,
      query: ''
    });
  }

  onFilter() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.$store.dispatch('communication/searchCampaigns', {
        entityId: this.$store.getters['entities/current'].id,
        query: this.form.filter
      });
    }, 250);
  }

  onViewSubCampaigns(campaignId: string) {
    this.$store.dispatch('communication/fetchSubCampaigns', {
      campaignId
    });

    this.modalSubCampaignsShow = true;
  }

  formatDate(date: string) {
    if (date) {
      return moment(date).format('DD/MM/YYYY HH:mm');
    }
    return '';
  }
}
</script>

<style>
.p-datatable {
  width: 100%;
}
.p-datatable .p-datatable-thead > tr > th {
  padding: 0.75rem;
}
.hidden_header {
  display: none;
}
.communication-type__img {
  height: 20px;
}
.type__column {
  max-width: 80px;
}
.subcampaigns__column {
  max-width: 130px;
}
.actions__column {
  max-width: 120px;
}
.input-group > input.search-imput {
  width: 230px;
}
</style>
