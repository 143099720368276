<template>
  <div class="form-group">
    <label for="input-sender">
      {{ $t('smsEditor.tools.smsSenderInput.label') }} * :
    </label>
    <div class="input-group">
      <b-form-input
        id="input-sender"
        v-model="messageSender"
        type="text"
        disabled
      />
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class SmsSenderInput extends Vue {
  @Prop({ type: String, required: true })
  readonly messageSender!: string;
}
</script>
<style scoped>
#input-sender {
  width: 50%;
}
</style>
