<template>
  <div
    id="portal"
    :class="[
      { collapsed: collapsed },
      { onmobile: isOnMobile },
      { loading: isLoading }
    ]"
  >
    <div v-if="isError" class="error">
      <ErrorPopup :title="$t('global.errors.unknown.title')" :onClose="logout">
        {{ $t('global.errors.unknown.text') }}
      </ErrorPopup>
    </div>
    <div v-else-if="isLoading" class="loader"></div>
    <div v-else>
      <b-toast
        v-model="toast.visible"
        id="main-toast"
        :title="toast.title"
        :variant="toast.variant"
        :auto-hide-delay="toast.delay"
      >
        {{ toast.message }}
      </b-toast>
      <div class="sticky-top headers">
        <Header />
        <SubHeader v-if="isSubheaderDisplayed" />
      </div>
      <div class="content">
        <Content />
      </div>
      <div class="menu">
        <Menu :width="menuWidth" />
      </div>
      <div
        v-if="isOnMobile && !collapsed"
        class="sidebar-overlay"
        @click="collapsed = true"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Emit, Watch } from 'vue-property-decorator';
import Menu from '@/core/components/layout/Menu.vue';
import Header from '@/core/components/layout/Header.vue';
import SubHeader from '@/core/components/layout/subheader/SubHeader.vue';
import Content from '@/core/components/layout/Content.vue';
import ErrorPopup from '@/core/components/popups/ErrorPopup.vue';
import i18n from '@/plugins/i18n';
import { Entity } from '@/core/store/models';
import { menuWidth } from '@/conf/menu';
import LogoutMixin from '@/core/mixins/logoutMixin';

@Component({
  components: {
    Menu,
    Header,
    SubHeader,
    Content,
    ErrorPopup
  },
  mixins: [LogoutMixin]
})
export default class App extends Vue {
  private menuWidth = menuWidth;

  get isSubheaderDisplayed(): boolean {
    return this.$store.getters['app/subheader/isDisplayed'];
  }

  get entities(): Entity[] {
    return this.$store.getters['entities/all'];
  }

  get currentEntity(): Entity {
    return this.$store.getters['entities/current'];
  }

  @Watch('currentEntity')
  onCurrentEntityChanged(): void {
    this.$store.dispatch('app/rebuildMenu');
  }

  mounted(): void {
    document.title = i18n.t('title').toString();
    this.onResize();
    window.addEventListener('resize', this.onResize);
  }

  get collapsed(): boolean {
    return this.$store.getters['app/isCollapsed'];
  }

  set collapsed(status: boolean) {
    this.$store.dispatch('app/SET_COLLAPSED', status);
  }

  get isOnMobile(): boolean {
    return this.$store.getters['app/isOnMobile'];
  }

  get isLoading(): boolean {
    return (
      !this.$store.getters['auth/isAuthenticated'] || this.entities.length === 0
    );
  }

  get isError(): boolean {
    return this.$store.getters['auth/isError'];
  }

  get toast(): boolean {
    return this.$store.getters['app/toast'];
  }

  @Emit()
  onResize(): void {
    const mobileTriggerSize = 1024;
    this.$store.commit(
      'app/SET_COLLAPSED',
      window.innerWidth <= mobileTriggerSize
    );
    this.$store.commit(
      'app/SET_IS_MOBILE',
      window.innerWidth <= mobileTriggerSize
    );
  }
}
</script>
<style scoped lang="scss">
#portal {
  .menu {
    z-index: 20;
    top: $header-height;
    position: fixed;
  }

  .navigation {
    z-index: 10;
  }

  .content {
    z-index: 0;
    padding-left: 250px;
    transition: 0.3s ease;
  }

  &.collapsed {
    .content {
      padding-left: 50px;
    }
  }
}
</style>
