import i18n from '@/plugins/i18n';
import { getInstance } from '@/core/auth/wrapper';
import { VIEW, STATISTICS } from '@/conf/permissions';

export default {
  name: 'analyse',
  createMenu: (): any => {
    const authService = getInstance();
    return {
      title: `${i18n.t('analyse.menu.title')}`,
      icon: 'fa fa-tachometer-alt',
      hidden: !authService || !authService.$ability.can(VIEW, STATISTICS),
      child: [
        {
          icon: 'fas fa-chart-pie',
          href: '/analyse/communication',
          exactPath: true,
          title: `${i18n.t('analyse.communication.menu.title')}`
        }
        // , Mis en commentaire car sera réutilisé plus tard (fait le 19/11/21)
        // {
        //   icon: 'fas fa-chart-pie',
        //   href: '/analyse/collect',
        //   exactPath: true,
        //   title: `${i18n.t('analyse.collect.menu.title')}`
        // },
        // {
        //   icon: 'fas fa-chart-pie',
        //   href: '/analyse/contacts',
        //   exactPath: true,
        //   title: `${i18n.t('analyse.contacts.menu.title')}`
        // },
        // {
        //   icon: 'fas fa-chart-pie',
        //   href: '/analyse/activity',
        //   exactPath: true,
        //   title: `${i18n.t('analyse.activity.menu.title')}`
        // }
      ]
    };
  }
};
