<template>
  <div @dragover.prevent class="col-12 h-100">
    <div class="row h-100">
      <div class="col-5 px-4 pt-3">
        <b-form @submit.stop.prevent="onSubmit">
          <b-form-group
            id="input-group-phone-nums"
            :label="$t('smsEditor.smsSendTest.label')"
            label-for="input-phone-nums"
          >
            <b-input-group
              :class="{ 'is-invalid': validateState('phone') === false }"
            >
              <b-form-input
                id="input-phone-nums"
                :placeholder="$t('smsEditor.smsSendTest.placeholder')"
                type="text"
                v-model="$v.phone.$model"
                :state="validateState('phone')"
                aria-describedby="input-phone-nums-feedback"
              ></b-form-input>
              <b-input-group-append>
                <b-button
                  type="submit"
                  variant="primary"
                  :disabled="!isSubmitable"
                >
                  <b-spinner
                    v-if="sending"
                    small
                    type="grow"
                    label="Spinning"
                  ></b-spinner>
                  {{ $t('smsEditor.smsSendTest.button') }}
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-form-invalid-feedback id="input-phone-nums-feedback">
              <span v-if="!this.invalidMessage">
                {{ $t('smsEditor.feedback.phoneNumber') }}
              </span>
              <span v-else>
                {{ $t('smsEditor.feedback.invalidMessage') }}
              </span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-form>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Validations } from 'vuelidate-property-decorators';
import { required, helpers } from 'vuelidate/lib/validators';

// phone inupt validation regex
// an optional '+' at start, then 5 to 25 digits.
// This is broad to allow all types of phone numbers in the world
const phoneNumber = helpers.regex('phoneNumber', /^[+]?[0-9]{5,25}$/);

@Component
export default class SmsSendTest extends Vue {
  @Prop({ type: Boolean, default: false }) readonly sending!: boolean;

  @Prop({ type: Boolean, default: false }) readonly invalidMessage!: boolean;

  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean;

  phone = '';

  @Validations()
  validations = {
    phone: {
      required,
      phoneNumber
    }
  };

  get isSubmitable(): boolean {
    return (
      !this.disabled &&
      !this.invalidMessage &&
      !this.sending &&
      this.$v.$dirty &&
      !this.$v.$invalid
    );
  }

  onSubmit() {
    this.$v.$touch();
    if (!this.isSubmitable) {
      return;
    }

    this.$emit('send-test', this.phone);
  }

  public validateState(name: string) {
    const $dirty = this.$v[name]?.$dirty;
    const $error = this.$v[name]?.$error;
    return $dirty ? !$error && !this.invalidMessage : null;
  }
}
</script>
<style scoped></style>
