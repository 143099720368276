<template>
  <p-card
    :title="$t('analyse.dashboard.licenses.title')"
    icon-src="@/core/assets/img/icons/ico-network.svg"
    type="group"
  >
    TODO: licence deployment stats
  </p-card>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import PCard from '@/core/components/cards/PCard.vue';

@Component({ components: { PCard } })
export default class LicenseDeployment extends Vue {}
</script>

<style lang="scss" scoped></style>
