<template>
  <b-card>
    <template #header>
      <div class="p-3 card-header-line">
        <b-row>
          <b-col cols="12" md="auto" class="card-title">
            <img alt="icon" v-if="iconSrc" :src="iconSrc" />
            <h2 v-if="isGroup" class="ml-3">{{ title }}</h2>
            <h3 v-else class="ml-3">{{ title }}</h3>
          </b-col>
          <b-col>
            <slot name="header"></slot>
          </b-col>
        </b-row>
      </div>
      <hr class="m-0" v-if="!isGroup" />
    </template>
    <b-card-text>
      <slot />
    </b-card-text>
  </b-card>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class PCard extends Vue {
  @Prop(String) readonly type: string | undefined;

  @Prop(String) readonly iconSrc: string | undefined;

  @Prop(String) readonly title: string | undefined;

  get isGroup(): boolean {
    return this.type === 'group';
  }
}
</script>
<style lang="scss" scoped>
$single-card-header-inner-height: 2rem;
.card-header {
  // border-bottom: 1px solid $card-header-separator-color;
  @include spaced-height($single-card-header-inner-height, 3);

  .card-header-line {
    .row {
      > div {
        line-height: $single-card-header-inner-height;
        margin: auto;
      }

      .card-title {
        > * {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }

  img {
    max-height: $single-card-header-inner-height;
  }

  h2 {
    color: $group-card-title-color;
    margin: 0;
  }

  h3 {
    color: $single-card-title-color;
    margin: 0;
  }
}
</style>
