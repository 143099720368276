import Vue from 'vue';

import {
  BootstrapVue,
  FormPlugin,
  LayoutPlugin,
  NavbarPlugin
} from 'bootstrap-vue';
import '../styles/main.scss';

Vue.use(BootstrapVue);
Vue.use(LayoutPlugin);
Vue.use(FormPlugin);
Vue.use(NavbarPlugin);
