import gql from 'graphql-tag';

export default gql`
  query getEntityKeyCommunication(
    $input: EntityInput!
    $children: [ID]
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    getCommunicationStat(input: $input, populateFor: $children) {
      communication(startDate: $startDate, endDate: $endDate) {
        campaignStats {
          total {
            total
          }
        }
      }
    }
  }
`;
