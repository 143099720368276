import { render, staticRenderFns } from "./SubHeader.vue?vue&type=template&id=a0590d42&scoped=true"
import script from "./SubHeader.vue?vue&type=script&lang=ts"
export * from "./SubHeader.vue?vue&type=script&lang=ts"
import style0 from "./SubHeader.vue?vue&type=style&index=0&id=a0590d42&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a0590d42",
  null
  
)

export default component.exports