<template>
  <div @dragover.prevent class="col-12 h-100">
    <div class="row h-100 sms-editor-row" data-cy="SmsTextEditor">
      <div class="col-6 px-4">
        <form v-on:submit.prevent>
          <!-- EXPEDITEUR -->
          <sms-sender-input
            :messageSender="messageSender"
            data-cy="smsSender"
          />

          <!-- EDITION DU SMS-->
          <sms-body-input
            v-model="messageBody"
            :charset="charset"
            :maxlength="bodyMaxLength"
            :footer="footer"
            :state="validateState('messageBody')"
            :requiredError="!$v.messageBody.required"
            :maxlengthError="!$v.fulltext.maxLength"
            @preview-change="onPreviewChange"
            @fulltext-change="onFulltextChange"
            @error="onError"
            @change="onChange"
            data-cy="smsBody"
          />

          <sms-link-title-input
            v-if="hasLink"
            v-model="messageLinkTitle"
            :charset="charset"
            :maxlength="linkTitleMaxLength"
            :state="validateState('messageLinkTitle')"
            @change="onChange"
            @error="onError"
            data-cy="smsLink"
          />
        </form>

        <div class="row mt-3">
          <sms-counter
            :charactersCount="charactersCount"
            :smsCount="smsCount"
            :nextSmsLimit="nextSmsLimit"
            data-cy="smsCounter"
          />
        </div>

        <!-- Messages d'information en accordéon -->
        <sms-info-message
          :unicodeCharacters="unicodeCharacters"
          :smsCount="smsCount"
          :nextSmsLimit="nextSmsLimit"
          data-cy="smsEditorMessage"
        />

        <div class="row mt-2">
          <status-save :status="status" />
        </div>
      </div>
      <!-- PREVIEW -->
      <div class="col-6 bg-light">
        <div class="mt-4">
          <h5 class="text-center">{{ $t('smsEditor.smsEditor.preview') }} :</h5>
        </div>
        <sms-preview
          class="preview"
          title="Preview SMS"
          :header="messageSender"
          :preview="messagePreview"
          data-cy="smsPreview"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Emit,
  ModelSync,
  Prop,
  Vue,
  Watch
} from 'vue-property-decorator';
import { Validations } from 'vuelidate-property-decorators';
import { required, maxLength } from 'vuelidate/lib/validators';
import { SmsPreview } from '@dolmen/vue-toolkit';
import { SMS_FOOTER, SmsType, LINK_PREVIEW } from '@/core/utils/smsUtils';
import SmsMode from '@/core/utils/smsUtils/SmsMode';
import SmsModeStandard from '@/core/utils/smsUtils/SmsModeStandard';
import SmsModeWithLink from '@/core/utils/smsUtils/SmsModeWithLink';
import SmsCharFactory from '@/core/utils/smsUtils/SmsCharFactory';
import ESmsSaveStatus from '@/core/enum/ESmsSaveStatus';
import SmsSenderInput from './tools/SmsSenderInput.vue';
import SmsBodyInput from './tools/SmsBodyInput.vue';
import SmsLinkTitleInput from './tools/SmsLinkTitleInput.vue';
import SmsCounter from './tools/SmsCounter.vue';
import SmsInfoMessage from './tools/SmsInfoMessage.vue';
import StatusSave from './tools/StatusSave.vue';

@Component({
  components: {
    SmsPreview,
    SmsSenderInput,
    SmsBodyInput,
    SmsLinkTitleInput,
    SmsCounter,
    SmsInfoMessage,
    StatusSave
  }
})
export default class SmsEditor extends Vue {
  @ModelSync('sms', 'change', { type: Object })
  smsValue!: SmsType;

  @Prop({ type: String }) readonly status!: ESmsSaveStatus | null;

  @Prop({ type: Boolean, default: false }) readonly hasLink!: boolean;

  messageSender = `[${this.$t('smsEditor.tools.smsSenderInput.placeholder')}]`;

  footer = SMS_FOOTER;

  link = LINK_PREVIEW;

  preview = '';

  fulltext = '';

  messageMaxLength!: number;

  bodyMaxLength!: number;

  linkTitleMaxLength!: number;

  smsCount!: number;

  nextSmsLimit!: number;

  smsMode!: SmsMode;

  charset!: string;

  @Validations()
  validations() {
    return {
      messageBody: {
        required
      },
      fulltext: {
        maxLength: maxLength(this.messageMaxLength)
      },
      messageLinkTitle: {
        maxLength: maxLength(this.linkTitleMaxLength)
      }
    };
  }

  get messageBody(): string {
    return this.smsValue?.message;
  }

  set messageBody(value: string) {
    this.smsValue.message = value;
    this.$v.messageBody.$touch();

    this.$emit('change', this.smsValue);
    this.stateUpdate();
  }

  get messageLinkTitle(): string {
    return this.smsValue?.linkTitle;
  }

  set messageLinkTitle(value: string) {
    this.smsValue.linkTitle = value;
    this.$v.messageLinkTitle.$touch();

    // Force the preview update
    const prev = this.preview;
    this.messagePreview = '';
    this.messagePreview = prev;

    // Update component state
    this.computeBodyLimit(value);
    this.computeLinkTitleLimit(this.fulltext);
    this.countSms();

    this.$emit('change', this.smsValue);
    this.stateUpdate();
  }

  get charactersCount(): number {
    return this.smsMode.countCharacters(this.messageFulltext);
  }

  get unicodeCharacters(): Array<string> {
    return Array.from(this.messageFulltext).filter((char) =>
      SmsCharFactory.isUnicodeText(char)
    );
  }

  get messagePreview(): string {
    if (this.hasLink) {
      return `${this.preview}</br>${
        this.smsValue?.linkTitle
          ? `${this.smsValue?.linkTitle
              .replace(/&/gi, '&amp')
              .replace(/</gi, '&lt')}</br>`
          : ''
      }${this.link}</br>${this.footer}`;
    }
    return `${this.preview}</br>${this.footer}`;
  }

  set messagePreview(value: string) {
    this.preview = value;
  }

  get messageFulltext(): string {
    if (this.hasLink) {
      return `${this.fulltext}\n${
        this.smsValue?.linkTitle ? `${this.smsValue?.linkTitle}\n` : ''
      }${this.link}\n${this.footer}`;
    }
    return `${this.fulltext}\n${this.footer}`;
  }

  beforeMount() {
    // init mode
    this.smsMode = this.hasLink
      ? (new SmsModeWithLink(
          SmsCharFactory.createSmsChar(
            this.smsValue.message,
            this.smsValue.linkTitle
          )
        ) as SmsMode)
      : (new SmsModeStandard(
          SmsCharFactory.createSmsChar(this.smsValue.message)
        ) as SmsMode);

    this.charset = this.smsMode.getCharset();
    // update component state
    this.computeMessageLimit();
    this.computeBodyLimit(this.smsValue.linkTitle);
    this.computeLinkTitleLimit(this.fulltext);
    this.smsCount = this.smsMode.countSms(this.charactersCount);
    this.nextSmsLimit = this.smsMode.countNextSmsLimit(this.smsCount);
  }

  mounted() {
    // manage form validation
    this.$v.messageBody.$touch();
    this.$v.fulltext.$touch();
    this.$v.messageLinkTitle.$touch();
    this.stateUpdate();
  }

  onChange() {
    this.replaceUnsupportedChars();
    this.computeCharType();
    this.computeMessageLimit();
    this.computeBodyLimit(this.smsValue.linkTitle);
    this.computeLinkTitleLimit(this.fulltext);
  }

  onPreviewChange(value: string) {
    this.messagePreview = value;
  }

  onFulltextChange(value: string) {
    this.fulltext = value;
    this.computeLinkTitleLimit(value);
    this.computeBodyLimit(this.smsValue.linkTitle);
    this.countSms();
  }

  countSms() {
    this.smsCount = this.smsMode.countSms(this.charactersCount);
    this.$v.fulltext.$touch();
    this.$emit('sms-count', this.smsCount);

    this.nextSmsLimit = this.smsMode.countNextSmsLimit(this.smsCount);
  }

  static handleReplacementsUnsupportedChars(input: string) {
    return (
      input
        .replace(/’/g, "'")
        .replace(/‘/g, "'")
        .replace(/“/g, '"')
        .replace(/”/g, '"')
        // eslint-disable-next-line no-irregular-whitespace
        .replace(/ /g, ' ')
        // eslint-disable-next-line no-control-regex
        .replace(/	/g, ' ')
        .replace(/…/g, '...')
        .replace(/–/g, '-')
        .replace(/-/g, '-')
        .replace(/‚/g, ',')
    );
  }

  replaceUnsupportedChars() {
    this.smsValue.message = SmsEditor.handleReplacementsUnsupportedChars(
      this.smsValue.message
    );
    this.smsValue.linkTitle = SmsEditor.handleReplacementsUnsupportedChars(
      this.smsValue.linkTitle
    );
  }

  computeCharType() {
    if (this.hasLink) {
      this.smsMode.setSmsCharType(
        SmsCharFactory.createSmsChar(
          this.smsValue.message,
          this.smsValue.linkTitle
        )
      );
    } else {
      this.smsMode.setSmsCharType(
        SmsCharFactory.createSmsChar(this.smsValue.message)
      );
    }
    this.charset = this.smsMode.getCharset();
  }

  computeMessageLimit() {
    this.messageMaxLength = this.smsMode.computeMessageLimit();
  }

  computeBodyLimit(linkTitle: string | undefined) {
    this.bodyMaxLength = this.smsMode.computeBodyLimit(
      this.footer,
      linkTitle,
      this.link
    );
  }

  computeLinkTitleLimit(fulltextValue: string) {
    this.linkTitleMaxLength = this.smsMode.computeLinkTitleLimit(
      fulltextValue,
      this.footer,
      this.link
    );
  }

  /**
   * Manage smsType change (with or without link)
   * Truncate body if needed.
   */
  @Watch('hasLink')
  onHasLinkChange(val: boolean) {
    this.computeCharType();
    this.computeBodyLimit(this.smsValue.linkTitle);
    if (val && this.charactersCount > this.messageMaxLength) {
      const body = this.messageBody;
      this.messageBody = body.substr(
        0,
        body.length - (this.fulltext.length - this.bodyMaxLength)
      );
      this.$emit('error', { type: 'truncate-body' });
    }
    this.computeLinkTitleLimit(this.fulltext);
    this.countSms();
  }

  @Emit('state-change')
  stateUpdate() {
    return this.$v.$dirty ? !this.$v.$error : null;
  }

  public validateState(name: string) {
    const $dirty = this.$v[name]?.$dirty;
    const $error = this.$v[name]?.$error;
    return $dirty ? !$error : null;
  }

  @Emit('error')
  // eslint-disable-next-line class-methods-use-this
  onError(content: { type: string }): { type: string } {
    return content;
  }
}
</script>

<style src="@/core/assets/css/PreviewsPositions.css"></style>
<style>
.sms-editor-row {
  min-height: 660px;
}

.fa-exclamation-circle.orange {
  color: #f98844;
}

.fa-check-circle.blue {
  color: #30445f;
}
</style>
