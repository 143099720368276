import GenericCountStats from './genericCountStats';

export default class CommunicationActivityStats {
  total?: number;

  active?: GenericCountStats;

  constructor(data?: Partial<CommunicationActivityStats>) {
    Object.assign(this, data);
  }
}
