import gql from 'graphql-tag';

export default gql`
  query getEntityKeyCommunication(
    $input: EntityInput!
    $children: [ID]
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    stats: getCommunicationStat(input: $input, populateFor: $children) {
      children {
        id
        communication(startDate: $startDate, endDate: $endDate) {
          transmittedMessage {
            sms {
              carbonImpact
              count
              percent
            }
            email {
              carbonImpact
              count
              percent
            }
            print {
              carbonImpact
              count
              percent
            }
            total {
              carbonImpact
              count
              percent
            }
          }
          campaignStats {
            sms {
              total
              model {
                count
                percent
              }
            }
            email {
              total
              model {
                count
                percent
              }
            }
            print {
              total
              model {
                count
                percent
              }
            }
            total {
              total
              model {
                count
                percent
              }
            }
          }
        }
      }
    }
  }
`;
