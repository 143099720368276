<template>
  <b-container>
    <b-row>
      <b-col class="my-4">
        <Steps :model="items" />
      </b-col>
    </b-row>
    <router-view></router-view>
  </b-container>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Steps from 'primevue/steps';
import { Route, NavigationGuardNext } from 'vue-router';

@Component({
  components: { Steps },
  // Avoid enter in step without campaignId (except general step => creation)
  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext<any>) {
    if (
      to.path !== '/communication/edit/general' &&
      (typeof to.query.cid === 'undefined' || to.query.cid === '')
    ) {
      next(false);
    } else {
      next();
    }
  },

  beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext<any>) {
    if (
      to.path !== '/communication/edit/general' &&
      (typeof to.query.cid === 'undefined' || to.query.cid === '')
    ) {
      next(false);
    } else {
      next();
    }
  }
})
export default class CampaignEdit extends Vue {
  mounted() {
    this.$store.commit('communication/SET_EDITMODE', true);
  }

  destroyed() {
    this.$store.commit('communication/SET_EDITMODE', false);
  }

  items = [
    {
      label: 'General',
      to: '/communication/edit/general'
    },
    {
      label: 'Contacts',
      to: '/communication/edit/contacts'
    },
    {
      label: 'Message',
      to: '/communication/edit/message'
    },
    {
      label: 'Confirmation',
      to: '/communication/edit/confirmation'
    }
  ];
}
</script>

<style lang="scss">
.my-4 .p-steps .p-steps-item.p-highlight .p-steps-number {
  background: var(--blue);
}
</style>
