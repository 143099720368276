/* eslint-disable no-param-reassign */
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { EntitiesClient } from '@/core/store/network';
import { Entity } from '@/core/store/models';
import store from '@/core/store';
import { EntitiesState } from './state';
import { Operation, displayError } from '../apiError';

const updateEntityInTree = (
  entities: Entity[] | undefined,
  entity: Entity,
  parent: number | undefined | null = undefined
): Entity[] | undefined => {
  // Process entities in this level
  let resultingEntities = entities;

  // Process children
  if (entities) {
    entities.forEach((e: Entity) => {
      e.children = updateEntityInTree(e.children, entity, e.id);
    });
  }
  // Process entities in this level
  const index = entities ? entities.findIndex((e) => e.id === entity.id) : -1;
  if (resultingEntities && index >= 0) {
    // Existing entity
    if (parent === entity.parent || parent === null) {
      // Just updated
      resultingEntities.splice(index, 1, entity);
    } else {
      // Not in this parent anymore
      resultingEntities.splice(index, 1);
    }
  } else if (parent === entity.parent) {
    // New entity in this parent
    resultingEntities = resultingEntities || [];
    resultingEntities.push(entity);
  }
  return resultingEntities;
};

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: 'entities'
})
export default class Entities extends VuexModule implements EntitiesState {
  private entitiesClient = new EntitiesClient();

  entities: Entity[] = [];

  currentEntity: Entity | null = null;

  previousEntity: Entity | null = null;

  loading = false;

  // Hash map of all entities
  private _hashEntities: Record<number, Entity> = {};

  get current(): Entity | null {
    return this.currentEntity;
  }

  get all(): Entity[] {
    return this.entities;
  }

  get isLoading(): boolean {
    return this.loading;
  }

  get getEntity() {
    return (id: number): Entity | null => {
      return this._hashEntities[id] || null;
    };
  }

  @Mutation SET_LOADING(loading: boolean): void {
    this.loading = loading;
  }

  @Mutation
  public SET_ENTITIES(entities: Entity[]): void {
    this._hashEntities = {};
    if (entities.length > 0) {
      const populateAllEntities = (
        ents: Entity[],
        parentId: number | undefined = undefined
      ) => {
        ents.forEach((ent) => {
          if (ent.id !== undefined) {
            ent.parent = parentId;
            this._hashEntities[ent.id] = ent;
            if (ent.children) {
              populateAllEntities(ent.children, ent.id);
            }
          }
        });
      };
      populateAllEntities(entities);
      this.entities = [...entities];
      store.commit('entities/SELECT_ENTITY', entities[0].id);
    }
  }

  @Action
  public async SET_ENTITY(data: { entityId: number; isGroup: boolean }) {
    if (data.isGroup) {
      this.context.commit('SELECT_PREVIOUS_ENTITY', data.entityId);
    }
    this.context.commit('SELECT_ENTITY', data.entityId);
  }

  @Action
  public async ROLLBACK_ENTITY() {
    this.context.dispatch('SET_ENTITY', {
      entityId: this.previousEntity,
      isGroup: true
    });
  }

  @Mutation
  public SELECT_ENTITY(entityId: number): void {
    this.currentEntity = this._hashEntities[entityId] || null;
  }

  @Mutation
  public SELECT_PREVIOUS_ENTITY(entityId: number): void {
    this.previousEntity = this._hashEntities[entityId] || null;
  }

  @Mutation
  public UPDATE_ENTITY(entity: Entity): void {
    if (entity && entity.id) {
      // eslint-disable-next-line no-param-reassign
      this._hashEntities[entity.id] = entity;
      this.entities = [
        ...(updateEntityInTree(this.entities, entity, null) || [])
      ];
    }
  }

  /**
   * Force a store refresh of the current entity object
   * for getters using current entity to detect changes in entities tree
   */
  @Mutation
  public async REFRESH_CURRENT_ENTITY(): Promise<void> {
    if (this.currentEntity && this.currentEntity.id) {
      store.commit(
        'entities/UPDATE_ENTITY',
        new Entity({
          ...this._hashEntities[this.currentEntity.id]
        })
      );
      this.currentEntity = this._hashEntities[this.currentEntity.id];
    }

    store.commit('entities/SET_STAT_LOADING', false);
  }

  /**
   * Fetch the main entity corresponding to the organisation currently selected
   */
  @Action
  async fetch(): Promise<void> {
    this.context.commit('SET_LOADING', true);
    const response = await this.entitiesClient.fetch(
      store.getters['organisations/current']
    );
    this.context.commit('SET_LOADING', false);
    if (!response || response.status !== 200) {
      console.error(response);
    } else {
      this.context.commit('SET_ENTITIES', response.data);
    }
  }

  /**
   * Save the given entity with parent, name, type and legacy type
   */
  @Action
  async save(entity: Entity): Promise<Entity | null> {
    const response = await this.entitiesClient.save(
      store.getters['organisations/current'],
      entity
    );

    if (!response || response.status !== 200) {
      displayError(response, Operation.SAVE, entity.name);
      // Refresh entity
      this.context.dispatch('fetch');
      return null;
    }
    return response.data;
  }

  /**
   * Save the entity type at given index
   */
  @Action
  async update(entity: Entity): Promise<Entity> {
    const response = await this.context.dispatch('save', entity);
    store.commit('entities/UPDATE_ENTITY', response);
    return response;
  }

  /**
   * Save the parent
   */
  @Action
  async saveParent(entity: Entity): Promise<Entity> {
    // Send parent only
    const { id, parent } = entity;
    const response = await this.context.dispatch(
      'save',
      new Entity({ id, parent })
    );
    // Refresh store with returned entity
    store.commit('entities/UPDATE_ENTITY', response);
    return response;
  }

  /**
   * Create the given entity with parent, name, type and legacy type
   */
  @Action
  async create(entity: Entity): Promise<Entity> {
    const response = await this.context.dispatch('save', entity);
    // Refresh store with returned entity
    store.commit('entities/UPDATE_ENTITY', response);
    return response || entity;
  }
}
