import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { VueAuthGuard } from '@/core/auth';
import Styleguide from '@/core/views/Styleguide.vue';

if (!process || process.env.NODE_ENV !== 'test') {
  Vue.use(VueRouter);
}
const routes: Array<RouteConfig> = [
  { path: '/', redirect: '/dashboard' },
  {
    path: '/styleguide',
    name: 'Styleguide',
    component: Styleguide,
    meta: {
      permission: {
        view: 'styleguide'
      }
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(VueAuthGuard);

export default router;
