/* eslint-disable class-methods-use-this */
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Organisation } from '@/core/store/models';
import store from '@/core/store';
import { OrganisationsClient } from '@/core/store/network';
import { OrganisationsState } from './state';

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: 'organisations'
})
export default class Organisations
  extends VuexModule
  implements OrganisationsState
{
  private organisationsClient = new OrganisationsClient();

  organisationList: Organisation[] = [];

  currentOrganisation: Organisation | null = null;

  get organisationByIdOrType() {
    return (organisation: number | Organisation): Organisation => {
      return typeof organisation === 'number'
        ? (this.organisationList.find(
            (o) => o.id === organisation
          ) as Organisation) || this.currentOrganisation
        : organisation;
    };
  }

  get current(): Organisation | null {
    return this.currentOrganisation;
  }

  get all(): Organisation[] {
    return this.organisationList.sort((a: Organisation, b: Organisation) => {
      if (b.name && a.name) {
        return a.name?.localeCompare(b.name);
      }
      return 0;
    });
  }

  @Mutation
  public SET_ORGANISATIONS(organisations: Organisation[]): void {
    // we take the first organisation we received as current
    store.commit(
      'organisations/SET_CURRENT_ORGANISATION',
      this.currentOrganisation ? this.currentOrganisation : organisations[0]
    );
    this.organisationList = organisations;
  }

  @Mutation
  public SET_CURRENT_ORGANISATION(organisation: Organisation): void {
    this.currentOrganisation = organisation;
  }

  @Action
  public SELECT_CURRENT_ORGANISATION(
    organisation: Organisation | number
  ): void {
    store.commit(
      'organisations/SET_CURRENT_ORGANISATION',
      store.getters['organisations/organisationByIdOrType'](organisation)
    );
    store.dispatch('entities/fetch');

    // Also set their types for global use
    store.dispatch('entityTypes/fetch');
  }

  /**
   * Fetch the organisations that the user has access to
   */
  @Action
  async fetch(): Promise<void> {
    const response = await this.organisationsClient.fetch();
    if (!response || response.status !== 200) {
      console.error(response);
    } else {
      this.context.commit('SET_ORGANISATIONS', response.data);
    }
  }
}
