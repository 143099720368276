<template>
  <StatItem
    :title="$t('analyse.dashboard.collect.title')"
    icon-src="@/core/assets/img/icons/ico-collect.svg"
    :button-title="$t('analyse.dashboard.collect.buttonTitle')"
    button-link="#todo"
  >
    TODO: collect key facts
  </StatItem>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import StatItem from './StatItem.vue';

@Component({ components: { StatItem } })
export default class StatItemCollect extends Vue {}
</script>

<style lang="scss" scoped></style>
