<template>
  <b-navbar type="light" sticky>
    <!-- Same width as menu -->
    <div :style="`width: ${menuWidth}px;`">
      <b-navbar-brand href="/"
        ><img src="@/core/assets/img/logo.svg" alt="Dolmen" width="100%"
      /></b-navbar-brand>
    </div>
    <!-- Left aligned nav items -->
    <b-navbar-nav class="w-25 ml-4" href="#" v-if="organisations.length > 1">
      <treeselect
        v-model="currentOrganisation"
        value-format="object"
        :multiple="false"
        :options="organisations"
        :normalizer="normalizer"
        :clearable="false"
      />
    </b-navbar-nav>
    <b-collapse id="nav-collapse" is-nav>
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto mr-3 text-primary">
        <b-nav-item-dropdown
          @hide="onDropdownHide"
          @toggle="toggle"
          toggle-class="nav-link dropdown-toggle"
          right
          ref="dropdown"
        >
          <!-- Using 'button-content' slot -->
          <template v-slot:button-content>
            <span class="user-name">{{ user.name }}</span>
          </template>
          <a class="dropdown-item" href="#" id="edit-user-profile">
            <i class="fa fa-user" aria-hidden="true" />
            {{ $t('global.authentication.profile') }}
          </a>
          <PopoverForm
            target="edit-user-profile"
            :title="$t('organisation.users.editor.popupTitle')"
            v-slot="scope"
          >
            <UserEdit
              @canceled="
                () => {
                  scope.canceled();
                  closeDropDown();
                }
              "
              @saved="
                () => {
                  scope.saved();
                  closeDropDown();
                }
              "
              :validators="validators"
              :feedback="feedback"
              :userId="user.sub"
            />
          </PopoverForm>
          <b-dropdown-item variant="danger" @click="logout">
            <i class="fa fa-sign-out-alt" aria-hidden="true" />
            {{ $t('global.authentication.logout') }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { BNavItemDropdown } from 'bootstrap-vue';
import { mixins } from 'vue-class-component';
import { email, maxLength, required } from 'vuelidate/lib/validators';
import LogoutMixin from '@/core/mixins/logoutMixin';
import PopoverForm from '@/core/components/widgets/PopoverForm.vue';
import FeedbacksMixin from '@/core/mixins/feedbacksMixin';
import UserEdit from '@/modules/organisation/views/users/UserEdit.vue';
import { Organisation } from '@/core/store/models';
import { menuWidth } from '@/conf/menu';

@Component({
  components: { PopoverForm, UserEdit }
})
export default class Header extends mixins(LogoutMixin, FeedbacksMixin) {
  isClosable = true;

  private menuWidth = menuWidth;

  get user(): Record<string, any> | null {
    return this.$store.getters['auth/currentUser'];
  }

  get currentOrganisation(): Organisation {
    return this.$store.getters['organisations/current'];
  }

  set currentOrganisation(organisation: Organisation) {
    this.$store.dispatch(
      'organisations/SELECT_CURRENT_ORGANISATION',
      organisation
    );
    const newUserPreferences =
      this.$store.getters['preferences/currentPreferences'];
    newUserPreferences.lastSelectedOrganisation = organisation.id;
    this.$store.dispatch('preferences/save', newUserPreferences);
  }

  get organisations(): Organisation[] {
    return this.$store.getters['organisations/all'];
  }

  get collapsed(): boolean {
    return this.$store.getters['app/isCollapsed'];
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  onDropdownHide(bvEvent: any): void {
    if (!this.isClosable) {
      bvEvent.preventDefault();
    }
  }

  toggle(): void {
    this.isClosable = !this.isClosable;
  }

  // eslint-disable-next-line class-methods-use-this
  private normalizer(node: Organisation) {
    return {
      id: `${node.id}`,
      label: node.name
    };
  }

  closeDropDown(): void {
    this.isClosable = true;
    (this.$refs.dropdown as BNavItemDropdown).hide(false);
  }

  private validators = {
    name: {
      required,
      maxLength: maxLength(70)
    },
    email: {
      required,
      email,
      maxLength: maxLength(70)
    }
  };

  private feedback = {
    name: (value: any) => {
      return this.genericFeedback(value);
    }
  };
}
</script>
<style lang="scss" scoped>
.navbar-nav {
  display: inline-block;
}
</style>
