/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import VueI18n, { LocaleMessages } from 'vue-i18n';
import localeMessages from '@/core/locales';

Vue.use(VueI18n);

export function loadLocaleMessages(
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  locales: any,
  namespace?: string
): LocaleMessages {
  const messages: LocaleMessages = {};
  Object.keys(locales).forEach((key: any) => {
    const locale = locales[key];
    messages[key] = namespace ? { [namespace]: locale } : locale;
  });
  return messages;
}

export default new VueI18n({
  locale:
    navigator.language.split('-')[0] || process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(localeMessages)
});
