import { AxiosResponse } from 'axios';
import getEnv from '@/core/utils/getEnv';
import ECampaignType from '@/core/enum/ECampaignType';
import ECampaignStatus from '@/core/enum/ECampaignStatus';
import { ResponseFetchPacksByEntities } from '../models/pack';
import ApiClient from './ApiClient';
import { Operation, displayError } from '../modules/apiError';
import paths from './paths';

enum EndPointFinalPath {
  GET_PACKS_BY_ENTITIES_ID = '/entity/pack'
}

export default class extends ApiClient {
  constructor() {
    super(paths.PACK_MANAGER_WS);
  }

  private baseURL = getEnv('PACKS_MANAGER_URL');

  // eslint-disable-next-line class-methods-use-this
  async fetchPacksByEntitiesId(
    entitiesId: number[],
    media: ECampaignType,
    status: ECampaignStatus,
    searchDate: Date
  ): Promise<AxiosResponse<ResponseFetchPacksByEntities>> {
    try {
      const response = await this.post(
        {},
        entitiesId,
        {
          baseURL: `${this.baseURL}`,
          params: {
            media,
            status,
            searchDate: searchDate.toLocaleDateString()
          }
        },
        EndPointFinalPath.GET_PACKS_BY_ENTITIES_ID
      );
      return response;
    } catch (error) {
      displayError(error, Operation.GET_PACKS_BY_ENTITIES_ID, 'PackClient');
      return Promise.reject(error);
    }
  }
}
