<template>
  <div class="text-right mt-2 col-12 d-inline">
    <div class="d-inline total">
      <span class="charsCount">{{ charactersCount }}</span
      >/<span
        class="dolmen-tooltip nextCharsLimit"
        :tooltip="$t('smsEditor.tools.smsCounter.tooltipNextSms')"
        >{{ nextSmsLimit }}</span
      >
    </div>
    <div class="mt-2 d-inline">
      <span class="billed-sms">
        ({{ $tc('smsEditor.tools.smsCounter.smsBilled', smsCount) }})
      </span>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SmsCounter extends Vue {
  @Prop({ type: Number, required: true }) readonly charactersCount!: number;

  @Prop({ type: Number, required: true }) readonly smsCount!: number;

  @Prop({ type: Number, required: true }) readonly nextSmsLimit!: number;
}
</script>
<style scoped>
.total,
.billed-sms {
  font-size: 15px;
  font-weight: 300;
  color: #30425c;
}
.total {
  font-weight: 700;
}

.dolmen-tooltip {
  cursor: pointer;
}

[tooltip].dolmen-tooltip:hover:after {
  font-size: 0.9rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  line-height: 1.5rem;
}
</style>
