import getEnv from '@/core/utils/getEnv';
import { AxiosResponse } from 'axios';
import { Role } from '../models';
import ApiClient from './ApiClient';
import paths from './paths';
import { Operation, displayError } from '../modules/apiError';

export default class RolesClient extends ApiClient {
  constructor() {
    super(paths.USERS_ROLES_WS);
  }

  private baseURL = getEnv('USER_MANAGER_URL');

  async fetch(): Promise<AxiosResponse<Array<Role>>> {
    try {
      const response = await this.get({}, { baseURL: `${this.baseURL}` });

      return response;
    } catch (error) {
      displayError(error, Operation.FETCH, 'Fetch Roles');
      console.error(error);
      return Promise.reject(new Error(error as string));
    }
  }
}
