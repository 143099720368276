<template>
  <b-modal
    data-cy="holiday-popup"
    :visible="true"
    id="modal-error"
    :title="title"
    @hidden="onClose"
  >
    <p class="my-4">
      <slot></slot>
    </p>
    <template #modal-footer="{ cancel }">
      <b-button size="sm" variant="danger" @click="cancel()"> OK</b-button>
    </template>
  </b-modal>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class HolidayPopup extends Vue {
  @Prop(String) title: string | undefined;

  @Prop(Function) onClose: (() => void) | undefined;
}
</script>
<style lang="scss" scoped></style>
