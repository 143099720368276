import { AbilityBuilder } from '@casl/ability';
import { NavigationGuardNext, Route } from 'vue-router';
import getEnv from '@/core/utils/getEnv';
import { Actions, AppAbility } from '@/plugins/caslability';
import { getInstance } from './wrapper';

export function VueAuthGuard(
  to: Route,
  _from: Route,
  next: NavigationGuardNext
): any {
  const authService = getInstance();

  const fn = async () => {
    // If authenticated, updated user with accessToken
    if (await authService.isAuthenticated()) {
      if (to.path === '/logout') {
        authService.logout({
          logoutParams: {
            returnTo: window.location.origin
          }
        });
        return;
      }
      // update user with accessToken that contains permissions
      authService
        .getTokenSilently({
          authorizationParams: {
            audience: getEnv('AUTH_AUDIENCE')
          }
        })
        .then((accessToken) => {
          const user = authService.getUser();
          const { can, rules } = new AbilityBuilder(AppAbility);
          // add CASL permissions
          (user.accessToken.permissions as Array<string>).forEach(
            (permission: string) => {
              const splitted = permission.split(':');
              can(splitted[0] as Actions, splitted[1]);
            }
          );
          authService.$ability.update(rules);
          authService.$store.dispatch('auth/authenticate', {
            user,
            accessToken
          });
          // Check if a permission is required for this route
          let authorized = true;
          if (to.meta?.permission) {
            authorized = Object.keys(to.meta.permission).reduce<boolean>(
              (previous, right) =>
                previous &&
                authService.$ability.can(
                  right as Actions,
                  to.meta?.permission[right]
                ),
              true
            );
          }
          next(authorized ? undefined : false);
        });
    } else {
      // else redirect to login page
      authService.loginWithRedirect({
        authorizationParams: {
          audience: getEnv('AUTH_AUDIENCE')
        },
        appState: { targetUrl: to.fullPath }
      });
    }
  };

  if (!authService.isLoading()) {
    return fn();
  }

  authService.$watch('loading', (loading: boolean) => {
    if (!loading) {
      return fn();
    }
    return null;
  });

  return null;
}

export default {};
