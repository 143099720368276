<template>
  <div class="form-group">
    <label for="msgInputContainer">
      {{ $t('smsEditor.tools.SmsBodyInput.label') }} * :</label
    >
    <div
      class="sms-input-container"
      :class="{ 'is-invalid': state === false, 'is-valid': state === true }"
    >
      <div class="row bg-light m-0 border">
        <div class="col-sm row m-1 p-0">
          <div class="col-sm float-right p-0">
            <dropdown
              class="float-right"
              @click="insertAtCursor"
              :title="$t('smsEditor.tools.SmsBodyInput.customField')"
              :items="dropdownItems"
              id="msgTag"
            ></dropdown>
          </div>
        </div>
      </div>
      <b-form-textarea
        id="msgInput"
        class="msg-textarea"
        :placeholder="$t('smsEditor.tools.SmsBodyInput.placeholder')"
        v-model="contentValue"
        @blur="selectCaret"
        @focus="selectCaret"
        @change="fulltextChange"
        ref="msgInput"
        rows="10"
        :state="state"
        :maxlength="textAreaMaxLength"
      ></b-form-textarea>
      <div class="footer">{{ footer }}</div>
    </div>
    <div class="invalid-feedback" v-if="requiredError">
      <i class="fas fa-exclamation-circle orange d-inline pr-1"> </i>
      {{ $t('smsEditor.tools.SmsBodyInput.warningEmptyField') }}
    </div>
    <div class="invalid-feedback" v-if="maxlengthError">
      <i class="fas fa-exclamation-circle orange d-inline pr-1"> </i>
      {{
        $t('smsEditor.tools.SmsBodyInput.warningFieldExceed', {
          number: maxlength
        })
      }}
    </div>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Emit,
  ModelSync,
  Prop,
  Ref,
  Vue,
  Watch
} from 'vue-property-decorator';
import { fulltextDropdownItem } from '@/core/utils/editorUtils';
import SmsCharFactory from '@/core/utils/smsUtils/SmsCharFactory';
import SmsCharUnicode from '@/core/utils/smsUtils/SmsCharUnicode';
import Dropdown from './Dropdown.vue';

enum ESubstituteType {
  PREVIEW = 'preview',
  FULLTEXT = 'fulltext'
}

@Component({ components: { Dropdown } })
export default class SmsBodyInput extends Vue {
  @ModelSync('content', 'change', { type: String })
  contentValue!: string;

  @Prop({ type: String }) readonly footer?: string;

  @Prop({ type: Number, required: true }) readonly maxlength!: number;

  @Prop({ type: Boolean }) readonly state?: boolean;

  @Prop({ type: Boolean, required: true }) readonly requiredError!: boolean;

  @Prop({ type: Boolean, required: true }) readonly maxlengthError!: boolean;

  @Prop({ type: String, required: true }) readonly charset!: string;

  @Ref('msgInput') readonly msg!: HTMLTextAreaElement;

  get isUnicode(): boolean {
    return this.charset === SmsCharUnicode.CHARSET;
  }

  dropdownItems: Array<fulltextDropdownItem> = [
    {
      label: `${this.$t('smsEditor.tools.customFields.civility')}`,
      value: 'CIV',
      preview: `${this.$t('smsEditor.tools.customFields.civilitypreview')}`,
      fulltext: 'aaa' // 3 char
    },
    {
      label: `${this.$t('smsEditor.tools.customFields.firstname')}`,
      value: 'PRENOM',
      preview: 'Jane',
      fulltext: 'aaaaaaaaaaaaaaaaaaaaaaaaa' // 25 char
    },
    {
      label: `${this.$t('smsEditor.tools.customFields.lastname')}`,
      value: 'NOM',
      preview: 'DOE',
      fulltext: 'aaaaaaaaaaaaaaaaaaaaaaaaa' // 25 char
    },
    {
      label: `${this.$t('smsEditor.tools.customFields.business')}`,
      value: 'ENSEIGNE',
      preview: 'Dolmen',
      fulltext: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaa' // 30 char
    }
  ];

  caret = {
    start: 0,
    end: 0
  };

  // manage difference between dropdownItem value (with brakets) and fulltext (ie. max sized value)
  maxlengthDelta = 0;

  get textAreaMaxLength(): number {
    return this.maxlength - this.maxlengthDelta;
  }

  mounted() {
    this.previewChange();
    this.fulltextChange(this.contentValue);
  }

  @Emit()
  @Watch('contentValue')
  previewChange() {
    return this.substitute(this.contentValue, ESubstituteType.PREVIEW);
  }

  @Emit()
  @Watch('contentValue')
  fulltextChange(contentValue: string) {
    const smsCharType = this.isUnicode
      ? SmsCharFactory.charUnicode
      : SmsCharFactory.charGSM;

    let fulltext = this.substitute(contentValue, ESubstituteType.FULLTEXT);

    // The new value is larger than the accepted length
    if (smsCharType.countCharacters(fulltext) > this.maxlength) {
      // Revert the old value if exists or truncate the new one
      const fixedValue = contentValue.slice(
        0,
        this.maxlength - smsCharType.countCharacters(fulltext)
      );

      this.$nextTick(() => {
        this.contentValue = fixedValue;
      });

      this.$emit('error', { type: 'truncate-body' });
      fulltext = this.substitute(fixedValue, ESubstituteType.FULLTEXT);
    }

    this.maxlengthDelta = fulltext.length - this.contentValue.length;
    return fulltext;
  }

  selectCaret() {
    if (this.msg !== undefined) {
      this.caret.start = this.msg.selectionStart;
      this.caret.end = this.msg.selectionEnd;
    }
  }

  insertAtCursor(customField: fulltextDropdownItem) {
    const value = `[${customField.value}]`;
    const textBeforeCaret = this.msg.value.substring(0, this.caret.start);
    const textAfterCaret = this.msg.value.substring(
      this.caret.end,
      this.msg.value.length
    );

    // Check if we need to add a space before inserting value
    // Don't work if textArea begin with space(s)
    const needSpaceBefore =
      this.caret.start > 0 && textBeforeCaret === textBeforeCaret.trim();
    const needSpaceAfter =
      textAfterCaret.length > 0 && textAfterCaret[0] !== ' ';
    const caretPosition =
      this.caret.start +
      value.length +
      (needSpaceBefore ? 1 : 0) +
      (needSpaceAfter ? 1 : 0);

    let newValue =
      textBeforeCaret +
      (needSpaceBefore ? ' ' : '') +
      value +
      (needSpaceAfter ? ' ' : '') +
      textAfterCaret;
    const newValueSubstitued = this.substitute(
      newValue,
      ESubstituteType.FULLTEXT
    );

    // Truncate text if longer the accepted value
    if (newValueSubstitued.length > this.maxlength) {
      const nbCharToRemove = newValueSubstitued.length - this.maxlength;
      if (textAfterCaret.length < nbCharToRemove) {
        this.$emit('error', { type: 'custom-field-insertion' });
        this.msg.focus();
        return;
      }
      newValue = newValue.substring(0, newValue.length - nbCharToRemove);
      this.$emit('error', { type: 'truncate-body' });
    }

    this.$nextTick(() => {
      this.msg.setSelectionRange(caretPosition, caretPosition);
      this.msg.focus();
    });

    this.contentValue = newValue;
    this.caret.start = caretPosition;
    this.caret.end = caretPosition;
  }

  /**
   * manage dropdownItem substitution depending on the type (preview or fulltext)
   * The specific chars < will be replaced by &lt for not being interpreted by the preview
   * The specific chars & will be replaced by &amp for not being interpreted by the preview
   */
  substitute(text: string | undefined, type: ESubstituteType): string {
    if (text === undefined) {
      return '';
    }

    const substituedText = text.replace(/\[([^\]]+)\]/gi, (s, entry) => {
      const match = this.dropdownItems.filter((item) => item.value === entry);
      if (match.length > 0) {
        return match[0][type];
      }
      return `[${entry}]`;
    });

    if (type === ESubstituteType.PREVIEW) {
      return `${substituedText
        .replace(/&/gi, '&amp')
        .replace(/</gi, '&lt')
        .split('\n')
        .join('</br>')}`;
    }

    return substituedText;
  }
}
</script>

<style scoped>
textarea {
  resize: none;
}

.msg-textarea:focus {
  box-shadow: none !important;
}

.msg-textarea {
  color: #30425c;
  font-size: 15px;
  font-weight: 300;
}

.sms-input-container {
  border: solid 1px #dde1e8;
  border-radius: 3px;
}

.sms-input-container textarea {
  border: none;
}

.sms-input-container .navbar-text {
  font-size: 13px;
  font-weight: 500;
  color: #30425c;
}

.sms-input-container.is-valid {
  border-color: #33a02c;
}

.sms-input-container.is-invalid {
  border-color: #f98844;
}

.footer {
  padding: 0.375rem 0.75rem;
  color: #30425c;
  opacity: 0.5;
  font-size: 15px;
  font-weight: 500;
  border-top: solid 1px #dde1e8;
}
</style>
