import {
  VuexModule,
  Module,
  Action,
  Mutation,
  MutationAction
} from 'vuex-module-decorators';
import { displayError, Operation } from '@/core/store/modules/apiError';
import ESmsType from '@/core/enum/ESmsType';
import ECampaignStatus from '@/core/enum/ECampaignStatus';
import store from '@/core/store';
import {
  ActivationCampaignClient,
  PacksManagerClient,
  SmsEditorClient
} from '@/core/store/network';
import { i18n } from '@/plugins';
import {
  TargetedContact,
  CampaignCreateTargetRequest,
  SendTargetedContacts,
  CampaignDTO,
  CreateCampaignRequest,
  UpdateCampaignRequest
} from '@/core/store/models/activationCampaign';
import { Pack } from '@/core/store/models/pack';
import { SmsEditorEntityOptions } from '@/core/store/models/smsEditor';
import ESmsSaveStatus from '@/core/enum/ESmsSaveStatus';
import EPageComponentType from '@/core/enum/EPageComponentType';
import { getSmsRoundedPrice } from '@/core/utils/smsUtils';
import moment from 'moment';
import TARGETING_HANDLER_DATE_FORMAT from '@/core/utils/dateFormat';
import { EstimationsData } from './model/packEstimations';
import CommunicationService from '../service/CommunicationService';
import SmsMapperService from '../service/SmsMapperService';
import ActivationCampaignType from './model/ActivationCampaignType';
import { SmsMediaType } from './model/SmsMediaType';
import { DatatableParamsType, CommunicationState, SubCampaign } from './state';

interface entityParams {
  entityId: number;
}

interface searchCampaignsParams extends entityParams {
  query: string;
}

interface fetchCampaignsParams extends entityParams {
  event: DatatableParamsType;
}

interface fetchCampaignParams {
  campaignId: string;
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: 'communication'
})
export default class Communication
  extends VuexModule
  implements CommunicationState
{
  private activationCampaignClient = new ActivationCampaignClient();

  private communicationService = new CommunicationService();

  private packsManagerClient = new PacksManagerClient();

  private smsEditorClient = new SmsEditorClient();

  private smsMapperService = new SmsMapperService();

  campaignLoading = true;

  campaigns: Array<CampaignDTO> = [];

  campaignsCount = 0;

  paginationParams: DatatableParamsType = {
    filters: {},
    first: 0,
    page: 0,
    pageCount: 0,
    rows: 10,
    sortField: 'creationDate',
    sortOrder: -1
  };

  searchQuery = '';

  subCampaignLoading = false;

  subCampaigns: Array<SubCampaign> = [];

  currentCampaign: ActivationCampaignType = new ActivationCampaignType();

  isEditMode = false;

  entityOptions: SmsEditorEntityOptions = {
    prices: [],
    defaultTimezone: '',
    timeZones: []
  };

  // Map that links an entity to its pack
  packsByEntitiesId: Record<number, Pack[]> = [];

  /*
    Used in getPossibleContactForCampaigns(), send default value for the moment
    because we can't know this network's informations nowadays
  */
  allowedPhonePrefixes = ['+33'];

  domTomAllowed = false;
  /// /

  // Contacts received from BackEnd
  possibleContactForCampaigns: TargetedContact[] = [];

  // Object to stocks/manipulate contacts before sending at the end of StepContacts
  targetedContacts: TargetedContact[] = [];

  // Save this information during StepContact to re-use it in StepConfirm
  contactsByEntities: SendTargetedContacts[] = [];

  // To stock data for StepConfirm
  estimationsData: EstimationsData = {
    included: [],
    excluded: [],
    totalIncluded: 0,
    totalExcluded: 0,
    totalSendMessage: 0,
    totalCost: 0,
    hasPack: false
  };

  smsSaveStatus: ESmsSaveStatus | null = null;

  isSmsSet = false;

  requestFailed = false;

  get targetFailed(): boolean {
    return this.requestFailed;
  }

  // Because use of implicit store getter are not effective
  get editMode(): boolean {
    return this.isEditMode;
  }

  get targetedContactFilteredLength(): number {
    const filteredTargetedContacts = this.communicationService.filterContacts(
      this.possibleContactForCampaigns,
      this.currentCampaign.filter
    );

    store.commit(
      'communication/updateTargetedContacts',
      filteredTargetedContacts
    );

    return this.targetedContacts.length;
  }

  get hasEntityOptions(): boolean {
    return (
      this.entityOptions.prices.length > 0 &&
      this.entityOptions.timeZones.length > 0 &&
      !!this.entityOptions.defaultTimezone
    );
  }

  get smsType(): ESmsType | undefined {
    return this.isSmsSet ? this.currentCampaign.media.smsMedia.type : undefined;
  }

  @MutationAction({
    mutate: ['estimationsData']
  })
  public async estimatePacksByEntities() {
    try {
      // init 'estimate' to be able to used it outside await/then()
      let estimated = this.estimationsData;

      // fetch all packs from entites to estimate the campaigns
      await this.context.dispatch('fetchPacksByEntitiesId');
      estimated = this.communicationService.calculateEstimations(
        this.contactsByEntities,
        this.currentCampaign.media.smsMedia?.count || 1,
        this.packsByEntitiesId
      );

      const priceBySMSType = this.communicationService.findPriceBySMSType(
        this.currentCampaign.media.smsMedia?.type || ESmsType.SIMPLE,
        this.entityOptions.prices
      );

      if (priceBySMSType) {
        estimated.totalCost = getSmsRoundedPrice(
          priceBySMSType.national,
          estimated.totalExcluded
        );
      } else {
        throw new DOMException();
      }
      return {
        estimationsData: estimated
      };
    } catch (error) {
      displayError(error, Operation.PRICES_PROCESS, 'CommunicationStore');
      return {
        estimationsData: this.estimationsData
      };
    }
  }

  @Mutation updateContactsByEntities(value: SendTargetedContacts[]) {
    this.contactsByEntities = value;
  }

  @Mutation updateTargetedContacts(value: TargetedContact[]) {
    this.targetedContacts = value;
  }

  @Mutation updateBirthMonth(value: number) {
    this.currentCampaign.filter.birthMonth = value;
  }

  @Mutation updateLocalisation(value: boolean) {
    this.currentCampaign.filter.localisation = value;
  }

  @Mutation updateAgeRange(value: number[]) {
    this.currentCampaign.filter.ageRange = value;
  }

  @Mutation updateGender(value: number) {
    this.currentCampaign.filter.gender = value;
  }

  @Mutation SET_CAMPAIGN_LOADING(loading: boolean): void {
    this.campaignLoading = loading;
  }

  @Mutation SET_SEARCH_QUERY(query: string): void {
    this.searchQuery = query;
  }

  @Mutation SET_SUB_CAMPAIGN_LOADING(loading: boolean): void {
    this.subCampaignLoading = loading;
  }

  @Mutation SET_CURRENT_CAMPAIGN(campaign: ActivationCampaignType) {
    this.currentCampaign = campaign;
  }

  @Mutation SET_IS_SMS_SET(value: boolean) {
    this.isSmsSet = value;
  }

  @Mutation SET_EDITMODE(value: boolean) {
    this.isEditMode = value;
  }

  @Mutation updateCampaignName(name: string) {
    this.currentCampaign.name = name;
  }

  @Mutation updateCampaignSubEntities(subEntities: Array<number>) {
    this.currentCampaign.subEntities = subEntities;
  }

  @MutationAction({
    mutate: ['possibleContactForCampaigns', 'requestFailed']
  })
  public async getPossibleContactForCampaigns() {
    try {
      if (this.currentCampaign.id) {
        const startOfWeekDate = moment()
          .startOf('isoWeek')
          .format(TARGETING_HANDLER_DATE_FORMAT);
        const endOfWeekDate = moment()
          .endOf('isoWeek')
          .format(TARGETING_HANDLER_DATE_FORMAT);

        const response =
          await this.activationCampaignClient.fetchPossibleContactsByCampaignId(
            this.currentCampaign.id.toString(),
            {
              allowedPhonePrefixes: this.allowedPhonePrefixes,
              domTomAllowed: this.domTomAllowed,
              availableMinDate: startOfWeekDate,
              availableMaxDate: endOfWeekDate
            }
          );
        return {
          possibleContactForCampaigns: response.data,
          requestFailed: false
        };
      }
      return {
        possibleContactForCampaigns: [],
        requestFailed: true
      };
    } catch (error) {
      console.log(error);
      return {
        possibleContactForCampaigns: [],
        requestFailed: true
      };
    }
  }

  @MutationAction({
    mutate: ['campaigns', 'campaignsCount', 'paginationParams']
  })
  public async fetchCampaigns(extra: fetchCampaignsParams) {
    try {
      // turn on the loader
      this.context.commit('SET_CAMPAIGN_LOADING', true);

      // merge partial input params to exisiting params
      const params = { ...this.paginationParams, ...extra.event };

      // manage parameters value
      let sortParams: string | null = null;
      if (params.sortField) {
        sortParams = params.sortField;
      }
      if (params.sortOrder) {
        sortParams = [sortParams, params.sortOrder < 0 ? 'desc' : 'asc'].join(
          ','
        );
      }

      // fetch from API
      const response =
        await this.activationCampaignClient.getCampaignsByEntityId(
          extra.entityId,
          params.page,
          params.rows,
          sortParams,
          this.searchQuery.length > 0 ? this.searchQuery : null
        );

      // return value to mutate state
      return {
        campaigns: response.data,
        campaignsCount: Number(response.headers['x-total-count']) || 0,
        paginationParams: params
      };
    } catch (error) {
      // log error and return empty value for state mutation
      console.error(error);
      return {
        campaigns: [],
        campaignsCount: 0,
        paginationParams: this.paginationParams
      };
    } finally {
      // turn off the loader
      this.context.commit('SET_CAMPAIGN_LOADING', false);
    }
  }

  @MutationAction({ mutate: ['subCampaigns'] })
  public async fetchSubCampaigns(extra: fetchCampaignParams) {
    try {
      // turn on the loader
      this.context.commit('SET_SUB_CAMPAIGN_LOADING', true);

      // fetch from API
      const response =
        await this.activationCampaignClient.getSubCampaignsByCpId(
          extra.campaignId
        );
      // return value to mutate state
      const subCampaignList = response.data
        // look for entity
        .map((entityId) => {
          return this.communicationService.findEntity(
            entityId,
            this.context.rootState.entities.entities
          );
        })
        // look for all leaf in entity tree
        .map((entity) => {
          if (entity != null) {
            return this.communicationService.findLeaves(entity);
          }
          return entity;
        })
        // map not found entity to an unknown
        .map((entity) => {
          if (entity == null) {
            return {
              name: i18n.t('communication.campaign.sub-campaigns-modal.unknown')
            };
          }
          return entity;
        });
      return {
        subCampaigns: Object.values(subCampaignList).flat()
      };
    } catch (error) {
      // log error and return empty value for state mutation
      console.error(error);
      return {
        subCampaigns: []
      };
    } finally {
      // turn off the loader
      this.context.commit('SET_SUB_CAMPAIGN_LOADING', false);
    }
  }

  @MutationAction({ mutate: ['currentCampaign', 'isSmsSet'] })
  public async fetchCampaign(extra: fetchCampaignParams) {
    await this.context.dispatch('clearCampaign');
    try {
      const campaignResponse =
        await this.activationCampaignClient.getCampaignInfoByid(
          extra.campaignId
        );
      const subCampaignResponse =
        await this.activationCampaignClient.getSubCampaignsByCpId(
          extra.campaignId
        );

      let targetFilter;
      if (campaignResponse.data.filter !== undefined) {
        targetFilter = campaignResponse.data.filter;
      } else {
        targetFilter = {
          birthMonth: -1,
          ageRange: [0, 100],
          localisation: true,
          gender: -1
        };
      }
      return {
        currentCampaign: {
          ...this.currentCampaign,
          id: extra.campaignId,
          name: campaignResponse.data.name,
          externalId: campaignResponse.data.externalId,
          subEntities: subCampaignResponse.data,
          filter: targetFilter,
          media: {
            ...this.currentCampaign.media,
            smsMedia: {
              ...this.currentCampaign.media.smsMedia,
              type: campaignResponse.data.mediaType
            }
          }
        },
        isSmsSet: this.isSmsSet
      };
    } catch (error) {
      // log error and return empty value for state mutation
      console.error(error);
      return {
        currentCampaign: new ActivationCampaignType(),
        isSmsSet: false
      };
    }
  }

  @MutationAction({ mutate: ['currentCampaign'] })
  public async createCampaign(extra: entityParams) {
    const request: CreateCampaignRequest = {
      name: this.currentCampaign.name,
      subEntities: this.communicationService.findLeavesId(
        this.currentCampaign.subEntities,
        store.state.entities.entities
      )
    };

    const updateResponse =
      await this.activationCampaignClient.createCampaignByEntityId(
        extra.entityId,
        request
      );

    return {
      currentCampaign: {
        id: updateResponse.data.id,
        name: updateResponse.data.name,
        externalId: updateResponse.data.externalId,
        filter: {
          birthMonth: -1,
          ageRange: [0, 100],
          localisation: true,
          gender: -1
        }
      }
    };
  }

  @MutationAction({ mutate: ['packsByEntitiesId'] })
  public async fetchPacksByEntitiesId() {
    try {
      const todayDate = new Date();

      const response = await this.packsManagerClient.fetchPacksByEntitiesId(
        this.currentCampaign.subEntities,
        this.currentCampaign.media.mediaType,
        ECampaignStatus.IN_PROGRESS,
        todayDate
      );
      return {
        packsByEntitiesId: response.data
      };
    } catch (error) {
      // log error and return empty value for state mutation
      console.log(error);
      return {
        packsByEntitiesId: new Map()
      };
    }
  }

  @MutationAction({ mutate: ['entityOptions'] })
  public async getEntityConfiguration() {
    try {
      const response = await this.smsEditorClient.getConfByCampaignId(
        this.currentCampaign.externalId
      );

      const pricesMapped = this.communicationService.createPricesFromRequest(
        response.data.prices
      );

      return {
        entityOptions: {
          prices: pricesMapped,
          defaultTimezone: response.data.networkOptions.defaultTimezone,
          timeZones: response.data.networkOptions.timeZones
        }
      };
    } catch {
      return {
        entityOptions: {
          prices: [],
          defaultTimezone: '',
          timeZones: []
        }
      };
    }
  }

  @Action
  /* eslint-disable-next-line class-methods-use-this */
  public initialize(): void {
    console.log('Initializing Communication');
  }

  @Action
  public async searchCampaigns(extra: searchCampaignsParams) {
    this.context.commit('SET_SEARCH_QUERY', extra.query);
    await this.context.dispatch('fetchCampaigns', { entityId: extra.entityId });
  }

  @Action
  public async updateCampaign() {
    if (this.currentCampaign.id !== null) {
      const request: UpdateCampaignRequest = {
        name: this.currentCampaign.name,
        filter: this.currentCampaign.filter,
        subEntities: this.communicationService.findLeavesId(
          this.currentCampaign.subEntities,
          store.state.entities.entities
        ),
        mediaType: this.currentCampaign.media.smsMedia.type
      };

      await this.activationCampaignClient.saveCampaign(
        this.currentCampaign.id,
        request
      );
    }
  }

  @Action
  public async sendCampaign() {
    if (this.currentCampaign.id !== null) {
      await this.activationCampaignClient.sendCampaignById(
        this.currentCampaign.id
      );
    }
  }

  @MutationAction({ mutate: ['contactsByEntities'] })
  public async sendTargetedContacts() {
    if (this.currentCampaign.id) {
      const splitContactsByEntity: SendTargetedContacts[] =
        this.communicationService.splitTargetedContactsByEntities(
          this.targetedContacts,
          this.currentCampaign.subEntities
        );

      const startOfWeekDate = moment()
        .startOf('isoWeek')
        .format(TARGETING_HANDLER_DATE_FORMAT);
      const endOfWeekDate = moment()
        .endOf('isoWeek')
        .format(TARGETING_HANDLER_DATE_FORMAT);

      const sendContacts: CampaignCreateTargetRequest = {
        campaigns: splitContactsByEntity,
        availableMinDate: startOfWeekDate,
        availableMaxDate: endOfWeekDate
      };
      await this.activationCampaignClient.sendTargetedContactsByCampaignId(
        this.currentCampaign.id.toString(),
        sendContacts
      );
      return {
        contactsByEntities: splitContactsByEntity
      };
    }
    return {
      contactsByEntities: []
    };
  }

  @Action
  public clearCampaign() {
    this.context.commit('SET_CURRENT_CAMPAIGN', new ActivationCampaignType());
    this.context.commit('SET_IS_SMS_SET', false);
  }

  @Action
  public async clearFilters() {
    this.context.commit('updateAgeRange', [0, 100]);
    this.context.commit('updateGender', -1);
    this.context.commit('updateBirthMonth', -1);
  }

  @Mutation
  public updateSmsType(type: ESmsType) {
    this.currentCampaign.media.smsMedia.type = type;
    this.isSmsSet = true;
  }

  @MutationAction({ mutate: ['currentCampaign', 'isSmsSet'] })
  public async updateSms(extra: SmsMediaType) {
    const sms = this.smsMapperService.toApiSms(extra);

    this.smsEditorClient.updateSms(
      this.currentCampaign.externalId,
      sms,
      (status: ESmsSaveStatus) => {
        this.context.commit('updateSaveStatus', status);
      }
    );

    return {
      currentCampaign: {
        ...this.currentCampaign,
        media: {
          mediaType: this.currentCampaign.media.mediaType,
          smsMedia: extra
        }
      },
      isSmsSet: true
    };
  }

  @MutationAction({ mutate: ['currentCampaign', 'isSmsSet'] })
  public async fetchSms() {
    const response = await this.smsEditorClient.getSms(
      this.currentCampaign.externalId
    );

    if ('sms' in response.data) {
      return {
        currentCampaign: {
          ...this.currentCampaign,
          media: {
            mediaType: this.currentCampaign.media.mediaType,
            smsMedia: this.smsMapperService.toStoreSms(response.data)
          }
        },
        isSmsSet: true
      };
    }

    return {
      currentCampaign: this.currentCampaign,
      isSmsSet: false
    };
  }

  @Mutation
  public updateSaveStatus(status: ESmsSaveStatus) {
    this.smsSaveStatus = status;
  }

  @MutationAction({ mutate: ['currentCampaign'] })
  public async uploadImage(extra: { index: number; image: File }) {
    try {
      const response = await this.smsEditorClient.uploadImage(
        this.currentCampaign.externalId,
        extra.image
      );
      const campaign = { ...this.currentCampaign };
      campaign.media.smsMedia.page.components[extra.index] = {
        type: EPageComponentType.IMAGE,
        url: response.data.imageURL,
        fid: response.data.imageFID
      };
      return {
        currentCampaign: campaign
      };
    } catch (error) {
      return {
        currentCampaign: this.currentCampaign
      };
    }
  }

  @Action
  public async sendSmsTest(phoneNumber: string) {
    await this.smsEditorClient.sendSmsTest(
      this.currentCampaign.externalId,
      phoneNumber
    );
  }
}
