import gql from 'graphql-tag';

export default gql`
  query getEntityKeyCommunication(
    $input: EntityInput!
    $children: [ID]
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    getCommunicationStat(input: $input, populateFor: $children) {
      communication(startDate: $startDate, endDate: $endDate) {
        transmittedMessage {
          sms {
            carbonImpact
            count
          }
          email {
            carbonImpact
            count
          }
          print {
            carbonImpact
            count
          }
        }
        campaignStats {
          sms {
            total
            model {
              percent
            }
          }
          email {
            total
            model {
              percent
            }
          }
          print {
            total
            model {
              percent
            }
          }
        }
      }
    }
  }
`;
