<template src="./Cgu.html"></template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class Cgu extends Vue {}
</script>
<style lang="scss">
.x {
  margin-top: 2rem;
}
.x_y {
  text-align: center;
  margin-top: 1rem;
}
.x_y_z {
  margin-top: 0.5rem;
}
.x_y_z_a {
  margin-top: 0.5rem;
}
</style>
