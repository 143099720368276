/* eslint-disable class-methods-use-this */
import { Store } from 'vuex';
import ApolloClient from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import 'cross-fetch/polyfill';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import RootState from '@/core/store/modules/rootState';
import typeDefs from './typeDefs';

let instance: ApolloClient<any>;
export default class ApolloAbstractClient {
  private store: Store<RootState>;

  private defaultOptions = {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore'
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all'
    },
    addTypename: false
  };

  public constructor(
    baseUrl: string,
    store: Store<RootState>,
    aditionnalOptions?: Record<string, unknown>
  ) {
    if (instance !== undefined) {
      throw new Error(
        '(error): Instantiation failed: ApolloClient already instanciated'
      );
    }
    this.store = store;

    const httpLink = new HttpLink({
      uri: `${baseUrl}/graphql`
    });

    const authLink = setContext((_, { headers }) => {
      // get the authentication token from local storage if it exists
      const token = this.store.getters['auth/token'];
      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : ''
        }
      };
    });

    const cache = aditionnalOptions?.cache
      ? (aditionnalOptions.cache as InMemoryCache)
      : new InMemoryCache();
    instance = new ApolloClient({
      link: authLink.concat(httpLink),
      typeDefs,
      cache,
      connectToDevTools: process.env.NODE_ENV !== 'production',
      ...this.defaultOptions
    });
  }

  public getInstance(): ApolloClient<any> {
    if (instance === undefined) {
      throw new Error(
        '(error): Get instance failed: ApolloClient not yet instanciated'
      );
    }
    return instance;
  }
}
