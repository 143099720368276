import { render, staticRenderFns } from "./SmsInfoMessage.vue?vue&type=template&id=3fae83f0"
import script from "./SmsInfoMessage.vue?vue&type=script&lang=ts"
export * from "./SmsInfoMessage.vue?vue&type=script&lang=ts"
import style0 from "./SmsInfoMessage.vue?vue&type=style&index=0&id=3fae83f0&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports