// Centralized configuration for main menu items order
export const menuWidth = 250;
export const menuWidthCollapsed = 50;
export default [
  'home',
  'analyse',
  'communication',
  'collect',
  'contacts',
  'training',
  'organisation',
  'styleguide'
];
