import { getInstance } from '@/core/auth/wrapper';
import { VIEW, STYLEGUIDE } from '@/conf/permissions';
import i18n from '@/plugins/i18n';

export default {
  name: 'styleguide',
  createMenu: (): any => {
    const authService = getInstance();
    return {
      icon: 'fas fa-palette',
      href: '/styleguide',
      title: i18n.t('menu.styleguide'),
      hidden: !authService || !authService.$ability.can(VIEW, STYLEGUIDE)
    };
  }
};
