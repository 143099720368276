import { VuexModule, Module, Mutation } from 'vuex-module-decorators';
import store from '@/core/store';
import { SubheaderState, ComponentState } from './state';

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: 'app/subheader'
})
export default class Subheader extends VuexModule implements SubheaderState {
  displayed = true;

  componentList: ComponentState[] = [];

  get isDisplayed(): boolean {
    return this.displayed;
  }

  get components(): ComponentState[] {
    return this.componentList;
  }

  get getValue() {
    return (id: string, key: string): any => {
      const component = this.componentList.find((c) => c.id === id);
      return component ? component.values[key] : null;
    };
  }

  @Mutation
  public DISPLAY(displayed: boolean): void {
    this.displayed = displayed;
  }

  @Mutation
  public ADD_COMPONENT(component: ComponentState): void {
    if (!this.componentList.some((c) => c.id === component.id)) {
      this.componentList.push({ ...component, values: {} });
    }
  }

  @Mutation
  public CLEAR_COMPONENTS(): void {
    this.componentList = [];
  }

  @Mutation
  public SET_COMPONENT_VALUES(record: {
    id: string;
    key: string;
    value: never;
  }): void {
    const component = this.componentList.find((c) => c.id === record.id);
    if (component) {
      component.values = { ...component.values, [record.key]: record.value };
    }
  }
}
