import { Store } from 'vuex';
import { getModule } from 'vuex-module-decorators';
import Communication from '@/modules/communication/store';
import Auth from './auth/auth';
import Application from './application/application';
import EntityTypes from './entityTypes/entityTypes';
import Entities from './entities/entities';
import Organisations from './organisations/organisations';
import RootState from './rootState';
import Subheader from './application/subheader/subheader';
import Users from './users/users';
import Roles from './roles/roles';
import Preferences from './preferences/preferences';

const loadModule = (store: Store<RootState>): void => {
  getModule(Auth, store);
  getModule(Application, store);
  getModule(Subheader, store);
  getModule(EntityTypes, store);
  getModule(Entities, store);
  getModule(Organisations, store);
  getModule(Users, store);
  getModule(Roles, store);
  getModule(Preferences, store);
  getModule(Communication, store);
};

export default loadModule;
