/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { LINE_BREAK_LENGTH, SMS_MAX } from '.';
import SmsCharType from './SmsCharType';
import SmsCharUnicode from './SmsCharUnicode';
import SmsMode from './SmsMode';

export default class SmsModeStandard implements SmsMode {
  private smsCharType: SmsCharType;

  constructor(smsCharType: SmsCharType) {
    this.smsCharType = smsCharType;
  }

  getSmsMaxUnitLength(): number {
    return this.smsCharType.getSmsMaxUnitLength();
  }

  setSmsCharType(smsCharType: SmsCharType): void {
    this.smsCharType = smsCharType;
  }

  getCharset(): string {
    return this.smsCharType.getCharset();
  }

  countCharacters(text: string): number {
    return this.smsCharType.countCharacters(text);
  }

  countSms(contentLength: number): number {
    return this.smsCharType.countSms(contentLength);
  }

  countNextSmsLimit(smsCount: number): number {
    return this.smsCharType.countNextSmsLimit(smsCount);
  }

  computeMessageLimit(): number {
    return SMS_MAX * this.smsCharType.getSmsMaxUnitLength();
  }

  computeBodyLimit(
    footer: string,
    linkTitle: string | undefined,
    linkHref: string | undefined
  ): number {
    return this.computeMessageLimit() - LINE_BREAK_LENGTH - footer.length;
  }

  computeLinkTitleLimit(
    fulltextValue: string,
    footer: string,
    linkHref: string | undefined
  ): number {
    return 0;
  }
}
