<template>
  <div>
    <StatItemActivity
      :title="$t('analyse.dashboard.activityCommunication.title')"
      :buttonTitle="$t('analyse.dashboard.activityCommunication.buttonTitle')"
      icon-src="@/core/assets/img/icons/ico-com.svg"
      :percentage="percentage"
      :value="value"
      :loading="statLoading"
      :activityStatError="statError"
      :tooltipPercentage="
        $t('analyse.dashboard.activityCommunication.tooltipPercentage')
      "
      :tooltipValue="$t('analyse.dashboard.activityCommunication.tooltipValue')"
      button-link="/analyse/communication"
    />
  </div>
</template>

<script lang="ts">
import 'vue-apollo';
import { mixins } from 'vue-class-component';
import { READ, STATISTICS } from '@/conf/permissions';
import { Component } from 'vue-property-decorator';
import CommunicationActivityStats from '@/modules/analyse/store/models/statistics/communicationActivityStats';
import StatsQueryMixin from '../mixins/statsQueryMixin';
import StatItemActivity from './StatItemActivity.vue';
import { getCommunicationActivity } from '../store/network/graphql/queries';

@Component({
  components: { StatItemActivity },
  apollo: {
    getCommunicationActivity: {
      query: getCommunicationActivity,
      variables() {
        return {
          input: this.entityInput,
          minimum: 0,
          startDate: this.dates.from,
          endDate: this.dates.to
        };
      },
      skip() {
        return (
          !this.dates ||
          !this.dates.from ||
          !this.dates.to ||
          !this.$can(READ, STATISTICS)
        );
      },
      error(error) {
        this.error = error;
      }
    }
  }
})
export default class StatItemActivityCommunication extends mixins(
  StatsQueryMixin
) {
  private getCommunicationActivity: CommunicationActivityStats =
    new CommunicationActivityStats();

  mounted(): void {
    this.queryName = 'getCommunicationActivity';
  }

  get percentage(): number {
    return this.getCommunicationActivity?.active?.percent ?? -1;
  }

  // eslint-disable-next-line class-methods-use-this
  get value(): number {
    return this.getCommunicationActivity?.active?.count ?? -1;
  }
}
</script>

<style lang="scss" scoped></style>
