import i18n from '@/plugins/i18n';
import getPilotLink from '@/core/menu/main/getPilotLink';

export default {
  name: 'contacts',
  createMenu: (): any => {
    return {
      href: getPilotLink('contacts/stats'),
      icon: 'fas fa-database',
      title: i18n.t('contacts.menu.title'),
      external: true,
      attributes: {
        target: '_blank'
      }
    };
  }
};
