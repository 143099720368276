var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"communication"},[_c('b-container',{staticClass:"mt-4 mb-4"},[_c('b-row',[_c('b-col',[_c('CommunicationStatItem',{attrs:{"loading":_vm.statLoading,"statError":_vm.statError,"title":_vm.$t('analyse.communication.stats.sms.title'),"icon-src":require("@/core/assets/img/icons/ico-phone-message.svg"),"channelStat":_vm.thousandSeparator(_vm.values.sms.campaigns),"channelDescription":_vm.$t('analyse.communication.stats.sms.channelDescription'),"campaignNumberTooltip":_vm.$t('analyse.communication.stats.indicators.sms.campaign.tooltip'),"sentStat":_vm.thousandSeparator(_vm.values.sms.messages),"sentDescription":_vm.$t('analyse.communication.stats.sms.sentDescription'),"numberSentTooltip":_vm.$t('analyse.communication.stats.indicators.sms.volume.tooltip'),"percentileStat":_vm.values.sms.models,"percentileDescription":_vm.$t('analyse.communication.stats.common.templateUsedDescription'),"percentageModelTooltip":_vm.$t(
              'analyse.communication.stats.indicators.sms.percentage.tooltip'
            ),"sentCarbonImpact":_vm.$t('analyse.communication.stats.sms.sentCarbonImpactFirstHalf') +
            _vm.carbonImpactBuilder(_vm.values.sms.carbonImpact) +
            _vm.$t('analyse.communication.stats.sms.sentCarbonImpactSecondHalf'),"carbonImpactDifferenceFirstHalf":_vm.$t(
              'analyse.communication.stats.sms.carbonImpactDifferenceFirstHalf'
            ),"carbonImpactDifferenceHighlight":_vm.p.SMS,"carbonImpactDifferenceSecondHalf":_vm.$t(
              'analyse.communication.stats.sms.carbonImpactDifferenceSecondHalf'
            )}})],1),_c('b-col',[_c('CommunicationStatItem',{attrs:{"loading":_vm.statLoading,"statError":_vm.statError,"title":_vm.$t('analyse.communication.stats.email.title'),"icon-src":require("@/core/assets/img/icons/ico-email.svg"),"channelStat":_vm.thousandSeparator(_vm.values.email.campaigns),"channelDescription":_vm.$t('analyse.communication.stats.email.channelDescription'),"campaignNumberTooltip":_vm.$t(
              'analyse.communication.stats.indicators.email.campaign.tooltip'
            ),"sentStat":_vm.thousandSeparator(_vm.values.email.messages),"sentDescription":_vm.$t('analyse.communication.stats.email.sentDescription'),"numberSentTooltip":_vm.$t('analyse.communication.stats.indicators.email.volume.tooltip'),"percentileStat":_vm.values.email.models,"percentileDescription":_vm.$t('analyse.communication.stats.common.templateUsedDescription'),"percentageModelTooltip":_vm.$t(
              'analyse.communication.stats.indicators.email.percentage.tooltip'
            ),"sentCarbonImpact":_vm.$t(
              'analyse.communication.stats.email.sentCarbonImpactFirstHalf'
            ) +
            _vm.carbonImpactBuilder(_vm.values.email.carbonImpact) +
            _vm.$t('analyse.communication.stats.email.sentCarbonImpactSecondHalf'),"carbonImpactDifferenceFirstHalf":_vm.$t(
              'analyse.communication.stats.email.carbonImpactDifferenceFirstHalf'
            ),"carbonImpactDifferenceSecondHalf":_vm.$t(
              'analyse.communication.stats.email.carbonImpactDifferenceSecondHalf'
            )}})],1)],1),_c('b-row',{staticClass:"mt-4"},[_c('b-col',[_c('CommunicationGraph')],1)],1),_c('b-row',{staticClass:"mt-4"},[_c('b-col',[_c('CommunicationDetails')],1)],1),_c('b-row',{staticClass:"mt-4"},[_c('b-col',[(false)?_c('CommunicationMap'):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }