<template>
  <b-container v-if="!!entity">
    <div v-if="$can(p.VIEW, p.ENTITYID) && entity.id">
      {{ $t('organisation.entities.editor.idLabel') + ' - ' + entity.id }}
    </div>
    <b-form>
      <b-form-row>
        <b-form-group
          :label="$t('organisation.entities.editor.nameLabel')"
          label-for="create-entity-name"
          :invalid-feedback="feedback.name($v.entity.name)"
          :state="validateState('name')"
        >
          <b-form-input
            autofocus
            id="create-entity-name"
            :placeholder="$t('organisation.entities.editor.namePlaceHolder')"
            v-model.trim="$v.entity.name.$model"
            :state="validateState('name')"
            :disabled="isSaving"
          />
        </b-form-group>
      </b-form-row>
      <b-form-row>
        <b-form-group
          :label="$t('organisation.entities.editor.typeLabel')"
          label-for="create-entity-type"
          :invalid-feedback="feedback.type($v.entity.type)"
          :state="validateState('type')"
        >
          <treeselect
            id="create-entity-type"
            v-model="$v.entity.type.$model"
            :disabled="isSaving"
            :multiple="false"
            :options="types"
            :clearable="false"
            :placeholder="$t('organisation.entities.editor.typePlaceHolder')"
            :class="validClass"
          />
        </b-form-group>
      </b-form-row>
      <b-form-row>
        <b-button-toolbar>
          <b-button
            class="mr-2"
            variant="primary"
            :disabled="isInvalid || isSaving"
            @click="confirm"
          >
            <b-spinner v-if="isSaving" small></b-spinner>
            <span v-else>{{ $t('global.actions.save') }}</span>
          </b-button>
          <b-button :disabled="isSaving" variant="secondary" @click="cancel">
            {{ $t('global.actions.cancel') }}
          </b-button>
        </b-button-toolbar>
      </b-form-row>
    </b-form>
  </b-container>
</template>

<script lang="ts">
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Emit, Prop } from 'vue-property-decorator';
import { ValidationFunc } from 'vuelidate/lib/validators';
import { mixins } from 'vue-class-component';
import { Validations } from 'vuelidate-property-decorators';
import FeedbacksMixin from '@/core/mixins/feedbacksMixin';
import { Entity, EntityType } from '@/core/store/models';
import { ENTITYID, VIEW } from '@/conf/permissions';

@Component
export default class EntityEdit extends mixins(FeedbacksMixin) {
  private p = { VIEW, ENTITYID };

  @Prop() readonly validators!: Record<string, Record<string, ValidationFunc>>;

  @Prop() readonly feedback: Record<string, (value: any) => string> | undefined;

  @Prop() readonly entityId!: number;

  @Prop() readonly parent?: number;

  private entityBase: Entity | null = null;

  private entity: Entity | null = null;

  mounted(): void {
    this.entityBase =
      this.$store.getters['entities/getEntity'](this.entityId) ||
      new Entity({
        name: '',
        type: undefined,
        parent: this.parent || undefined
      });

    this.entity = new Entity({
      id: this.entityId,
      name: this.entityBase?.name,
      type: this.entityBase?.type,
      parent: this.entityBase?.parent
    });
  }

  private isSaving = false;

  @Validations()
  private validations() {
    return {
      entity: {
        name: {},
        type: {},
        ...this.validators
      }
    };
  }

  private validateState(key: string) {
    const { $dirty, $error } = this.$v.entity[key] || {
      $dirty: false,
      $error: false
    };
    return $dirty && !this.isSaving ? !$error : null;
  }

  get validClass(): string {
    if (this.validateState('type') === null) return '';
    if (this.validateState('type')) {
      return 'treeselect-valid';
    }
    return 'treeselect-invalid';
  }

  get isInvalid(): boolean {
    return (
      (!this.$v.entity.name?.$dirty && this.isChanged) ||
      !this.$v.entity.type?.required ||
      this.$v.entity.$anyError
    );
  }

  get isChanged(): boolean {
    return (
      this.entityBase?.name === this.entity?.name &&
      this.entityBase?.type === this.entity?.type
    );
  }

  get types(): EntityType[] | null {
    const entityTypeIsGroup = this.entityBase?.type
      ? this.$store.getters['entityTypes/getEntityType'](this.entityBase.type)
          .isEntityGroup
      : false;
    const types = this.$store.getters['entityTypes/all'].filter(
      (type: EntityType) =>
        !this.entityBase?.type || type.isEntityGroup === entityTypeIsGroup
    );
    const nodes: Array<any> = [];
    if (types === null) return nodes;
    for (let i = 0; i < types?.length; i += 1) {
      const node = {
        id: types[i].id,
        label: types[i].name
      };
      nodes.push(node);
    }
    // Alphabetical order
    nodes.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
    return nodes;
  }

  private async confirm() {
    if (!this.entity) return;

    this.isSaving = true;
    const result = await this.$store.dispatch(
      this.entity?.id ? 'entities/update' : 'entities/create',
      this.entity
    );
    if (result) {
      this.$emit('saved');
    } else {
      this.isSaving = false;
    }
  }

  @Emit('canceled')
  private cancel() {
    // This is intentional
  }
}
</script>

<style lang="scss" scoped>
#create-entity-name,
#create-entity-type {
  width: 300px;
}
</style>
