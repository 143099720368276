<template>
  <div @dragover.prevent class="col-12 h-100">
    <div class="row h-100 page-editor-row">
      <div class="col-6 px-4" v-if="!hasComponentBeingEdited">
        <div class="bloc-component">
          <h6>{{ $t('smsEditor.pageEditor.components') }}</h6>
          <div
            class="text-center s"
            v-for="(component, index) in standardComponents"
            :key="`cpn-${index}`"
          >
            <div class="dragg" v-show="isAvailable(component)">
              <span>{{ component.label }}</span>
              <div
                @dragend="dragEnd()"
                @dragstart="dragNewComponent($event, component)"
                class="component-card component-draggable p-2"
                :draggable="isDraggable(component)"
              >
                <i :class="component.icon" class="fa"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="bloc-component color-picker-container">
          <h6>{{ $t('smsEditor.pageEditor.colors.header') }}</h6>
          <color-picker
            v-model="backgroundColor"
            :title="$t('smsEditor.pageEditor.colors.background')"
          />
          <color-picker
            v-model="foregroundColor"
            :title="$t('smsEditor.pageEditor.colors.foreground')"
          />
        </div>
      </div>
      <div
        class="bg-white col-6 px-4 d-inline-block panel-editor"
        v-if="hasComponentBeingEdited"
      >
        <b-overlay :show="isUploadingImage">
          <web-component-editor
            :component="componentBeingEdited"
            :items="customFields"
            :dirty="dirtyBeingEdited"
            @image-change="onImageChange"
            @save="updateComponent"
            @cancel="cancelEdit"
            @dirty-change="onDirtyChange"
          ></web-component-editor>
        </b-overlay>
      </div>
      <div class="col-6 bg-light">
        <!-- PREVIEW SMS PREMIUM -->
        <status-save />
        <div class="mt-4">
          <h5 class="screen-title text-center">
            {{ $t('smsEditor.pageEditor.preview') }}
          </h5>
        </div>
        <div class="preview" title="Preview Page">
          <div class="webpage tooltipstered">
            <div class="webpageNavbar">http://hsl.so/SAMPLE</div>
            <div :style="styles" class="webpageContent">
              <div
                @drop="drop($event, 0)"
                class="dropzoneArea"
                v-show="dragInProgress"
              ></div>
              <div :key="index" v-for="(component, index) in pageComponents">
                <div
                  :id="'editable-component-' + index"
                  class="component-draggable"
                  :class="{ 'component-selected': indexBeingEdited === index }"
                  :draggable="!dragInProgress"
                  @dragstart="dragExistingComponent($event, index)"
                  @dragend="dragEnd()"
                >
                  <web-component-editable
                    :component="component"
                    :items="customFields"
                    :fontColor="fontColor"
                    @edit="editComponent(index, component)"
                    @remove="removeComponent(index)"
                  ></web-component-editable>
                </div>
                <div
                  @drop="drop($event, index + 1)"
                  class="dropzoneArea"
                  v-show="dragInProgress"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <status-save :status="status" />
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Model, Prop, Watch } from 'vue-property-decorator';
import { SmsPreview } from '@dolmen/vue-toolkit';
import {
  buildNewComponent,
  PageComponent,
  PageComponentItem,
  PageType,
  PAGE_COMPONENTS_STANDARD
} from '@/core/utils/pageUtils';
import { previewedDropdownItem } from '@/core/utils/editorUtils';
import EPageComponentType from '@/core/enum/EPageComponentType';
import EPageComponentFormat from '@/core/enum/EPageComponentFormat';
import ESmsSaveStatus from '@/core/enum/ESmsSaveStatus';
import ColorPicker from './tools/ColorPicker.vue';
import StatusSave from './tools/StatusSave.vue';
import WebComponentEditor from './tools/WebComponentEditor.vue';
import WebComponentEditable from './tools/WebComponentEditable.vue';

@Component({
  components: {
    ColorPicker,
    StatusSave,
    SmsPreview,
    WebComponentEditor,
    WebComponentEditable
  }
})
export default class PageEditor extends Vue {
  @Model('change', { type: Object })
  page!: PageType;

  @Prop({ type: String }) readonly status!: ESmsSaveStatus | null;

  @Prop({ type: Boolean, default: false })
  readonly isUploadingImage!: boolean;

  dragInProgress = false;

  componentBeingEdited: PageComponent | null = null;

  indexBeingEdited: number | null = null;

  dirtyBeingEdited = false;

  standardComponents: Array<PageComponentItem> = PAGE_COMPONENTS_STANDARD;

  customFields: Array<previewedDropdownItem> = [
    {
      label: `${this.$t('smsEditor.tools.customFields.civility')}`,
      value: 'CIV',
      preview: `${this.$t('smsEditor.tools.customFields.civilitypreview')}`
    },
    {
      label: `${this.$t('smsEditor.tools.customFields.firstname')}`,
      value: 'PRENOM',
      preview: 'Jane'
    },
    {
      label: `${this.$t('smsEditor.tools.customFields.lastname')}`,
      value: 'NOM',
      preview: 'DOE'
    },
    {
      label: `${this.$t('smsEditor.tools.customFields.business')}`,
      value: 'ENSEIGNE',
      preview: 'Dolmen'
    }
  ];

  get backgroundColor(): string {
    return this.page.style.colors.background;
  }

  set backgroundColor(value: string) {
    this.page.style.colors.background = value;
    this.$emit('change', this.page);
  }

  get foregroundColor(): string {
    return this.page.style.colors.foreground;
  }

  set foregroundColor(value: string) {
    this.page.style.colors.foreground = value;
    this.$emit('change', this.page);
  }

  get fontColor(): string {
    return this.page.style.colors.font;
  }

  set fontColor(value: string) {
    this.page.style.colors.font = value;
    this.$emit('change', this.page);
  }

  get pageComponents(): Array<PageComponent> {
    return this.page.components;
  }

  set pageComponents(value: Array<PageComponent>) {
    this.page.components = value;
    this.$emit('change', this.page);
  }

  get styles(): object {
    return {
      backgroundColor: this.foregroundColor,
      border: `8px solid  ${this.backgroundColor}`
    };
  }

  get hasComponentBeingEdited(): boolean {
    return this.componentBeingEdited !== null;
  }

  get hasCoupon(): boolean {
    return (
      this.pageComponents.find(
        (cpn) => cpn.type === EPageComponentType.COUPON
      ) !== undefined
    );
  }

  dragNewComponent(event: DragEvent, component: PageComponentItem) {
    // https://github.com/react-dnd/react-dnd/issues/1085
    setTimeout(() => {
      this.dragInProgress = true;
    }, 0);

    if (event.dataTransfer) {
      event.dataTransfer.setData('action', 'new');
      event.dataTransfer.setData('type', component.type);
      if (component.format) {
        event.dataTransfer.setData('format', component.format);
      }
    }
  }

  dragExistingComponent(event: DragEvent, index: number) {
    // https://github.com/react-dnd/react-dnd/issues/1085
    setTimeout(() => {
      this.dragInProgress = true;
    }, 0);
    if (event.dataTransfer) {
      event.dataTransfer.setData('action', 'move');
      event.dataTransfer.setData('index', index.toString());
    }
  }

  dragEnd() {
    this.dragInProgress = false;
  }

  drop(event: DragEvent, index: number) {
    if (event.dataTransfer) {
      // get formated values from dataTransfer
      const action = event.dataTransfer.getData('action');
      const type =
        EPageComponentType[
          event.dataTransfer
            .getData('type')
            .toUpperCase() as keyof typeof EPageComponentType
        ];
      const format = event.dataTransfer.getData('format')
        ? EPageComponentFormat[
            event.dataTransfer.getData(
              'format'
            ) as keyof typeof EPageComponentFormat
          ]
        : undefined;

      switch (action) {
        case 'new':
          this.addComponent(index, type, format);
          break;
        case 'move':
        default:
          this.moveComponent(
            Number(event.dataTransfer.getData('index')),
            index
          );
          break;
      }
      // We call dragEnd() here because the dragend event isn't called at the end of each drag n drop
      this.dragEnd();
    }
  }

  addComponent(
    index: number,
    type: EPageComponentType,
    format?: EPageComponentFormat
  ) {
    const component = buildNewComponent(type, format);
    // add component to list
    this.pageComponents.splice(index, 0, component);
    // open editor
    this.indexBeingEdited = index;
    this.componentBeingEdited = { ...component };
  }

  moveComponent(fromIndex: number, toIndex: number) {
    if (this.pageComponents.length > 1) {
      const component = this.pageComponents[fromIndex];
      this.removeComponent(fromIndex);
      this.pageComponents.splice(toIndex, 0, component);
      this.$emit('change', this.page);
    }
  }

  async editComponent(index: number, component: PageComponent) {
    const confirmation = await this.confirm();
    if (confirmation) {
      this.indexBeingEdited = index;
      this.componentBeingEdited = { ...component };
      this.dirtyBeingEdited = false;
    }
  }

  async cancelEdit() {
    const confirmation = await this.confirm();
    if (confirmation) {
      this.closeEditor();
    }
  }

  updateComponent(component: PageComponent) {
    if (typeof this.indexBeingEdited === 'number') {
      this.pageComponents[this.indexBeingEdited] = component;
    }
    this.closeEditor();
  }

  removeComponent(index: number) {
    this.pageComponents.splice(index, 1);
    this.$emit('change', this.page);
    this.closeEditor();
  }

  onImageChange(file: File) {
    this.$emit('image-change', { index: this.indexBeingEdited, image: file });
  }

  onDirtyChange(value: boolean) {
    this.dirtyBeingEdited = value;
  }

  isDraggable(component: PageComponentItem): boolean {
    // Allow coupon to be added only if no coupon yet
    return (
      !this.dragInProgress &&
      (component.type !== EPageComponentType.COUPON || !this.hasCoupon)
    );
  }

  isAvailable(component: PageComponentItem): boolean {
    // Only one coupon can be added to page
    return !this.hasCoupon || component.type !== EPageComponentType.COUPON;
  }

  closeEditor() {
    this.indexBeingEdited = null;
    this.componentBeingEdited = null;
    this.dirtyBeingEdited = false;
  }

  @Watch('isUploadingImage')
  onImageLoaded(isLoading: boolean, wasLoading: boolean) {
    if (wasLoading && !isLoading) {
      this.closeEditor();
    }
  }

  async confirm(): Promise<boolean> {
    if (this.dirtyBeingEdited) {
      const result = await this.$swal({
        icon: 'warning',
        title: `${this.$t('smsEditor.pageEditor.preventUnsaved.title')}`,
        text: `${this.$t('smsEditor.pageEditor.preventUnsaved.body')}`,
        confirmButtonText: `${this.$t('global.actions.ok')}`,
        showCancelButton: true,
        cancelButtonText: `${this.$t('global.actions.cancel')}`,
        reverseButtons: true
      });
      return result.isConfirmed;
    }
    return true;
  }
}
</script>

<style src="@/core/assets/css/PagePreview.css"></style>
<style>
.page-editor-row {
  min-height: 660px;
}

.dropzoneArea {
  height: 20px;
  width: 100%;
  border: 2px dashed #498efb;
  z-index: 999;
}

.area-default,
.area-image {
  height: 0;
}

.landing-page-editor .card {
  width: 75px;
  text-align: center;
}

.iPhone .navbar {
  background: #f8f9fa;
  border-bottom: solid 1px #cbcbcb;
}

.iPhone .navbar-field {
  border-radius: 3px;
  border: solid 1px #cbcbcb;
  background: #cbcbcb;
  min-width: 100%;
}

.s {
  display: table;
  position: relative;
}

.s > span {
  font-size: 13px;
  font-weight: 300;
}

.component-draggable {
  cursor: pointer;
}

.component-draggable:hover {
  cursor: pointer;
}

.component-draggable:active {
  cursor: grabbing;
}

.component-selected {
  border: 1px dashed black;
}

.component-card > i {
  font-size: 33px;
  padding: 5px 0;
  pointer-events: none;
}

.component-card {
  width: 70px;
  height: 70px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #b8beca;
  margin: 8px auto 8px;
  z-index: 4;
}

.component-card:hover {
  box-shadow: 0 0 5px 0 rgba(48, 68, 95, 0.2);
}

.component-card:active {
  box-shadow: 0 0 5px 0 rgba(48, 68, 95, 0.4);
}

.component-card:first-child {
  margin-left: 0 !important;
  padding-right: 80px;
}

.draggable:active ~ .mask-card,
.component-card:active ~ .mask-card {
  display: block;
  width: 70px;
  height: 70px;
  background-color: #498efb;
  opacity: 0.3;
  border-radius: 8px;
  box-shadow: 0 0 5px 0 rgba(48, 68, 95, 0.4);
  position: absolute;
  bottom: 8px;
  z-index: -1;
  pointer-events: none;
}

.s:nth-of-type(4n + 1) {
  margin-right: 13.5px;
}

.s:not(:nth-of-type(4n + 1)) {
  margin-left: 13.5px;
  margin-right: 13.5px;
}

.s:nth-of-type(n + 5) {
  margin-top: 12px;
}

.preview p {
  color: #30445f;
  font-size: 13px;
  font-weight: 300;
}

.dragg label {
  font-size: 13px;
  font-weight: 700;
  color: #30425c;
}

.color-picker-container > div:not(:last-child) {
  margin-right: 25px;
}

.bloc-component {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 0 5px;
  margin-top: 30px;
  width: 380px;
}

/*.bloc-component:first-of-type {
}*/
.bloc-component > h6 {
  margin: 0 0 6px -5px;
  flex-basis: 100%;
  color: #30445f;
  font-family: 'Museo Sans', sans-serif;
  font-size: 13px;
  line-height: 24px;
  font-weight: 700;
}

.panel-editor {
  box-shadow: 0 2px 20px 0 rgba(48, 68, 95, 0.2);
  z-index: 1;
}
</style>
