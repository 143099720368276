/* eslint-disable no-restricted-syntax */
import SmsCharType from './SmsCharType';
import SmsCharGSM from './SmsCharGSM';
import SmsCharUnicode from './SmsCharUnicode';

export default class SmsCharFactory {
  static charGSM = new SmsCharGSM();

  static charUnicode = new SmsCharUnicode();

  static isUnicodeText(text: string): boolean {
    const REGEX_UNI =
      // eslint-disable-next-line max-len
      /[^A-Za-z0-9 \r\n@£$¥€!|\\"#$%&amp;'\-\\(\\)*\\+,_.\\/:;&lt;=&gt;?^{}\\\\[~\]ÇòΠΘ¿¡¤ΞΨìΣΛΩΦüΓÑù><ÖÄñö§àÜéÅÆåøæØßÉΔèä]/g;
    const matches = [...text.matchAll(REGEX_UNI)];
    return matches.length > 0;
  }

  static createSmsChar(...texts: string[]): SmsCharType {
    for (const text of texts) {
      if (SmsCharFactory.isUnicodeText(text)) {
        return SmsCharFactory.charUnicode;
      }
    }

    return SmsCharFactory.charGSM;
  }
}
