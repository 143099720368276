import { Entity } from '@/core/store/models';

export default class EntityInput {
  id?: number;

  children?: EntityInput[];

  constructor(entity: Partial<Entity>) {
    Object.assign(this, {
      id: entity.id,
      children: entity.children
        ? entity.children.map(
            (child: Partial<Entity>) => new EntityInput(child)
          )
        : undefined
    });
  }
}
