<template>
  <div class="web-component-editable">
    <div class="edit-toolbar">
      <div class="pl-2">
        <i @click="remove" class="fas fa-trash-alt"></i>
      </div>
    </div>

    <text-component
      v-if="isTextComponent"
      v-model="component"
      :fontColor="fontColor"
      :sampleArray="items"
      :placeholderText="$t('smsEditor.tools.webTextEditor.placeholder')"
      @triggerEdit="edit"
    />
    <image-component
      v-if="isImageComponent"
      v-model="component"
      @triggerEdit="edit"
    />
    <coupon-component
      v-if="isCouponComponent"
      v-model="component"
      @triggerEdit="edit"
    />
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import {
  CouponComponent,
  ImageComponent,
  TextComponent
} from '@dolmen/vue-toolkit';
import { PageComponent } from '@/core/utils/pageUtils';
import EPageComponentType from '@/core/enum/EPageComponentType';
import { previewedDropdownItem } from '@/core/utils/editorUtils';

@Component({ components: { TextComponent, ImageComponent, CouponComponent } })
export default class WebComponentEditable extends Vue {
  @Prop({ type: Object })
  readonly component!: PageComponent;

  @Prop({ type: Array, required: true })
  readonly items!: Array<previewedDropdownItem>;

  @Prop({ type: String, required: true })
  readonly fontColor!: string;

  get isTextComponent(): boolean {
    return this.component.type === EPageComponentType.TEXT;
  }

  get isImageComponent(): boolean {
    return this.component.type === EPageComponentType.IMAGE;
  }

  get isCouponComponent(): boolean {
    return this.component.type === EPageComponentType.COUPON;
  }

  @Emit()
  // eslint-disable-next-line @typescript-eslint/no-empty-function, class-methods-use-this
  edit() {}

  @Emit()
  // eslint-disable-next-line @typescript-eslint/no-empty-function, class-methods-use-this
  remove() {}
}
</script>
<style>
.web-component-editable {
  position: relative;
}

.web-component-editable:hover .edit-toolbar {
  display: block;
}

.edit-toolbar {
  display: none;
  position: absolute;
  top: 0px;
  right: 10px;
  padding: 0;
}
</style>
