import { DocumentNode } from 'graphql/language/ast';
import communication from './communication';

export enum DetailedQueryModule {
  Communication = 'communication'
  // Collect = 'Collect',
  // Contacts = 'Contacts'
}

const queries = {
  communication
};

// eslint-disable-next-line import/prefer-default-export
export const getDetailedQuery = (module: DetailedQueryModule): DocumentNode => {
  return queries[module];
};
