import gql from 'graphql-tag';

export default gql`
  query getCommunicationActivity(
    $input: EntityInput!
    $startDate: DateTime!
    $endDate: DateTime!
    $minimum: Int!
  ) {
    getCommunicationActivity(
      input: $input
      minimum: $minimum
      startDate: $startDate
      endDate: $endDate
    ) {
      active {
        count
        percent
      }
      total
    }
  }
`;
