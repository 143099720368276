import { Entity, Organisation } from '@/core/store/models';
import { AxiosResponse } from 'axios';
import ApiClient from './ApiClient';
import paths from './paths';
import { Operation, displayError } from '../modules/apiError';

export default class EntitiesClient extends ApiClient {
  constructor() {
    super(paths.ENTITY_WS);
  }

  async fetch(
    organisation: Organisation
  ): Promise<AxiosResponse<Array<Entity>>> {
    try {
      const response = await this.get(
        { id: `${organisation.id}` },
        { params: { withChildren: 'true' } }
      );
      return response;
    } catch (error) {
      console.error(error);
      displayError(
        error,
        Operation.FETCH,
        `Fetch Entities for: ${organisation.name}`
      );
      return Promise.reject(new Error(error as string));
    }
  }

  async save(
    organisation: Organisation,
    entity: Entity
  ): Promise<AxiosResponse<Entity>> {
    try {
      const exist = !!entity.id;
      const method = (exist ? this.patch : this.post).bind(this);
      const response = await method(
        {
          id: `${organisation.id}`,
          ...(exist && { eId: `${entity.id}` })
        },
        entity
      );
      return response;
    } catch (error) {
      console.error(error);
      displayError(
        error,
        Operation.SAVE,
        `Save Entity for: ${organisation.name} ${entity.name}`
      );
      return Promise.reject(new Error(error as string));
    }
  }
}
