import store from '@/core/store';
import getEnv from '@/core/utils/getEnv';

export default function getPilotLink(path: string): string {
  const currentEntity = store.getters['entities/current'];
  const currentEntityId = currentEntity ? currentEntity.id : 0;
  const logoutUrl = `${window.location.origin}/logout`;
  const oidc = `oidc=auth0&logout=${encodeURI(logoutUrl)}`;

  return `${getEnv('PILOT_URL')}/${path}?${oidc}&networkId=${currentEntityId}`;
}
