<template>
  <b-form @submit.stop.prevent="onSubmit" novalidate>
    <b-row>
      <b-col>
        <p-card
          ref="segmentation"
          :title="$t('communication.campaign.form.segmentation.title')"
          class="p-card-custom"
        >
          <b-col cols="auto" class="mr-auto">
            <b-form-group id="input-gender" label="Genre">
              <b-form-radio-group
                id="radio-group-1"
                v-model="gender"
                :options="genders"
                name="radio-options"
                data-cy="gender"
                stacked
                v-if="!loading"
              />
              <div v-else>
                <b-skeleton animation="wave" width="55%" />
                <b-skeleton animation="wave" width="50%" />
                <b-skeleton animation="wave" width="55%" />
              </div>
            </b-form-group>
            <hr />

            <b-form-group id="input-age-range" label="Tranche d'âge ">
              <div class="slider" v-if="!loading">
                <b-col class="ageMin" data-cy="ageMin">
                  {{ ageRange[0] }}
                </b-col>
                <b-col>
                  <Slider
                    id="input-age-range-slider"
                    v-model="ageRange"
                    :range="true"
                  />
                </b-col>
                <b-col class="ageMax" data-cy="ageMax">
                  {{ ageRange[1] }}
                </b-col>
              </div>
              <b-skeleton v-else animation="wave" />
            </b-form-group>
            <hr />
            <b-form-group
              :label="
                $t('communication.campaign.form.segmentation.birth_month')
              "
            >
              <b-form-select
                id="input-birthmonth"
                v-model="birthMonth"
                :options="months"
                size="sm"
                data-cy="birthMonth"
                v-if="!loading"
              />
              <b-skeleton v-else type="input" width="80%" />
            </b-form-group>
          </b-col>
        </p-card>
      </b-col>
      <b-col>
        <p-card
          :style="{ height: pCardHeight }"
          :title="$t('communication.campaign.form.localisation.title')"
          class="p-card-custom"
        >
          <b-form-checkbox
            disabled
            v-model="localisation"
            data-cy="localisation"
            switch
            v-if="!loading"
            >{{
              $t('communication.campaign.form.localisation.no-locality')
            }}</b-form-checkbox
          >
          <b-col v-else><b-skeleton animation="wave"></b-skeleton></b-col>
        </p-card>
      </b-col>
      <b-col>
        <p-card
          :style="{ height: pCardHeight }"
          :title="$t('communication.campaign.form.target.title')"
          class="p-card-custom"
        >
          <b-col v-if="loading">
            <b-skeleton animation="wave"> </b-skeleton
          ></b-col>
          <span v-else-if="!loading && requestFailed"
            ><h3>
              {{
                $t(
                  'communication.campaign.form.localisation.requestFailed.title'
                )
              }}
            </h3>
            {{
              $t('communication.campaign.form.localisation.requestFailed.text')
            }}</span
          >
          <span class="selectionnedContact" data-cy="targetedContacts" v-else>
            {{
              $tc(
                'communication.campaign.form.target.selection',
                targetedContactsNumber,
                {
                  nb: targetedContactsNumber
                }
              )
            }}
          </span>
        </p-card>
      </b-col>
    </b-row>
    <b-row class="mt-4" align-h="between">
      <b-col cols="auto" class="mr-auto">
        <b-button
          variant="primary"
          :to="{
            name: 'communication-campaign-step-general',
            query: { cid: $route.query.cid }
          }"
          ><i class="fas fa-chevron-left" />
          {{ $t('global.actions.previous') }}</b-button
        >
      </b-col>
      <b-col cols="auto" class="mr-auto">
        <b-button :to="{ name: 'communication-campaign-list' }">{{
          $t('communication.campaign.actions.back')
        }}</b-button>
      </b-col>
      <b-col cols="auto">
        <b-button
          variant="primary"
          type="submit"
          :disabled="loading"
          data-cy="next2"
        >
          <span v-if="!submitting">
            {{ $t('global.actions.next') }} <i class="fas fa-chevron-right" />
          </span>
          <span v-else> {{ $t('global.actions.loading') }} </span>
          <b-spinner v-if="submitting" small></b-spinner>
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import Slider from 'primevue/slider';
import PCard from '@/core/components/cards/PCard.vue';

@Component({ components: { PCard, Slider } })
export default class StepContacts extends Vue {
  submitting = false;

  loading = false;

  months = [
    {
      value: -1,
      text: this.$t('communication.campaign.form.segmentation.clickToChoose')
    },
    {
      value: 1,
      text: this.$t('communication.campaign.form.segmentation.months[0]')
    },
    {
      value: 2,
      text: this.$t('communication.campaign.form.segmentation.months[1]')
    },
    {
      value: 3,
      text: this.$t('communication.campaign.form.segmentation.months[2]')
    },
    {
      value: 4,
      text: this.$t('communication.campaign.form.segmentation.months[3]')
    },
    {
      value: 5,
      text: this.$t('communication.campaign.form.segmentation.months[4]')
    },
    {
      value: 6,
      text: this.$t('communication.campaign.form.segmentation.months[5]')
    },
    {
      value: 7,
      text: this.$t('communication.campaign.form.segmentation.months[6]')
    },
    {
      value: 8,
      text: this.$t('communication.campaign.form.segmentation.months[7]')
    },
    {
      value: 9,
      text: this.$t('communication.campaign.form.segmentation.months[8]')
    },
    {
      value: 10,
      text: this.$t('communication.campaign.form.segmentation.months[9]')
    },
    {
      value: 11,
      text: this.$t('communication.campaign.form.segmentation.months[10]')
    },
    {
      value: 12,
      text: this.$t('communication.campaign.form.segmentation.months[11]')
    }
  ];

  genders = [
    {
      value: 1,
      text: this.$t('communication.campaign.form.segmentation.genders.man')
    },
    {
      value: 0,
      text: this.$t('communication.campaign.form.segmentation.genders.woman')
    },
    {
      value: -1,
      text: this.$t('communication.campaign.form.segmentation.genders.all')
    }
  ];

  pCardHeight = 'auto';

  async created() {
    try {
      this.loading = true;
      await this.$store.dispatch('communication/fetchCampaign', {
        entityId: this.$store.getters['entities/current'].id,
        campaignId: this.$route.query.cid
      });
      await this.$store.dispatch(
        'communication/getPossibleContactForCampaigns'
      );
    } finally {
      this.loading = false;
    }
  }

  updated() {
    this.adjustPCardHeight();
  }

  private adjustPCardHeight() {
    const heightCardSegmentation = (this.$refs.segmentation as any).$el
      .clientHeight;
    this.pCardHeight = `${heightCardSegmentation}px`;
  }

  set gender(value: number) {
    this.$store.commit('communication/updateGender', value);
  }

  get gender(): number {
    return this.$store.state.communication.currentCampaign.filter.gender;
  }

  get birthMonth(): number {
    return this.$store.state.communication.currentCampaign.filter.birthMonth;
  }

  set birthMonth(value: number) {
    this.$store.commit('communication/updateBirthMonth', value);
  }

  get ageRange(): number[] {
    return this.$store.state.communication.currentCampaign.filter.ageRange;
  }

  set ageRange(value) {
    this.$store.commit('communication/updateAgeRange', value);
  }

  get localisation(): boolean {
    return this.$store.state.communication.currentCampaign.filter.localisation;
  }

  set localisation(value) {
    this.$store.commit('communication/updateLocalisation', value);
  }

  get targetedContactsNumber(): number {
    return this.$store.getters['communication/targetedContactFilteredLength'];
  }

  get requestFailed(): boolean {
    return this.$store.getters['communication/targetFailed'];
  }

  onSubmit() {
    this.submitting = true;
    this.$store.dispatch('communication/updateCampaign');
    this.$store
      .dispatch('communication/sendTargetedContacts')
      .then(() => {
        this.$router.push({
          name: 'communication-campaign-step-message',
          query: { cid: this.$route.query.cid }
        });
      })
      .catch(() => {
        this.submitting = false;
      });
  }

  @Watch('$store.state.communication.currentCampaign.filter.gender')
  @Watch('$store.state.communication.currentCampaign.filter.ageRange')
  @Watch('$store.state.communication.currentCampaign.filter.birthMonth')
  reSendReqIfTargetFailed() {
    if (this.requestFailed) {
      this.loading = true;
      this.$store
        .dispatch('communication/getPossibleContactForCampaigns')
        .then(() => {
          this.loading = false;
        });
    }
  }
}
</script>

<style lang="scss">
.slider .p-slider .p-slider-handle {
  background: white;
  border: 2px solid var(--blue);
  border-radius: 50%;
}
.slider .p-slider .p-slider-range {
  background: var(--blue);
}
.slider .p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: var(--cyan);
  border-color: var(--blue);
}
.slider {
  display: flex;
  align-items: center;
}
.ageMin {
  max-width: fit-content;
  padding-left: 0;
  padding-right: 0;
}
.ageMax {
  max-width: fit-content;
  padding-left: 0;
  padding-right: 0;
}
.custom-checkbox .custom-control-label::before {
  border-radius: 50%;
}
.col-align-center {
  display: flex;
  justify-content: center;
}
.p-card-custom {
  h3 {
    color: var(--blue) !important;
  }
}
</style>
