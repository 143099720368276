import i18n from '@/plugins/i18n';
import { getInstance } from '@/core/auth/wrapper';
import { COMMUNICATE, VIEW } from '@/conf/permissions';

export default {
  name: 'communication',
  createMenu: (): any => {
    const authService = getInstance();
    return {
      hidden: !authService || !authService.$ability.can(VIEW, COMMUNICATE),
      href: '/communication',
      icon: 'fa fa-paper-plane',
      title: i18n.t('communication.menu.title')
    };
  }
};
