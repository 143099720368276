<template>
  <div class="communication">
    <b-container class="mt-4 mb-4">
      <b-row>
        <b-col>
          <CommunicationStatItem
            :loading="statLoading"
            :statError="statError"
            :title="$t('analyse.communication.stats.sms.title')"
            icon-src="@/core/assets/img/icons/ico-phone-message.svg"
            :channelStat="thousandSeparator(values.sms.campaigns)"
            :channelDescription="
              $t('analyse.communication.stats.sms.channelDescription')
            "
            :campaignNumberTooltip="
              $t('analyse.communication.stats.indicators.sms.campaign.tooltip')
            "
            :sentStat="thousandSeparator(values.sms.messages)"
            :sentDescription="
              $t('analyse.communication.stats.sms.sentDescription')
            "
            :numberSentTooltip="
              $t('analyse.communication.stats.indicators.sms.volume.tooltip')
            "
            :percentileStat="values.sms.models"
            :percentileDescription="
              $t('analyse.communication.stats.common.templateUsedDescription')
            "
            :percentageModelTooltip="
              $t(
                'analyse.communication.stats.indicators.sms.percentage.tooltip'
              )
            "
            :sentCarbonImpact="
              $t('analyse.communication.stats.sms.sentCarbonImpactFirstHalf') +
              carbonImpactBuilder(values.sms.carbonImpact) +
              $t('analyse.communication.stats.sms.sentCarbonImpactSecondHalf')
            "
            :carbonImpactDifferenceFirstHalf="
              $t(
                'analyse.communication.stats.sms.carbonImpactDifferenceFirstHalf'
              )
            "
            :carbonImpactDifferenceHighlight="p.SMS"
            :carbonImpactDifferenceSecondHalf="
              $t(
                'analyse.communication.stats.sms.carbonImpactDifferenceSecondHalf'
              )
            "
          />
        </b-col>
        <b-col>
          <CommunicationStatItem
            :loading="statLoading"
            :statError="statError"
            :title="$t('analyse.communication.stats.email.title')"
            icon-src="@/core/assets/img/icons/ico-email.svg"
            :channelStat="thousandSeparator(values.email.campaigns)"
            :channelDescription="
              $t('analyse.communication.stats.email.channelDescription')
            "
            :campaignNumberTooltip="
              $t(
                'analyse.communication.stats.indicators.email.campaign.tooltip'
              )
            "
            :sentStat="thousandSeparator(values.email.messages)"
            :sentDescription="
              $t('analyse.communication.stats.email.sentDescription')
            "
            :numberSentTooltip="
              $t('analyse.communication.stats.indicators.email.volume.tooltip')
            "
            :percentileStat="values.email.models"
            :percentileDescription="
              $t('analyse.communication.stats.common.templateUsedDescription')
            "
            :percentageModelTooltip="
              $t(
                'analyse.communication.stats.indicators.email.percentage.tooltip'
              )
            "
            :sentCarbonImpact="
              $t(
                'analyse.communication.stats.email.sentCarbonImpactFirstHalf'
              ) +
              carbonImpactBuilder(values.email.carbonImpact) +
              $t('analyse.communication.stats.email.sentCarbonImpactSecondHalf')
            "
            :carbonImpactDifferenceFirstHalf="
              $t(
                'analyse.communication.stats.email.carbonImpactDifferenceFirstHalf'
              )
            "
            :carbonImpactDifferenceSecondHalf="
              $t(
                'analyse.communication.stats.email.carbonImpactDifferenceSecondHalf'
              )
            "
          />
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <CommunicationGraph />
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col>
          <CommunicationDetails />
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col>
          <!-- Zone "Visualisation" masqué car non utilisé pour le moment (PLA-2564) -->
          <CommunicationMap v-if="false" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { READ, STATISTICS } from '@/conf/permissions';
import { SMS, EMAIL } from '@/conf/components';
import StatsQueryMixin from '../mixins/statsQueryMixin';
import CommunicationStatItem from './components/CommunicationStatItem.vue';
import CommunicationGraph from './components/CommunicationGraph.vue';
import CommunicationMap from './components/CommunicationMap.vue';
import CommunicationDetails from './components/CommunicationDetails.vue';
import { getCommunicationGlobalStats } from '../store/network/graphql/queries';
import { CommunicationStats, EntityStats } from '../store/models/statistics';

Vue.component('CommunicationStatItem', CommunicationStatItem);
Vue.component('CommunicationGraph', CommunicationGraph);
Vue.component('CommunicationMap', CommunicationMap);
Vue.component('CommunicationDetails', CommunicationDetails);

interface MediaCommunicationValues {
  carbonImpact: number;
  campaigns: number;
  messages: number;
  models: number;
}
interface CommunicationValues {
  sms?: MediaCommunicationValues;
  email?: MediaCommunicationValues;
  print?: MediaCommunicationValues;
}

@Component({
  apollo: {
    getCommunicationStat: {
      query: getCommunicationGlobalStats,
      variables() {
        return {
          input: this.entityInput,
          startDate: this.dates.from,
          endDate: this.dates.to
        };
      },
      skip() {
        return (
          !this.dates ||
          !this.dates.from ||
          !this.dates.to ||
          !this.$can(READ, STATISTICS)
        );
      },
      error(error) {
        this.error = error;
      }
    }
  }
})
export default class Communication extends mixins(StatsQueryMixin) {
  private getCommunicationStat: EntityStats = new EntityStats();

  private p = { SMS, EMAIL };

  // eslint-disable-next-line class-methods-use-this
  private thousandSeparator(num: any): string {
    let res = num;
    if (typeof num === 'number') {
      res = this.$n(num);
    }
    return res;
  }

  private carbonImpactBuilder(num: any): string {
    let res = num;
    if (typeof num === 'number') {
      if (num >= 1000) {
        if (num >= 1000000) {
          res = `${this.$n(Math.round(num / 100000) / 10)} T`;
        } else {
          res = `${this.$n(Math.round(num / 100) / 10)} Kg`;
        }
      } else {
        res = `${this.$n(Math.round(num))} g`;
      }
    }
    return res;
  }

  get communication(): CommunicationStats | undefined {
    return this.getCommunicationStat.communication;
  }

  get values(): CommunicationValues {
    return (['sms', 'email', 'print'] as (keyof CommunicationValues)[]).reduce(
      (vals, media: keyof CommunicationValues) => ({
        ...vals,
        [media]: {
          carbonImpact:
            this.communication?.transmittedMessage?.[media]?.carbonImpact || 0,
          campaigns: this.communication?.campaignStats?.[media]?.total || 0,
          messages: this.communication?.transmittedMessage?.[media]?.count || 0,
          models:
            this.communication?.campaignStats?.[media]?.model?.percent || 0
        }
      }),
      {} as CommunicationValues
    );
  }

  mounted(): void {
    this.queryName = 'getCommunicationStat';
    this.$store.commit('app/subheader/ADD_COMPONENT', {
      name: 'SHDatePicker',
      id: 'sh-date-picker'
    });
  }
}
</script>
