export default class Entity {
  id?: number;

  name?: string;

  type?: number;

  legacyType?: string;

  children?: Entity[];

  organisation?: number;

  parent?: number;

  constructor(data: Partial<Entity>) {
    Object.assign(this, {
      ...data,
      children: data?.children
        ? data.children.map((entity: Partial<Entity>) => new Entity(entity))
        : undefined
    });
  }
}
