<template>
  <div>
    <b-card>
      <tab-menu :model="items" />
      <router-view></router-view>
    </b-card>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import TabMenu from 'primevue/tabmenu';
import PCard from '@/core/components/cards/PCard.vue';
import getPilotLink from '@/core/menu/main/getPilotLink';
import { getInstance } from '@/core/auth/wrapper';
import {
  COMMUNICATE_BILLING,
  COMMUNICATE_MODELS,
  VIEW
} from '@/conf/permissions';

@Component({ components: { TabMenu, PCard } })
export default class MenuCommunication extends Vue {
  items = [
    {
      label: this.$t('communication.campaign.title'),
      icon: 'pi pi-send',
      to: '/communication',
      class: 'communication-menu-item-list'
    },
    {
      label: this.$t('communication.billing.title'),
      icon: 'pi pi-money-bill',
      url: getPilotLink('connect/com/invoice'),
      target: '_blank',
      visible: this.haveViewBillingPermissions(),
      class: 'communication-menu-item-billing'
    },
    {
      label: this.$t('communication.modele.title'),
      icon: 'pi pi-fw pi-mobile',
      url: getPilotLink('connect/com/mockup'),
      target: '_blank',
      visible: this.haveViewModelsPermissions(),
      class: 'communication-menu-item-mockup'
    }
  ];

  // eslint-disable-next-line class-methods-use-this
  haveViewBillingPermissions(): boolean {
    const authService = getInstance();
    return authService.$ability.can(VIEW, COMMUNICATE_BILLING);
  }

  // eslint-disable-next-line class-methods-use-this
  haveViewModelsPermissions(): boolean {
    const authService = getInstance();
    return authService.$ability.can(VIEW, COMMUNICATE_MODELS);
  }
}
</script>
<style lang="scss">
.p-menuitem-text {
  color: var(--indigo);
}
.p-menuitem-icon {
  color: var(--blue);
}
</style>
