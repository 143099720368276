import Vue from 'vue';
import VueApollo from 'vue-apollo';
import store from '@/core/store';
import { i18n } from '@/plugins';
import router from '@/core/router';
import initStore from '@/core/store/initStore';
import apolloClient from '@/modules/analyse/store/network/graphql';
import App from './App.vue';
import 'primevue/resources/themes/bootstrap4-light-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

Vue.config.productionTip = false;

// Init Store
initStore();
// Init Graphql Client
Vue.use(VueApollo);
const apolloProvider = new VueApollo({
  defaultClient: apolloClient(store)
});
// Register other modules
store.dispatch('app/registerAllModules');

new Vue({
  router,
  store,
  i18n,
  apolloProvider,
  render: (h) => h(App)
}).$mount('#portal');
