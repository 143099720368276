import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { EntityTypesClient } from '@/core/store/network';
import { EntityType } from '@/core/store/models';
import store from '@/core/store';
import { EntityTypesState } from './state';

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: 'entityTypes'
})
export default class EntityTypes
  extends VuexModule
  implements EntityTypesState
{
  private entityTypesClient = new EntityTypesClient();

  types: EntityType[] = [];

  loading = false;

  // Hash map of all entities
  private _hashEntityTypes: Record<number, EntityType> = {};

  get all(): EntityType[] {
    return this.types;
  }

  get isLoading(): boolean {
    return this.loading;
  }

  get getEntityType() {
    return (id: number): EntityType | null => {
      return this._hashEntityTypes[id] || null;
    };
  }

  @Mutation SET_LOADING(loading: boolean): void {
    this.loading = loading;
  }

  @Mutation
  public SET_ENTITY_TYPES(entityTypes: EntityType[]): void {
    this._hashEntityTypes = {};
    entityTypes.forEach((et) => {
      if (et.id !== undefined) {
        this._hashEntityTypes[et.id] = et;
      }
    });
    this.types = entityTypes;
  }

  /**
   * Fetch the entity types corresponding to the organisation currently selected
   */
  @Action
  async fetch(): Promise<void> {
    this.context.commit('SET_LOADING', true);
    const response = await this.entityTypesClient.fetch(
      store.getters['organisations/current']
    );
    this.context.commit('SET_LOADING', false);
    if (!response || response.status !== 200) {
      console.error(response);
    } else {
      this.context.commit('SET_ENTITY_TYPES', response.data);
    }
  }

  /**
   * Save the entity type at given index
   */
  @Action
  async saveAt(index: number): Promise<boolean> {
    const response = await this.context.dispatch('save', this.types[index]);
    if (response.id !== undefined) {
      // types must be commited with a new object for reactivity to work (PLA-1935)
      this.context.commit('SET_ENTITY_TYPES', [
        ...this.types.slice(0, index),
        response,
        ...this.types.slice(index + 1)
      ]);
    }
    return !!response.id;
  }

  /**
   * Create a new entity type
   */
  @Action
  async create(type: EntityType): Promise<EntityType> {
    const response = await this.context.dispatch('save', type);
    if (response.id !== undefined) {
      this.types.push(response);
      this._hashEntityTypes[response.id] = response;
    }
    return response;
  }

  /**
   * Save the given entity type
   */
  @Action
  async save(type: EntityType): Promise<EntityType | null> {
    const response = await this.entityTypesClient.save(
      store.getters['organisations/current'],
      type
    );
    if (!response || response.status !== 200) {
      // Refresh entity types
      this.context.dispatch('fetch');
      return null;
    }
    return response.data;
  }

  /**
   * Delete the given entity type
   */
  @Action
  async deleteAt(index: number): Promise<boolean> {
    const type = this.types[index];
    if (type.id) {
      const response: any = await this.entityTypesClient.del(
        store.getters['organisations/current'],
        type
      );
      if (!response || response.status !== 200) {
        // Refresh entity types
        this.context.dispatch('fetch');
        return false;
      }
      this.types.splice(index, 1);
      delete this._hashEntityTypes[type.id];
    } else {
      console.error('Trying to delete an entity type with whithout id');
    }
    return true;
  }
}
