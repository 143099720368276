<template>
  <div>
    <b-row class="mt-4">
      <b-col class="centered-title">
        <h1>
          {{
            $t('communication.campaign.form.message.sms-type-selection.title')
          }}
        </h1>
        <span>{{
          $t('communication.campaign.form.message.sms-type-selection.subtitle')
        }}</span>
      </b-col>
    </b-row>
    <b-row class="mt-4" v-if="!loading">
      <sms-type-selector
        v-model="smsType"
        :simplePrice="simplePrice"
        :premiumPrice="premiumPrice"
      />
    </b-row>
    <b-row class="mt-4" align-h="around" v-else>
      <b-col cols="3" class="border border-light rounded p-2">
        <b-skeleton class="mx-auto mt-1" height="21px" width="60%"></b-skeleton>
        <b-skeleton class="mx-auto mt-1" height="60px" width="80%"></b-skeleton>
        <b-skeleton-img
          height="156px"
          width="192px"
          class="mx-auto mt-2"
        ></b-skeleton-img>
        <b-skeleton
          type="button"
          class="mx-auto mt-1"
          height="33px"
          width="130px"
        ></b-skeleton>
      </b-col>
      <b-col cols="3" class="border border-light rounded p-2">
        <b-skeleton class="mx-auto mt-1" height="21px" width="60%"></b-skeleton>
        <b-skeleton class="mx-auto mt-1" height="60px" width="80%"></b-skeleton>
        <b-skeleton-img
          height="156px"
          width="192px"
          class="mx-auto mt-2"
        ></b-skeleton-img>
        <b-skeleton
          type="button"
          class="mx-auto mt-1"
          height="33px"
          width="130px"
        ></b-skeleton>
      </b-col>
    </b-row>
    <b-row class="mt-4" v-if="isTypeSelected">
      <b-col class="centered-title">
        <h1>
          {{ $t('communication.campaign.form.message.sms-edition.title') }}
        </h1>
        <span>{{
          $t('communication.campaign.form.message.sms-edition.subtitle')
        }}</span>
      </b-col>
    </b-row>
    <b-row class="mt-4" v-if="isTypeSelected">
      <sms-editor
        v-model="sms"
        :status="status"
        :hasLink="isPremium"
        @sms-count="onSmsCountChange"
        @state-change="onStateChange"
        @error="onError"
      />
    </b-row>
    <b-row class="mt-5" v-if="isPremium">
      <b-col class="centered-title">
        <h1>
          {{ $t('communication.campaign.form.message.page-edition.title') }}
        </h1>
        <span>{{
          $t('communication.campaign.form.message.page-edition.subtitle')
        }}</span>
      </b-col>
    </b-row>
    <b-row class="mt-4" v-if="isPremium">
      <page-editor
        v-model="page"
        :status="status"
        :isUploadingImage="isUploadingImage"
        @image-change="onImageChange"
      />
    </b-row>
    <b-row class="mt-4" v-if="isTypeSelected">
      <b-col class="centered-title">
        <h1>
          {{ $t('communication.campaign.form.message.sms-send-test.title') }}
        </h1>
        <span>{{
          $t('communication.campaign.form.message.sms-send-test.subtitle')
        }}</span>
      </b-col>
    </b-row>
    <b-row class="mt-4" v-if="isTypeSelected">
      <sms-send-test
        :invalidMessage="!isMessageValid"
        :sending="isSendingTest"
        :disabled="isMessageSaving"
        @send-test="onSendTest"
      />
    </b-row>

    <b-row class="mt-4" align-h="between">
      <b-col cols="auto" class="mr-auto">
        <b-button
          variant="primary"
          :to="{
            name: 'communication-campaign-step-contacts',
            query: { cid: $route.query.cid }
          }"
          ><i class="fas fa-chevron-left" />
          {{ $t('global.actions.previous') }}</b-button
        >
      </b-col>
      <b-col cols="auto" class="mr-auto">
        <b-button :to="{ name: 'communication-campaign-list' }">{{
          $t('communication.campaign.actions.back')
        }}</b-button>
      </b-col>
      <b-col cols="auto">
        <b-button
          variant="primary"
          :disabled="!isSubmittable"
          data-cy="next3"
          @click="popupAlertMessage"
        >
          {{ $t('global.actions.next') }} <i class="fas fa-chevron-right" />
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import SmsTypeSelector from '@/core/components/smsEditor/SmsTypeSelector.vue';
import SmsEditor from '@/core/components/smsEditor/SmsEditor.vue';
import PageEditor from '@/core/components/smsEditor/PageEditor.vue';
import SmsSendTest from '@/core/components/smsEditor/SmsSendTest.vue';
import ESmsType from '@/core/enum/ESmsType';
import CommunicationService from '@/modules/communication/service/CommunicationService';
import { getSmsPrice, SmsType } from '@/core/utils/smsUtils';
import ESmsSaveStatus from '@/core/enum/ESmsSaveStatus';
import { PageType } from '@/core/utils/pageUtils';
import { Toast } from '@/core/store/models';

@Component({
  components: {
    SmsTypeSelector,
    SmsEditor,
    PageEditor,
    SmsSendTest
  }
})
export default class StepMessage extends Vue {
  service: CommunicationService = new CommunicationService();

  loading = true;

  smsCount = 1;

  isSendingTest = false;

  validationState: boolean | null = null;

  isUploadingImage = false;

  popupAlertMessage() {
    if (this.smsCount > 1) {
      this.$swal({
        icon: 'info',
        title: `${this.$t(
          'communication.campaign.form.message.popupInfo.popupHeader'
        )}`,
        text: `${this.$t(
          'communication.campaign.form.message.popupInfo.popupTextMultipleSms'
        )}`,
        confirmButtonText: `${this.$t(
          'communication.campaign.form.message.popupInfo.continue'
        )}`,
        showCancelButton: true,
        cancelButtonText: `${this.$t(
          'communication.campaign.form.message.popupInfo.cancel'
        )}`,
        reverseButtons: true,
        confirmButtonColor: 'var(--blue)',
        cancelButtonColor: '#acbbd1',
        iconColor: 'var(--blue)',
        padding: '2.5rem',
        customClass: {
          popup: 'dolmen-swal-popup',
          confirmButton: 'dolmen-swal-btn dolmen-swal-btn-primary',
          cancelButton: 'dolmen-swal-btn dolmen-swal-btn-secondary'
        }
      }).then((e) => {
        if (!e.dismiss) {
          this.updateCampaign();
        }
      });
    } else {
      this.updateCampaign();
    }
  }

  updateCampaign() {
    this.loading = true;
    this.$store
      .dispatch('communication/updateCampaign')
      .then(() => {
        this.$router.push({
          name: 'communication-campaign-step-confirmation',
          query: { cid: this.$route.query.cid }
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  get simplePrice(): string {
    return this.getUnitPrice(ESmsType.SIMPLE);
  }

  get premiumPrice(): string {
    return this.getUnitPrice(ESmsType.PREMIUM);
  }

  get isSimple(): boolean {
    return this.smsType === ESmsType.SIMPLE;
  }

  get isPremium(): boolean {
    return this.smsType === ESmsType.PREMIUM;
  }

  get isTypeSelected(): boolean {
    return this.smsType !== undefined;
  }

  get isMessageSaving(): boolean {
    return this.status === ESmsSaveStatus.SAVING;
  }

  get isMessageValid(): boolean {
    return (
      this.status !== ESmsSaveStatus.ERROR && this.validationState === true
    );
  }

  get isSubmittable(): boolean {
    return (
      this.smsType !== undefined &&
      this.status !== ESmsSaveStatus.SAVING &&
      this.status !== ESmsSaveStatus.ERROR &&
      this.validationState === true
    );
  }

  get smsType(): ESmsType | null {
    return this.$store.getters['communication/smsType'];
  }

  set smsType(value: ESmsType | null) {
    this.$store.commit('communication/updateSmsType', value);
  }

  get sms(): SmsType {
    return this.$store.state.communication.currentCampaign.media.smsMedia.sms;
  }

  set sms(value: SmsType) {
    this.$store.dispatch('communication/updateSms', {
      type: this.smsType,
      sms: value,
      page: this.page,
      count: this.smsCount
    });
  }

  get status(): ESmsSaveStatus | null {
    return this.$store.state.communication.smsSaveStatus;
  }

  get page(): PageType {
    return this.$store.state.communication.currentCampaign.media.smsMedia.page;
  }

  set page(value: PageType) {
    this.$store.dispatch('communication/updateSms', {
      type: this.smsType,
      sms: this.sms,
      page: value,
      count: this.smsCount
    });
  }

  async onImageChange(details: { index: number; image: File }) {
    this.isUploadingImage = true;
    await this.$store.dispatch('communication/uploadImage', details);
    this.$store.dispatch('communication/updateSms', {
      type: this.smsType,
      sms: this.sms,
      page: this.page,
      count: this.smsCount
    });
    this.isUploadingImage = false;
  }

  getUnitPrice(type: ESmsType): string {
    const options = this.$store.state.communication.entityOptions;
    if (options) {
      const price = this.service.findPriceBySMSType(type, options.prices);
      if (price) {
        return `${getSmsPrice(price.national, 1)}`;
      }
    }
    return '- ';
  }

  async created() {
    try {
      this.loading = true;
      await this.$store.dispatch('communication/fetchCampaign', {
        entityId: this.$store.getters['entities/current'].id,
        campaignId: this.$route.query.cid
      });
      this.$store.dispatch('communication/getEntityConfiguration');
      this.$store.dispatch('communication/fetchSms');
    } finally {
      this.loading = false;
    }
  }

  onSmsCountChange(count: number) {
    this.smsCount = count;
    this.$store.dispatch('communication/updateSms', {
      type: this.smsType,
      sms: this.sms,
      page: this.page,
      count
    });
  }

  onStateChange(value: boolean | null) {
    this.validationState = value;
  }

  async onSendTest(phoneNumber: string) {
    try {
      this.isSendingTest = true;
      await this.$store.dispatch('communication/sendSmsTest', phoneNumber);
    } finally {
      this.isSendingTest = false;
    }
  }

  onError(content: { type: string }) {
    const title = `${this.$t(`smsEditor.errors.${content.type}.title`)}`;
    const description = `${this.$t(
      `smsEditor.errors.${content.type}.description`
    )}`;
    this.$store.commit('app/DISPLAY_TOAST', Toast.error(title, description));
  }
}
</script>
<style scoped>
h1 {
  color: #30445f;
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
}

h1 ~ span {
  font-weight: 300;
  color: #30445f;
  font-size: 15px;
}
.centered-title {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
<style>
.dolmen-swal-btn.dolmen-swal-btn-primary {
  background-color: var(--blue);
  border-color: var(--blue);
  color: #fff;
  padding: 0.8rem 1rem;
}

.dolmen-swal-btn.dolmen-swal-btn-secondary {
  padding: 0.8rem 1rem;
  transition: background-color 0.3s;
}

.dolmen-swal-popup {
  border-radius: 1.5rem;
}

.dolmen-swal-popup h2 {
  line-height: 1.2;
  margin-bottom: 0;
  font-size: 1.5rem;
}

.dolmen-swal-popup .swal2-html-container {
  line-height: 1.5;
  margin-bottom: 1rem;
}
</style>
