<template>
  <p-card class="text-center" :title="title" :icon-src="iconSrc">
    <b-container v-if="!loading && !statError">
      <h2
        v-b-tooltip
        :title="campaignNumberTooltip"
        class="mb-0 statCampaign"
        id="statCampaign"
        v-if="$can(p.READ, p.STATISTICS)"
      >
        {{ channelStat }}
      </h2>
      <h2 v-else>-</h2>
      <p>{{ channelDescription }}</p>

      <h2
        v-b-tooltip
        :title="numberSentTooltip"
        class="mb-0 statSend"
        id="statSend"
        v-if="$can(p.READ, p.STATISTICS)"
      >
        {{ sentStat }}
      </h2>
      <h2 v-else>-</h2>

      <p>{{ sentDescription }}</p>

      <h2
        v-b-tooltip
        :title="percentageModelTooltip"
        class="mb-0 text-secondary statPercent"
        id="statPercent"
        v-if="$can(p.READ, p.STATISTICS)"
      >
        <span v-if="percentileStat !== '-'">
          {{ Math.round(percentileStat) }} %
        </span>
        <span v-else>{{ percentileStat }}</span>
      </h2>
      <h2 v-else>- %</h2>
      <span>{{ percentileDescription }}</span>

      <div style="font-size: 75%">
        <br />
        <strong>
          {{ sentCarbonImpact }}
        </strong>
        <br />
        <span v-if="carbonImpactDifferenceFirstHalf != ''">
          {{ carbonImpactDifferenceFirstHalf }}
          <strong style="color: #33a02c">{{
            carbonImpactDifferenceHighlight
          }}</strong>
          {{ carbonImpactDifferenceSecondHalf }}
        </span>
        <span v-else>
          <br />
          <br />
        </span>
      </div>
    </b-container>
    <b-spinner v-else-if="loading && !statError" />
    <b-container v-else-if="statError">
      <h5
        class="text-center key-stat-error"
        v-b-tooltip
        :title="$t('analyse.dashboard.keyFacts.error-tooltip')"
      >
        {{ $t('analyse.dashboard.keyFacts.error') }}
      </h5>
    </b-container>
  </p-card>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import PCard from '@/core/components/cards/PCard.vue';
import { READ, STATISTICS } from '@/conf/permissions';

type StringNbrUnd = string | number | undefined;
@Component({ components: { PCard } })
export default class CommunicationStatItem extends Vue {
  private p = { READ, STATISTICS };

  @Prop(String) private title: string | undefined;

  @Prop(String) private iconSrc: string | undefined;

  @Prop([Number, String]) private channelStat: StringNbrUnd;

  @Prop(String) private channelDescription: string | undefined;

  @Prop([Number, String]) private sentStat: StringNbrUnd;

  @Prop(String) private sentDescription: string | undefined;

  @Prop(String) private sentCarbonImpact: string | undefined;

  @Prop(String) private carbonImpactDifferenceFirstHalf: string | undefined;

  @Prop(String) private carbonImpactDifferenceHighlight: string | undefined;

  @Prop(String) private carbonImpactDifferenceSecondHalf: string | undefined;

  @Prop([Number, String]) private percentileStat: StringNbrUnd;

  @Prop(String) private percentileDescription: string | undefined;

  @Prop(Boolean) private loading: boolean | undefined;

  @Prop(String) private campaignNumberTooltip: string | undefined;

  @Prop(String) private numberSentTooltip: string | undefined;

  @Prop(String) private percentageModelTooltip: string | undefined;

  @Prop(Boolean) private statError: boolean | undefined;
}
</script>
