<template>
  <div class="editor-component">
    <div class="d-flex flex-row-reverse mb-3">
      <span @click="cancel" class="pl-2 btn-close align-self-center"
        >{{ $t('global.actions.close') }}<i class="di-close"></i
      ></span>
    </div>
    <div v-if="component">
      <web-text-editor
        v-if="isTextComponent"
        v-model="editedComponent"
        :items="items"
      />
      <web-image-editor
        v-else-if="isImageComponent"
        v-model="editedComponent"
        @change="onImageChange"
      />
      <web-coupon-editor
        v-else-if="isCouponComponent"
        v-model="editedComponent"
        @validity-change="onValidityChange"
      />
    </div>
    <div class="mt-3 text-center">
      <button
        :class="[
          'btn',
          'btn-outline-dark',
          {
            'mr-3': !isImageComponent
          }
        ]"
        @click="cancel"
        type="button"
      >
        {{ $t('global.actions.cancel') }}
      </button>
      <button
        :disabled="isNotSubmittable"
        @click="save"
        class="btn btn-primary"
        type="button"
        v-if="!isImageComponent"
      >
        {{ $t('global.actions.confirm') }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { PageComponent } from '@/core/utils/pageUtils';
import EPageComponentType from '@/core/enum/EPageComponentType';
import { dropdownItem } from '@/core/utils/editorUtils';
import WebTextEditor from './web-component-editors/WebTextEditor.vue';
import WebImageEditor from './web-component-editors/WebImageEditor.vue';
import WebCouponEditor from './web-component-editors/WebCouponEditor.vue';

@Component({
  components: {
    WebTextEditor,
    WebImageEditor,
    WebCouponEditor
  }
})
export default class WebComponentEditor extends Vue {
  @Prop({ type: Object, required: true }) readonly component!: PageComponent;

  @Prop({ type: Array, required: true })
  readonly items!: Array<dropdownItem>;

  @Prop({ type: Boolean, required: true })
  readonly dirty!: boolean;

  componentValue: PageComponent | null = null;

  invalid = false;

  get isTextComponent(): boolean {
    return this.component.type === EPageComponentType.TEXT;
  }

  get isImageComponent(): boolean {
    return this.component.type === EPageComponentType.IMAGE;
  }

  get isCouponComponent(): boolean {
    return this.component.type === EPageComponentType.COUPON;
  }

  get editedComponent(): PageComponent {
    return this.component;
  }

  set editedComponent(value: PageComponent) {
    if (this.componentValue !== null) {
      // avoid setting dirty at the initialization
      this.$emit('dirty-change', true);
    }
    this.componentValue = value;
  }

  get isNotSubmittable(): boolean {
    return !this.dirty || this.invalid;
  }

  onValidityChange(isValid: boolean) {
    this.invalid = !isValid;
  }

  onImageChange(file: File) {
    this.$emit('image-change', file);
  }

  @Watch('dirty')
  onDirtyChange(isDirty: boolean, wasDirty: boolean) {
    if (wasDirty && !isDirty) {
      this.clear();
    }
  }

  @Watch('component')
  onComponentChange() {
    this.clear();
  }

  save() {
    this.$emit('save', this.componentValue);
  }

  async cancel() {
    this.$emit('cancel');
  }

  clear() {
    this.invalid = false;
    this.componentValue = null;
  }
}
</script>
<style scoped>
.editor-component {
  margin: 23px;
}

.btn-close {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 3px;
}

.btn-close > i {
  display: inline-block;
  margin-left: 8px;
  font-size: 0.9em;
}
</style>
