import i18n from '@/plugins/i18n';
import faq from '@/core/assets/img/icons/ico-faq.svg';

export default {
  name: 'training',
  createMenu: (): any => ({
    title: i18n.t('menu.training'),
    icon: {
      element: 'img',
      attributes: { src: faq }
    },
    child: [
      {
        href: ' https://intercom.help/dolmenfr/fr/',
        icon: 'fa fa-question-circle',
        title: i18n.t('menu.faq'),
        external: true,
        attributes: {
          target: '_blank'
        }
      }
    ]
  })
};
