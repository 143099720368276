export default class Toast {
  visible = false;

  title = '';

  message = '';

  variant = 'primary';

  delay = 5000;

  constructor(data: Partial<Toast>) {
    Object.assign(this, data);
  }

  static error(title: string, message: string): Toast {
    return new Toast({
      title,
      message,
      variant: 'danger'
    });
  }

  static info(title: string, message: string): Toast {
    return new Toast({
      title,
      message,
      variant: 'info'
    });
  }

  static success(title: string, message: string): Toast {
    return new Toast({
      title,
      message,
      variant: 'success'
    });
  }

  static warning(title: string, message: string): Toast {
    return new Toast({
      title,
      message,
      variant: 'warning'
    });
  }
}
