<template>
  <p-card
    v-if="$can(p.VIEW, p.USERS)"
    :title="$t('organisation.users.editor.title')"
    icon-src="@/core/assets/img/icons/ico-users.svg"
  >
    <b-spinner v-if="isLoading" />
    <b-container v-else>
      <b-table
        outlined
        striped
        hover
        fixed
        responsive
        :items="users"
        :fields="fields"
        class="users-list"
        show-empty
      >
        <template #table-colgroup>
          <col />
          <col />
          <col />
          <col style="width: 10%" />
        </template>
        <!-- Headers -->
        <template #head()="data">
          <span :id="data.column">
            {{ $t(`organisation.users.editor.headers.${data.column}`) }}
          </span>
          <b-tooltip :target="data.column" placement="right">
            {{ $t(`organisation.users.editor.headers.tips.${data.column}`) }}
          </b-tooltip>
        </template>

        <template #empty>
          {{ $t('organisation.users.editor.empty') }}
        </template>

        <!-- Name column -->
        <template #cell(UserName)="row">
          <div :id="`user-name-${row.index}`">
            {{ row.item.name }}
          </div>
        </template>

        <!-- Email column -->
        <template #cell(UserEmail)="row">
          <div :id="`user-email-${row.index}`">
            {{ row.item.email }}
          </div>
        </template>

        <!-- Entities column -->
        <template #cell(UserEntities)="row">
          <span
            :id="`entities-${row.index}`"
            v-if="entitiesNames(row.item.entities).length < 2"
          >
            {{ entitiesNames(row.item.entities).toString() }}
          </span>
          <span :id="`entities-${row.index}`" v-else>
            {{
              `[...] ${row.item.entities.length} ${$t(
                'organisation.users.editor.headers.UserEntities'
              )}`
            }}
          </span>
          <b-tooltip
            v-if="row.item.entities.length > 1"
            placement="right"
            :target="`entities-${row.index}`"
          >
            <div v-for="name in entitiesNames(row.item.entities)" :key="name">
              {{ name }}
            </div>
          </b-tooltip>
        </template>

        <!-- Actions-->
        <template #cell(UserActions)="row">
          <div v-if="$can(p.EDIT, p.USERS)">
            <b-button
              :id="`editUser-${row.index}`"
              variant="secondary"
              @click="$root.$emit('bv::hide::popover')"
            >
              <i class="fas fa-edit" aria-hidden="true" />
            </b-button>
            <b-tooltip
              placement="right"
              :target="`editUser-${row.index}`"
              :title="$t('organisation.users.editor.tips.edit')"
            />
            <PopoverForm
              :target="`editUser-${row.index}`"
              :title="$t('organisation.users.editor.popupTitle')"
              v-slot="scopeEdit"
            >
              <UserEdit
                @canceled="scopeEdit.canceled"
                @saved="scopeEdit.saved"
                :validators="createFormValidators(users[row.index])"
                :feedback="feedback"
                :userId="users[row.index].userId"
              />
            </PopoverForm>
          </div>
        </template>
      </b-table>
      <div v-if="$can(p.CREATE, p.USERS)">
        <b-button
          variant="primary"
          id="create-user-button"
          href="#"
          tabindex="0"
        >
          <i class="fas fa-plus mr-1" aria-hidden="true" />
          {{ $t('organisation.users.editor.create') }}
        </b-button>
        <PopoverForm
          target="create-user-button"
          :title="$t('organisation.users.editor.createTitle')"
          v-slot="scope"
        >
          <UserEdit
            @canceled="scope.canceled"
            @saved="scope.saved"
            :validators="createFormValidators({})"
            :feedback="feedback"
          />
        </PopoverForm>
      </div>
    </b-container>
  </p-card>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { email, maxLength, required } from 'vuelidate/lib/validators';
import PCard from '@/core/components/cards/PCard.vue';
import PopoverForm from '@/core/components/widgets/PopoverForm.vue';
import { User } from '@/core/store/models';
import { CREATE, EDIT, USERS, VIEW } from '@/conf/permissions';
import FeedbacksMixin from '@/core/mixins/feedbacksMixin';
import PopupMixin from '@/core/mixins/popupMixin';
import UserEdit from './UserEdit.vue';

@Component({
  components: { PCard, PopoverForm, UserEdit }
})
export default class UsersEditor extends mixins(FeedbacksMixin, PopupMixin) {
  private p = { VIEW, USERS, CREATE, EDIT };

  private fields = ['UserName', 'UserEmail', 'UserEntities', 'UserActions'];

  get users(): User[] {
    return this.$store.getters['users/all'].sort((a: User, b: User) => {
      if (b.name && a.name) {
        return a.name?.localeCompare(b.name);
      }
      return 0;
    });
  }

  private entitiesNames(ids: number[]): string[] {
    return ids
      .map((id) => {
        return this.$store.getters['entities/getEntity'](id) !== null
          ? this.$store.getters['entities/getEntity'](id).name
          : '';
      })
      .filter((name) => {
        return name !== '';
      });
  }

  get isLoading(): boolean {
    return this.$store.getters['users/isLoading'];
  }

  mounted(): void {
    this.$store.dispatch('users/fetch');
    this.$store.dispatch('roles/fetch');
  }

  // Validations on user name
  private nameValidators(user: User) {
    if (this.users.length > 0) {
      return {
        required,
        maxLength: maxLength(70),
        noNameDuplicate: (value: string) => {
          return !this.users?.find(
            (t) => t.name === value && t.userId !== user.userId
          );
        }
      };
    }
    return {
      required,
      maxLength: maxLength(70)
    };
  }

  private emailValidators(user: User) {
    if (this.users.length > 0) {
      return {
        required,
        email,
        maxLength: maxLength(70),
        noEmailDuplicate: (value: string) => {
          return !this.users?.find(
            (t) => t.email === value && t.userId !== user.userId
          );
        }
      };
    }
    return {
      required,
      email,
      maxLength: maxLength(70)
    };
  }

  // eslint-disable-next-line class-methods-use-this
  private entitiesValidators() {
    return {
      required,
      maxLength: maxLength(80)
    };
  }

  // eslint-disable-next-line class-methods-use-this
  private rolesValidators(user: User) {
    return this.$store.getters['auth/currentUser'].sub === user.userId
      ? {}
      : {
          required,
          maxLength: maxLength(50)
        };
  }

  // Validators for create form
  private createFormValidators(user: User) {
    return {
      name: this.nameValidators(user),
      email: this.emailValidators(user),
      entities: this.entitiesValidators(),
      role: this.rolesValidators(user)
    };
  }

  private feedback = {
    name: (value: any) => {
      if (value.noEmailDuplicate === false) {
        return `${this.$t(
          'organisation.users.editor.validators.emailDuplicate'
        )}`;
      }
      if (value.noNameDuplicate === false) {
        return `${this.$t(
          'organisation.users.editor.validators.nameDuplicate'
        )}`;
      }
      return this.genericFeedback(value);
    }
  };
}
</script>

<style lang="scss" scoped>
.users-list {
  .btn {
    padding: 2px 8px;
    margin-top: -5px;
    margin-bottom: -5px;
  }
}
</style>
