import CommunicationStats from './communicationStats';

export default class EntityStats {
  id?: number;

  children?: EntityStats[];

  communication?: CommunicationStats;

  constructor(data?: Partial<EntityStats>) {
    Object.assign(this, data);
  }
}
