<template>
  <p-card
    :title="$t('analyse.communication.stats.common.communicationTendency')"
    icon-src="@/core/assets/img/icons/ico-graph.svg"
    ><b-row>
      <b-col class="text-center" cols="12">
        <b-form-group>
          <b-form-radio-group
            id="btn-media"
            v-model="media"
            :options="mediaOptions"
            button-variant="outline-primary"
            name="radios-btn-media"
            buttons
          ></b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <highcharts
          :options="tendencyChartOptions"
          v-if="$can(p.READ, p.STATISTICS)"
        ></highcharts>
      </b-col>
    </b-row>
  </p-card>
</template>
<script lang="ts">
import moment from 'moment';
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Chart } from 'highcharts-vue';
import PCard from '@/core/components/cards/PCard.vue';
import { READ, STATISTICS } from '@/conf/permissions';
import { getCommunicationTrends } from '../../store/network/graphql/queries';
import { EntityStats } from '../../store/models/statistics';
import StatsQueryMixin from '../../mixins/statsQueryMixin';
import TransmittedMessages from '../../store/models/statistics/transmittedMessages';
import MonthStatsTransmittedMessages from '../../store/models/statistics/monthStatsTransmittedMessages';

@Component({
  components: { PCard, highcharts: Chart },
  apollo: {
    getCommunicationStat: {
      query: getCommunicationTrends,
      variables() {
        return {
          input: this.entityInput,
          startDate: this.fullMonthDates.from,
          endDate: this.fullMonthDates.to
        };
      },
      skip() {
        return (
          !this.dates ||
          !this.dates.from ||
          !this.dates.to ||
          !this.$can(READ, STATISTICS)
        );
      },
      error(error) {
        this.error = error;
      }
    },
    previousGetCommunicationStat: {
      query: getCommunicationTrends,
      variables() {
        return {
          input: this.entityInput,
          startDate: moment(this.fullMonthDates.from)
            .utc()
            .subtract(12, 'month')
            .toDate(),
          endDate: moment(this.fullMonthDates.to)
            .utc()
            .subtract(12, 'month')
            .toDate()
        };
      },
      skip() {
        return !this.dates || !this.dates.from || !this.dates.to;
      },
      error(error) {
        this.error = error;
      },
      update(data) {
        return data.getCommunicationStat;
      }
    }
  }
})
export default class CommunicationGraph extends mixins(StatsQueryMixin) {
  private media = 'sms';

  private getCommunicationStat: EntityStats = new EntityStats();

  private previousGetCommunicationStat: EntityStats = new EntityStats();

  private mediaOptions = ['sms', 'email'].map((value) => ({
    text: this.$t(`analyse.communication.stats.${value}.title`),
    value
  }));

  private p = { READ, STATISTICS };

  get dates(): { from: Date; to: Date } {
    return this.$store.getters['analyse/statsDates'];
  }

  get fullMonthDates(): { from: Date; to: Date } {
    // Limited to 12 months max
    const start = moment(this.dates.from).utc().startOf('month');
    const end = moment(this.dates.to).utc().endOf('month');
    const endMax = moment(this.dates.from)
      .utc()
      .add(11, 'month')
      .endOf('month');
    return {
      from: start.toDate(),
      to: moment.min(end, endMax).toDate()
    };
  }

  get selectedData(): {
    now: {
      sms: Array<number>;
      model: Array<number>;
      categories: Array<string>;
    };
    previous: {
      sms: Array<number>;
      model: Array<number>;
      categories: Array<string>;
    };
  } {
    const key = this.media as keyof TransmittedMessages;
    const monthsNow =
      this.getCommunicationStat.communication?.transmittedMessage?.[key]
        ?.monthStats;
    const monthsRef =
      this.previousGetCommunicationStat.communication?.transmittedMessage?.[key]
        ?.monthStats;
    const fromNow = this.fullMonthDates.from;
    const fromRef = moment(fromNow).utc().subtract(12, 'month').toDate();
    return {
      now: {
        sms: monthsNow?.map((m) => m.count || 0) ?? [],
        model: monthsNow?.map((m) => m.model || 0) ?? [],
        categories:
          monthsNow?.map((_: MonthStatsTransmittedMessages, index: number) =>
            moment(fromNow).add(index, 'month').format('MM/YY')
          ) ?? []
      },
      previous: {
        sms: monthsRef?.map((m) => m.count || 0) ?? [],
        model: monthsRef?.map((m) => m.model || 0) ?? [],
        categories:
          monthsRef?.map((_: MonthStatsTransmittedMessages, index: number) =>
            moment(fromRef).add(index, 'month').format('MM/YY')
          ) ?? []
      }
    };
  }

  // Fixed width fot chart to handle column size and label positions
  private chartWidth = 1050;

  private get tendencyChartOptions(): any {
    return {
      chart: {
        type: 'column',
        styledMode: true,
        width: this.chartWidth
      },
      credits: { enabled: false },
      title: null,

      xAxis: [
        {
          id: 'now',
          className: 'nowAxis',
          categories: this.selectedData.now.categories,
          labels: {
            align: 'right',
            x: -4
          }
        },
        {
          id: 'previous',
          className: 'previousAxis',
          categories: this.selectedData.previous.categories,
          labels:
            this.selectedData.now.sms.length > 0
              ? {
                  align: 'left',
                  y: -11,
                  x: 4
                }
              : { align: 'left', y: 25, x: -15 }
        }
      ],

      yAxis: {
        allowDecimals: false,
        min: 0,
        title: null
      },

      plotOptions: {
        column: {
          borderRadius: 10,
          grouping: false,
          shadow: false,
          crisp: false,
          pointWidth: 25,
          centerInCategory: true
        }
      },

      series: [
        {
          name: this.$t(
            `analyse.communication.tendencies.currentPeriod.${this.media}`
          ),
          data: [...this.selectedData.now.sms].splice(0),
          pointPlacement: (-0.2 * this.selectedData.now.sms.length) / 11,
          xAxis: 'now',
          events: {
            legendItemClick: () => {
              return false;
            }
          }
        },
        /*
        {
          name: this.$t(
            `analyse.communication.tendencies.currentPeriod.model.${this.media}`
          ),
          data: this.selectedData.now.model,
          pointPadding: 0.4,
          pointPlacement: 0,
          // pointPlacement: -0.2,
          xAxis: 'now'
        },
         */
        {
          name: this.$t(
            `analyse.communication.tendencies.referencePeriod.${this.media}`
          ),
          data: this.selectedData.previous.sms,
          pointPlacement: (0.2 * this.selectedData.now.sms.length) / 11,
          xAxis: 'previous',
          colorIndex: 2,
          events: {
            legendItemClick: () => {
              return false;
            }
          }
        }
        /*
        ,
        {
          name: this.$t(
            `analyse.communication.tendencies.referencePeriod.model.${this.media}`
          ),
          data: this.selectedData.previous.model,
          pointPadding: 0.4,
          pointPlacement: 0.2,
          xAxis: 'previous',
          colorIndex: 3
        }
        */
      ]
    };
  }
}
</script>
<style lang="scss">
$colors: $orange #ffd07c $blue #000d15;

@import 'highcharts/css/highcharts.scss';
rect {
  &.highcharts-point {
    stroke-width: 0px;
  }
}
.highcharts-axis-line {
  stroke: none;
}
.nowAxis {
  text {
    fill: $orange;
  }
}
.previousAxis {
  text {
    fill: $blue;
  }
  transform: translateY(-7px);
}
.highcharts-legend-item > text {
  font-weight: normal;
}
.highcharts-container {
  overflow: scroll hidden;
}
</style>
