<template>
  <p-card
    :title="$t('organisation.entities.editor.title')"
    icon-src="@/core/assets/img/icons/ico-network.svg"
  >
    <!-- eslint-disable vue/no-v-model-argument -->
    <b-container>
      <TreeTable
        :value="nodes"
        :loading="isLoading"
        :lazy="true"
        class="entity-list"
        :expandedKeys.sync="expandedKeys"
        @node-expand="setDragAndDrop"
        ref="entities"
      >
        <Column
          field="name"
          :header="$t('organisation.entities.editor.headers.entity')"
          :expander="true"
        >
          <template #body="slotProps">
            <i
              aria-hidden="true"
              v-if="isGrp(slotProps.node.key)"
              class="fas fa-layer-group mr-1"
            />
            {{ slotProps.node.data.name }}
          </template>
        </Column>
        <Column
          :header="$t('organisation.entities.editor.headers.type')"
          headerStyle="width: 16em"
        >
          <template #body="slotProps">
            <div :id="`${slotProps.node.key}`">
              {{ slotProps.node.data.type }}
            </div>
          </template>
        </Column>
        <Column
          :header="$t('organisation.entities.editor.headers.actions')"
          headerStyle="width: 8em; text-align: center"
          bodyStyle="display: flex; justify-content: space-evenly"
        >
          <template #body="slotProps">
            <div v-if="$can(p.CREATE, p.ENTITY) && isGrp(slotProps.node.key)">
              <b-button
                variant="primary"
                :id="`add-${slotProps.node.key}`"
                @click="$root.$emit('bv::hide::popover')"
              >
                <i class="fas fa-plus mr-1" aria-hidden="true" />
              </b-button>
              <b-tooltip
                :target="`add-${slotProps.node.key}`"
                :title="$t('organisation.entities.editor.tips.add')"
              />
              <PopoverForm
                :target="`add-${slotProps.node.key}`"
                :title="$t('organisation.entities.editor.popupTitle')"
                v-slot="scopeAdd"
              >
                <EntityEdit
                  @canceled="scopeAdd.canceled"
                  @saved="scopeAdd.saved"
                  :validators="createFormValidators"
                  :feedback="feedback"
                  :parent="slotProps.node.key"
                />
              </PopoverForm>
            </div>
            <div v-if="$can(p.EDIT, p.ENTITY)">
              <b-button
                variant="secondary"
                class="btn-left"
                :id="`edit-${slotProps.node.key}`"
                @click="$root.$emit('bv::hide::popover')"
              >
                <i class="fas fa-edit mr-1" aria-hidden="true" />
              </b-button>
              <b-tooltip
                :target="`edit-${slotProps.node.key}`"
                :title="$t('organisation.entities.editor.tips.edit')"
              />
              <PopoverForm
                :target="`edit-${slotProps.node.key}`"
                :title="$t('organisation.entities.editor.popupTitle')"
                v-slot="scopeEdit"
              >
                <EntityEdit
                  @canceled="scopeEdit.canceled"
                  @saved="scopeEdit.saved"
                  :validators="createFormValidators"
                  :feedback="feedback"
                  :entityId="slotProps.node.key"
                />
              </PopoverForm>
            </div>
          </template>
        </Column>
      </TreeTable>
      <b-button variant="primary" @click="expandAll">
        {{ $t('organisation.entities.editor.expandAll') }}
      </b-button>
      <b-button class="btn-right" variant="secondary" @click="collapseAll">
        {{ $t('organisation.entities.editor.collapseAll') }}
      </b-button>
    </b-container>
  </p-card>
</template>

<script lang="ts">
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import TreeTable from 'primevue/treetable';
import Column from 'primevue/column';
import { maxLength, required } from 'vuelidate/lib/validators';
import TreeModel from 'tree-model';
import i18n from '@/plugins/i18n';
import PCard from '@/core/components/cards/PCard.vue';
import { Entity, EntityType, Toast } from '@/core/store/models';
import { CREATE, EDIT, ENTITY } from '@/conf/permissions';
import PopoverForm from '@/core/components/widgets/PopoverForm.vue';
import FeedbacksMixin from '@/core/mixins/feedbacksMixin';
import { getInstance } from '@/core/auth/wrapper';
import EntityEdit from './EntityEdit.vue';

Vue.component('TreeTable', TreeTable);
Vue.component('Column', Column);

export interface TreeNode {
  key?: any;
  data?: any;
  children?: TreeNode[];
  leaf?: boolean;
}

@Component({ components: { PCard, PopoverForm, EntityEdit } })
export default class EntitiesEditor extends mixins(FeedbacksMixin) {
  private p = { CREATE, EDIT, ENTITY };

  private nodes: TreeNode[] = [];

  private expandedKeys: boolean[] = [];

  private scrollingValue = 0;

  private scrollingTimeout: number | null = null;

  mounted(): void {
    this.$store.dispatch('entities/fetch');
  }

  private onChange() {
    if (this.entities !== null) {
      this.nodes = this.loadNodes(this.entities);
      this.setDragAndDrop();
    }
  }

  @Watch('entities', { immediate: true, deep: true })
  onEntitiesChanged(): void {
    this.onChange();
  }

  get entities(): Entity[] | null {
    return this.$store.getters['entities/all'];
  }

  @Watch('types')
  onTypesChanged(): void {
    this.onChange();
  }

  get types(): EntityType[] | null {
    return this.$store.getters['entityTypes/all'];
  }

  get isLoading(): boolean {
    return (
      this.$store.getters['entities/isLoading'] ||
      this.$store.getters['entityTypes/isLoading']
    );
  }

  isGrp(entityId: number): boolean {
    const entity = this.$store.getters['entities/getEntity'](entityId);
    const entityType = this.$store.getters['entityTypes/getEntityType'](
      entity.type
    );
    return !entityType || entityType.isEntityGroup;
  }

  // Check if the entity already exists
  private isExist(entityId: number, entityParent: number): boolean {
    if (this.entities === null || this.entities === undefined) return false;

    const json = JSON.stringify(this.entities[0]);
    const tree = new TreeModel();
    const node = tree.parse(JSON.parse(json));
    const val = node.first((n) => {
      if (n.model.id === entityParent) {
        for (let i = 0; i < n.model.children.length; i += 1) {
          if (n.model.children[i].id === entityId) return n.model.id;
        }
      }
      return undefined;
    });
    return val !== undefined;
  }

  // Validations on entity name
  private nameValidators() {
    if (this.entities !== null && this.entities !== undefined) {
      const json = JSON.stringify(this.entities[0]);

      const tree = new TreeModel();
      const node = tree.parse(JSON.parse(json));

      return {
        required,
        maxLength: maxLength(85),
        noDuplicate: (value: string) => {
          const val = node.first((n) => {
            return n.model.name === value;
          });
          return val === undefined;
        }
      };
    }
    return {
      required,
      maxLength: maxLength(85)
    };
  }

  // Validations on entity type
  private typeValidators() {
    return {
      required
    };
  }

  // Validators for create form
  private createFormValidators = {
    name: this.nameValidators(),
    type: this.typeValidators()
  };

  private feedback = {
    name: (value: any) => {
      if (value.noDuplicate === false)
        return `${this.$t(
          'organisation.entities.editor.validators.noDuplicate'
        )}`;
      return this.genericFeedback(value);
    },
    type: (value: any) => {
      return this.genericFeedback(value);
    }
  };

  private doScrolling(isStarting = false) {
    if (this.scrollingValue === 0) {
      if (this.scrollingTimeout !== null) {
        window.clearTimeout(this.scrollingTimeout);
        this.scrollingTimeout = null;
      }
    } else if (!isStarting || this.scrollingTimeout === null) {
      window.scrollBy(0, this.scrollingValue);
      this.scrollingTimeout = window.setTimeout(
        this.doScrolling.bind(this),
        100
      );
    }
  }

  private getColor(entityId: number): string {
    return this.isGrp(entityId)
      ? 'outline: solid #87CEFA'
      : 'outline: solid #FF7F7F';
  }

  private getOnDropEntityId(item: any) {
    return parseInt(item.children[1].children[0].id, 10);
  }

  dragstart(ev: any): void {
    ev.dataTransfer.setData('text/plain', ev.target.children[1].children[0].id);
  }

  dragend(): void {
    this.scrollingValue = 0;
  }

  dragenter(ev: any): void {
    ev.preventDefault();
    ev.currentTarget.setAttribute(
      'style',
      this.getColor(this.getOnDropEntityId(ev.currentTarget))
    );
  }

  dragover(ev: any): void {
    ev.preventDefault();
  }

  dragleave(ev: any): void {
    ev.currentTarget.setAttribute('style', ev.currentTarget.defaultStyle);
  }

  drag(ev: any): void {
    const height =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const scrollMargin = 200;
    if (ev.y < scrollMargin) {
      this.scrollingValue = -1;
    } else if (ev.y > height - scrollMargin) {
      this.scrollingValue = 1;
    } else {
      this.scrollingValue = 0;
    }
    this.doScrolling();
    ev.target.setAttribute('style', 'cursor: grabbing');
  }

  drop(ev: any): void {
    this.scrollingValue = 0;
    const droppedEntityId = parseInt(ev.dataTransfer.getData('text/plain'), 10);
    const onDropEntityId = this.getOnDropEntityId(ev.currentTarget);
    const element = ev.currentTarget;
    element.setAttribute('style', element.defaultStyle);
    if (
      droppedEntityId !== null &&
      onDropEntityId != null &&
      droppedEntityId !== onDropEntityId
    ) {
      if (
        this.isChildren(
          this.$store.getters['entities/getEntity'](droppedEntityId),
          this.$store.getters['entities/getEntity'](onDropEntityId)
        )
      ) {
        this.$store.commit(
          'app/DISPLAY_TOAST',
          Toast.error(
            `${i18n.t('global.errors.edit.title')}`,
            `${i18n.t('global.errors.edit.text')}`.concat(
              ` ${i18n.t('global.errors.common.466')}`
            )
          )
        );
      } else if (!this.isGrp(onDropEntityId)) {
        this.$store.commit(
          'app/DISPLAY_TOAST',
          Toast.error(
            `${i18n.t('global.errors.edit.title')}`,
            `${i18n.t('global.errors.edit.text')}`.concat(
              ` ${i18n.t('global.errors.common.465')}`
            )
          )
        );
      } else {
        this.$store.dispatch(
          'entities/saveParent',
          new Entity({
            id: droppedEntityId,
            parent: onDropEntityId
          })
        );
        // Expand node where its dropped
        this.expandedKeys = { ...this.expandedKeys, [onDropEntityId]: true };
      }
    }
    ev.preventDefault();
  }

  setDragAndDrop(): void {
    const authService = getInstance();
    if (!authService.$ability.can(EDIT, ENTITY)) return;
    setTimeout(() => {
      const trChildren =
        document.getElementsByClassName('p-treetable-tbody')[0]?.children;
      // Draggable on all trs
      for (let i = 0; i < trChildren.length; i += 1) {
        trChildren[i].setAttribute('draggableEnabled', 'true');
        if (i > 0) {
          // Set tr to draggable
          trChildren[i].setAttribute('draggable', 'true');
          // Styling drag
          trChildren[i].setAttribute('style', 'cursor: grab');
          trChildren[i].removeEventListener('drag', this.drag);
          trChildren[i].addEventListener('drag', this.drag);

          // Starting a drag operation
          trChildren[i].removeEventListener('dragstart', this.dragstart);
          trChildren[i].addEventListener('dragstart', this.dragstart);
          trChildren[i].removeEventListener('dragend', this.dragend);
          trChildren[i].addEventListener('dragend', this.dragend);
        }
        trChildren[i].setAttribute(
          'defaultStyle',
          i === 0
            ? 'outline-color: white;'
            : 'outline-color: white; cursor: grab'
        );
        // Specifying drop targets
        trChildren[i].removeEventListener('dragenter', this.dragenter);
        trChildren[i].addEventListener('dragenter', this.dragenter);
        trChildren[i].removeEventListener('dragover', this.dragover);
        trChildren[i].addEventListener('dragover', this.dragover);
        trChildren[i].removeEventListener('dragleave', this.dragleave);
        trChildren[i].addEventListener('dragleave', this.dragleave);
        // Performing a drop
        trChildren[i].removeEventListener('drop', this.drop);
        trChildren[i].addEventListener('drop', this.drop);
      } // End for
    }, 0);
  }

  private isChildren(parent: Entity, entity: Entity): boolean {
    let isChild = false;
    if (parent.children === null || parent.children === undefined) return false;
    for (let i = 0; i < parent.children.length; i += 1) {
      const child = parent.children[i];
      isChild = isChild || child === entity || this.isChildren(child, entity);
    }
    return isChild;
  }

  private loadNodes(entities: Entity[] | null | undefined): TreeNode[] {
    const nodes: TreeNode[] = [];
    if (entities === null || entities === undefined) return nodes;
    for (let i = 0; i < entities?.length; i += 1) {
      const type = this.$store.getters['entityTypes/getEntityType'](
        entities[i].type
      );
      const node: TreeNode = {
        key: entities[i].id,
        data: {
          id: entities[i].id,
          name: entities[i].name,
          type: type !== null ? type.name : ''
        },
        children: this.loadNodes(entities[i].children),
        leaf: entities[i].children === undefined
      };
      nodes.push(node);
    }
    return nodes;
  }

  expandAll(): void {
    for (let i = 0; i < this.nodes.length; i += 1) {
      this.expandNode(this.nodes[i]);
    }

    this.expandedKeys = { ...this.expandedKeys };
    this.setDragAndDrop();
  }

  collapseAll(): void {
    this.expandedKeys = [];
  }

  expandNode(node: TreeNode): void {
    if (node.children && node.children.length) {
      this.expandedKeys[node.key] = true;

      for (let i = 0; i < node.children.length; i += 1) {
        this.expandNode(node.children[i]);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.entity-list {
  .btn {
    padding: 4px 4px 4px 8px;
    margin-top: -5px;
    margin-bottom: -5px;
  }
}

.btn-right {
  margin-left: 15px;
}
</style>
