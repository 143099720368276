import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import RootState from '@/core/store/modules/rootState';

Vue.use(Vuex);

// Declare empty store first
const aStore = new Store<RootState>({});

export default aStore;
