<template>
  <div class="semi-circular-indicator-container">
    <h4 v-if="title != undefined">{{ title }}</h4>
    <div class="semi-circular-indicator">
      <div class="svg-container">
        <svg viewBox="0 0 55 50" class="svg-semi-circular-indicator">
          <path class="semi-circle-bg" d="M 12.5 38 A 20 20 0 1 1 42 38" />
          <path
            class="semi-circle"
            :style="{ animationDuration: `${duration}s` }"
            :stroke-dasharray="`${this.percentage}, 100`"
            d="M 12.5 38 A 20 20 0 1 1 42 38"
          />
        </svg>
      </div>
      <p class="semi-circular-indicator-caption">
        <span>{{ incrementvalue }}</span
        >/{{ total }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class SemiCircularIndicator extends Vue {
  @Prop(Number) readonly value!: number;

  @Prop(Number) readonly total!: number;

  @Prop(String) readonly title?: string;

  @Prop({ type: Number, default: 2 })
  readonly duration!: number;

  private incrementvalue = 0;

  mounted(): void {
    this.incrementvalueAnimation();
  }

  get percentage(): number {
    return (this.value / this.total) * 100;
  }

  incrementvalueAnimation(): void {
    setTimeout(() => {
      if (this.incrementvalue < this.value) {
        this.incrementvalue += 1;
        this.incrementvalueAnimation();
      }
    }, (this.duration * 1000) / this.value);
  }
}
</script>

<style lang="scss" scoped>
$semi-circular-indicator-thickness: 6;

.semi-circular-indicator-container {
  text-align: center;
  min-width: 120px;
  min-height: 120px;

  h4 {
    color: $semi-circular-indicator-title-color;
    font-weight: $title-font-weight;
    font-family: $title-font-familly;
  }

  .semi-circular-indicator {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;

    .svg-container {
      position: absolute;
      width: 100%;

      .svg-semi-circular-indicator {
        display: block;
        margin: auto;
      }

      .semi-circle-bg {
        fill: none;
        stroke: $semi-circular-indicator-bg-color;
        stroke-width: $semi-circular-indicator-thickness;
      }

      .semi-circle {
        fill: none;
        stroke: $semi-circular-indicator-circle-color;
        stroke-width: $semi-circular-indicator-thickness;
        animation: progress ease-in-out;
      }

      @keyframes progress {
        0% {
          stroke-dasharray: 0 100;
        }
      }
    }

    .semi-circular-indicator-caption {
      font-family: $title-font-familly;
      span {
        color: $semi-circular-indicator-caption-calue-color;
        font-weight: $title-font-weight;
      }
      position: absolute;
      text-align: center;
      bottom: 0;
    }
  }
}
</style>
