import Vue from 'vue';
import { VueAuthPlugin, AuthOptions, AuthAppState } from '@/core/auth';
import getEnv from '@/core/utils/getEnv';
import router from '@/core/router';

const schema = window.location.protocol;
const domain = window.location.host;
const host = `${schema}//${domain}`;

const options: AuthOptions = {
  domain: getEnv('AUTH_DOMAIN'),
  clientId: getEnv('AUTH_CLIENT_ID'),
  options: {
    audience: getEnv('AUTH_AUDIENCE')
  },
  redirectUri: host,
  onRedirectCallback: (appState: AuthAppState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
};

Vue.use(VueAuthPlugin, options);

export default {};
