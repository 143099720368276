<template>
  <div class="form-group">
    <label for="input-link-title">
      {{ $t('smsEditor.tools.smsLinkTitleInput.label') }} * :
    </label>
    <div class="input-group">
      <b-form-input
        id="input-link-title"
        ref="inputLinkTitle"
        v-model="messageLinkTitle"
        type="text"
        :maxlength="maxlength"
        :state="state"
        aria-describedby=" input-link-title-feedback"
      />
      <b-form-invalid-feedback id="input-link-title-feedback">
        <em class="pl-1 fas fa-exclamation-circle orange d-inline pr-1"> </em>
        <span>{{
          $t('smsEditor.tools.smsLinkTitleInput.feedback', {
            maxlength: maxlength
          })
        }}</span>
      </b-form-invalid-feedback>
    </div>
  </div>
</template>
<script lang="ts">
import SmsCharFactory from '@/core/utils/smsUtils/SmsCharFactory';
import SmsCharUnicode from '@/core/utils/smsUtils/SmsCharUnicode';
import {
  Component,
  Vue,
  ModelSync,
  Prop,
  Watch,
  Ref
} from 'vue-property-decorator';

@Component
export default class SmsLinkTitleInput extends Vue {
  @ModelSync('title', 'change', { type: String })
  messageLinkTitle!: string;

  @Prop({ type: Number, required: true }) readonly maxlength!: number;

  @Prop({ type: Boolean }) readonly state?: boolean;

  @Prop({ type: String, required: true }) readonly charset!: string;

  @Ref('inputLinkTitle') readonly linkTitleInput!: HTMLInputElement;

  get isUnicode(): boolean {
    return this.charset === SmsCharUnicode.CHARSET;
  }

  @Watch('messageLinkTitle')
  checkLength(val: string) {
    const smsCharType = this.isUnicode
      ? SmsCharFactory.charUnicode
      : SmsCharFactory.charGSM;

    if (smsCharType.countCharacters(val) > this.maxlength) {
      this.$nextTick(() => {
        this.messageLinkTitle = val.slice(
          0,
          this.maxlength - smsCharType.countCharacters(val)
        );
      });
      this.$emit('error', { type: 'truncate-link-title' });
    }
  }
}
</script>
<style scoped>
#input-link-title {
  width: 50%;
}
</style>
