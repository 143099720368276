<template>
  <div class="col-12 save-status">
    <!-- Succes -->
    <div v-if="isSaved">
      <i class="pi pi-save mr-2"></i>
      <span>{{ $t('smsEditor.tools.statusSave.campaignSaved') }}</span>
    </div>
    <!-- SAVING -->
    <div v-if="isSaving">
      <b-spinner small label="Loading..." class="mr-2"></b-spinner>
      <span>{{ $t('smsEditor.tools.statusSave.saveInProgress') }}</span>
    </div>
    <!-- ERROR -->
    <div v-if="isError" class="text-danger">
      <i class="fas fa-exclamation-circle mr-2"></i>
      <span>{{ $t('smsEditor.tools.statusSave.saveError') }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import ESmsSaveStatus from '@/core/enum/ESmsSaveStatus';

@Component
export default class StatusSave extends Vue {
  @Prop({ type: String }) readonly status!: ESmsSaveStatus | null;

  get isSaved(): boolean {
    return this.status === ESmsSaveStatus.SAVED;
  }

  get isSaving(): boolean {
    return this.status === ESmsSaveStatus.SAVING;
  }

  get isError(): boolean {
    return this.status === ESmsSaveStatus.ERROR;
  }
}
</script>

<style scoped>
.save-status {
  font-size: 0.9rem;
}
</style>
