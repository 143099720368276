<template>
  <sidebar-menu
    :menu="menu"
    :collapsed="collapsed"
    :rtl="rtl"
    :width="options.width"
    :widthCollapsed="options.widthCollapsed"
    :showOneChild="options.showOneChild"
    :showChild="options.showChild"
    :relative="options.relative"
    :hideToggle="true"
    :disableHover="true"
  >
  </sidebar-menu>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { SidebarMenu } from 'vue-sidebar-menu';
import MenuItem from '@/core/menu/menuItem';
import { menuWidth, menuWidthCollapsed } from '@/conf/menu';

@Component({
  components: {
    SidebarMenu
  }
})
export default class Menu extends Vue {
  private options = {
    width: `${menuWidth}px`,
    widthCollapsed: `${menuWidthCollapsed}px`,
    showOneChild: true,
    showChild: false,
    relative: false,
    disableHover: false
  };

  get menu(): Array<MenuItem> {
    return this.$store.getters['app/currentMenu'];
  }

  get rtl(): boolean {
    return this.$store.getters['app/isRTL'];
  }

  get collapsed(): boolean {
    return this.$store.getters['app/isCollapsed'];
  }
}
</script>

<style lang="scss" scoped>
.v-sidebar-menu {
  top: $header-height;
}
</style>
