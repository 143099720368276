import { format } from 'date-fns';
import { enUS, fr } from 'date-fns/locale';
import i18n from '@/plugins/i18n';

const locales: Record<string, Locale> = { 'en-US': enUS, fr };

// by providing a default string of 'PP' or any of its variants for `formatStr`
// it will format dates in whichever way is appropriate to the locale
export default function dateFormat(date: Date, formatStr = 'PP'): string {
  return format(date, formatStr, {
    locale: locales[i18n.locale]
  });
}

export function isDateValid(date: Date): boolean {
  return !Number.isNaN(date.getTime());
}

export const dateDisplayFormat = 'dd/MM/yyyy';
