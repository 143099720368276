import './bootstrap-vue';
import './vuetoolkit';
import './vuelidate';
import './auth0';
import i18nPlugin from './i18n';
import './caslability';
import './components';
import './vue-sweetalert2';

export const i18n = i18nPlugin;

export default {};
