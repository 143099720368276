import getEnv from '@/core/utils/getEnv';
import { AxiosResponse } from 'axios';
import { Organisation } from '../models';
import User from '../models/user';
import ApiClient from './ApiClient';
import paths from './paths';
import { Operation, displayError } from '../modules/apiError';

export default class UsersClient extends ApiClient {
  constructor() {
    super(paths.USERS_WS);
  }

  private baseURL = getEnv('USER_MANAGER_URL');

  async fetch(organisation: Organisation): Promise<AxiosResponse<Array<User>>> {
    try {
      const response = await this.get(
        { id: `${organisation.id}` },
        { baseURL: `${this.baseURL}` }
      );
      return response;
    } catch (error) {
      displayError(
        error,
        Operation.FETCH,
        `Fetch Users for: ${organisation.name}`
      );
      console.error(error);
      return Promise.reject(new Error(error as string));
    }
  }

  async fetchUser(
    organisation: Organisation,
    userId: string
  ): Promise<AxiosResponse<User>> {
    try {
      const response = await this.get(
        {
          id: `${organisation.id}`,
          userId: `${userId}`
        },
        { baseURL: `${this.baseURL}` }
      );
      // EP renvoie une liste de role mais les utilisateurs n'ont qu'un seul role
      if (response?.data.roles && response.data.roles.length > 0) {
        response.data.role = response.data.roles.find(() => true);
      }
      return response;
    } catch (error) {
      displayError(
        error,
        Operation.FETCH,
        `Fetch User ${userId} for:  ${organisation.name}`
      );
      console.error(error);
      return Promise.reject(new Error(error as string));
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async save(
    organisation: Organisation,
    user: any
  ): Promise<AxiosResponse<User>> {
    try {
      const exist = !!user.userId;
      const method = (exist ? this.patch : this.post).bind(this);
      const response = await method(
        {
          id: `${organisation.id}`,
          ...(exist && { userId: `${user.userId}` })
        },
        {
          name: user.name,
          email: user.email,
          entities: user.entities,
          ...(user.role && { roles: [user.role.roleId] })
        },
        { baseURL: `${this.baseURL}` }
      );

      return response;
    } catch (error) {
      displayError(
        error,
        Operation.SAVE,
        `Save User ${user.userId} for:  ${organisation.name}`
      );
      console.error(error);
      return Promise.reject(new Error(error as string));
    }
  }
}
