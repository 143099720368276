import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { RolesClient } from '@/core/store/network';
import { Role } from '@/core/store/models';
import store from '@/core/store';
import { RolesState } from './state';

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: 'roles'
})
export default class Roles extends VuexModule implements RolesState {
  private UsersRolesClient = new RolesClient();

  roles: Role[] = [];

  loading = false;

  // Hash map of all roleId and role
  private _hashRoles: Record<string, Role> = {};

  get all(): Role[] {
    return this.roles;
  }

  get isLoading(): boolean {
    return this.loading;
  }

  get getRole() {
    return (roleId: string): Role | null => {
      return this._hashRoles[roleId] || null;
    };
  }

  @Mutation SET_LOADING(loading: boolean): void {
    this.loading = loading;
  }

  @Mutation
  public SET_ROLES(roles: Role[]): void {
    this._hashRoles = {};
    roles.forEach((et) => {
      if (et.roleId !== undefined) {
        this._hashRoles[et.roleId] = et;
      }
    });
    this.roles = roles;
  }

  /**
   * Fetch all roles
   */
  @Action
  async fetch(): Promise<void> {
    this.context.commit('SET_LOADING', true);
    const response = await this.UsersRolesClient.fetch();
    this.context.commit('SET_LOADING', false);
    if (!response || response.status !== 200) {
      console.error(response);
    } else {
      this.context.commit('SET_ROLES', response.data);
    }
  }
}
