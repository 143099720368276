import imageDefault from '../assets/img/sms-editor/imageDefault';
import EPageComponentFormat from '../enum/EPageComponentFormat';
import EPageComponentType from '../enum/EPageComponentType';

export type PageComponentItem = {
  type: EPageComponentType;
  format?: EPageComponentFormat;
  label: string;
  icon: string;
};

export type TextPageComponent = {
  type: EPageComponentType;
  content: string;
};

export type ImagePageComponent = {
  type: EPageComponentType;
  url: string;
  fid: string;
};

export type RelativeCouponValidity = {
  after: number;
  duration: number;
};

export type AbsoluteCouponValidity = {
  start: string;
  end: string;
};

export type CouponValidity = RelativeCouponValidity | AbsoluteCouponValidity;

export type CouponPageComponent = {
  type: EPageComponentType;
  codeIsInPath: boolean;
  validity: CouponValidity;
  format: string;
  code: string;
  duration: number;
};

export type PageComponent =
  | TextPageComponent
  | ImagePageComponent
  | CouponPageComponent;

export type PageStyle = {
  colors: {
    background: string;
    foreground: string;
    font: string;
  };
};

export type PageType = {
  style: PageStyle;
  components: Array<PageComponent>;
};

export const PAGE_COMPONENTS_STANDARD = [
  {
    type: EPageComponentType.IMAGE,
    label: 'Photo',
    icon: 'fa-image'
  },
  {
    type: EPageComponentType.TEXT,
    label: 'Texte',
    icon: 'fa-font'
  }
  // {
  //   type: EPageComponentType.COUPON,
  //   format: EPageComponentFormat.ITF25,
  //   label: 'ITF 25',
  //   icon: 'fa-barcode'
  // },
  // {
  //   type: EPageComponentType.COUPON,
  //   format: EPageComponentFormat.EAN13,
  //   label: 'EAN 13',
  //   icon: 'fa-barcode'
  // },
  // {
  //   type: EPageComponentType.COUPON,
  //   format: EPageComponentFormat.CODE128,
  //   label: 'Code 128',
  //   icon: 'fa-barcode'
  // },
  // {
  //   type: EPageComponentType.COUPON,
  //   format: EPageComponentFormat.QR,
  //   label: 'QR code',
  //   icon: 'fa-qrcode'
  // },
  // {
  //   type: EPageComponentType.COUPON,
  //   format: EPageComponentFormat.TEXT,
  //   label: 'Code Texte',
  //   icon: 'fa-align-left'
  // }
];

export function buildNewComponent(
  type: EPageComponentType,
  format?: EPageComponentFormat
): PageComponent {
  switch (type) {
    case EPageComponentType.IMAGE:
      return {
        type: EPageComponentType.IMAGE,
        url: imageDefault.base64,
        fid: ''
      };
    case EPageComponentType.COUPON:
      return {
        codeIsInPath: true,
        type: EPageComponentType.COUPON,
        validity: {
          after: 0,
          duration: 15
        },
        format: format || EPageComponentFormat.TEXT,
        code: '',
        duration: 3600
      };
    case EPageComponentType.TEXT:
    default:
      return {
        type: EPageComponentType.TEXT,
        content: ''
      };
  }
}
