import gql from 'graphql-tag';

export default gql`
  scalar DateTime
  schema {
    query: Query
  }
  type Query {
    getCommunicationStat(input: EntityInput!, populateFor: [ID]): Entity
    getCommunicationActivity(
      input: EntityInput!
      minimum: Int!
      startDate: DateTime!
      endDate: DateTime!
    ): CommunicationActivity
  }
  input EntityInput {
    id: ID!
    children: [EntityInput]
  }
  type GenericCount {
    count: Int
    percent: Float
    months: MonthStat
  }
  type CampaignStat {
    total: Int
    model: GenericCount
  }
  type CampaignStats {
    sms: CampaignStat
    email: CampaignStat
    print: CampaignStat
    total: CampaignStat
  }
  type Communication {
    transmittedMessage: TransmittedMessages
    campaignStats: CampaignStats
  }
  type Entity {
    id: ID!
    communication(startDate: DateTime, endDate: DateTime): Communication
    children: [Entity]
  }
  type CommunicationActivity {
    total: Int
    active: GenericCount
  }
  type TransmittedMessages {
    sms: TransmittedMessagesDetail
    email: TransmittedMessagesDetail
    print: TransmittedMessagesDetail
    total: TransmittedMessagesDetail
  }
  type TransmittedMessagesDetail {
    carbonImpact: Float
    count: Int
    percent: Float
    model: Int
    monthStats: [MonthStatsTransmittedMessages]
  }
  type MonthStatsTransmittedMessages {
    month: String
    count: Int
    percent: Float
    model: Int
  }
`;
