/* eslint-disable class-methods-use-this */
import SmsCharType from './SmsCharType';

export default class SmsCharGSM implements SmsCharType {
  static CHARSET = 'gsm';

  static SMS_MAX_LENGTH = 160;

  static SMS_CONCATENATION = 7;

  static REG_DOUBLE = /[\][{}€^|~\\]/gi;

  static DOUBLE_CHAR = 'XX';

  getCharset(): string {
    return SmsCharGSM.CHARSET;
  }

  countCharacters(text: string): number {
    return text.replace(SmsCharGSM.REG_DOUBLE, SmsCharGSM.DOUBLE_CHAR).length;
  }

  countSms(contentLength: number): number {
    if (contentLength <= SmsCharGSM.SMS_MAX_LENGTH) {
      return 1;
    }
    return Math.ceil(
      contentLength / (SmsCharGSM.SMS_MAX_LENGTH - SmsCharGSM.SMS_CONCATENATION)
    );
  }

  countNextSmsLimit(smsCount: number): number {
    if (smsCount <= 1) {
      return SmsCharGSM.SMS_MAX_LENGTH;
    }
    return (
      Math.max(smsCount, 1) *
      (SmsCharGSM.SMS_MAX_LENGTH - SmsCharGSM.SMS_CONCATENATION)
    );
  }

  getSmsMaxUnitLength(): number {
    return SmsCharGSM.SMS_MAX_LENGTH - SmsCharGSM.SMS_CONCATENATION;
  }
}
