import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Entity } from '@/core/store/models';
import { EntityInput } from '../store/models/statistics';

@Component
export default class StatsQueryMixin extends Vue {
  private error = null;

  protected queryName = '';

  get currentEntity(): Entity {
    return this.$store.getters['entities/current'];
  }

  get entityInput(): EntityInput {
    return new EntityInput(this.currentEntity);
  }

  get dates(): { from: Date; to: Date } {
    return this.$store.getters['analyse/statsDates'];
  }

  get statLoading(): boolean {
    return this.$apollo.queries?.[this.queryName]?.loading || false;
  }

  get statError(): boolean {
    return !!this.error;
  }
}
