<template>
  <div>
    <b-modal
      v-model="modalShow"
      :title="modalTitle"
      size="sm"
      centered
      hide-footer
      hide-backdrop
    >
      <chrome v-model="chromeColor" />
    </b-modal>
    <span class="color-picker-title">{{ title }}</span>
    <div class="color-picker">
      <span class="input-group-addon">
        <span
          class="current-color"
          :style="currentColorStyle"
          @click="toggleModal()"
        ></span>
      </span>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Chrome } from 'vue-color';

type colorType = { hex: string };

@Component({ components: { Chrome } })
export default class ColorPicker extends Vue {
  @Prop({ type: String, required: true }) value!: string;

  @Prop({ type: String }) title!: string;

  modalTitle = this.$t('smsEditor.tools.colorPicker.title');

  chromeColorValue: colorType = {
    hex: ''
  };

  colorValue = '';

  modalShow = false;

  get chromeColor(): colorType {
    return this.chromeColorValue;
  }

  set chromeColor(value: colorType) {
    this.chromeColorValue = value;
    this.colorValue = value.hex;
    this.$emit('input', this.colorValue);
  }

  get currentColorStyle(): object {
    return { backgroundColor: this.colorValue };
  }

  mounted() {
    this.colorValue = this.value;
    this.chromeColorValue = {
      hex: this.value
    };
    this.$emit('input', this.colorValue);
  }

  toggleModal() {
    this.modalShow = !this.modalShow;
  }
}
</script>
<style scoped>
.input-group-addon > span {
  border: 1px solid #b8beca;
}
.current-color {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 45px;
  cursor: pointer;
}

.color-picker {
  display: inline-block;
  text-align: center;
  width: 100%;
}

.color-picker-title {
  display: block;
  margin-bottom: 10px;
  font-weight: 300;
}

.modal-body > .vc-chrome {
  width: auto;
  box-shadow: none;
}
</style>
